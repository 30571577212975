export const pcConfig = {
 hackStripTcp: true, // Важно для хрома, чтоб он не тупил при звонке
 rtcpMuxPolicy: 'negotiate', // Важно для хрома, чтоб работал multiplexing. Эту штуку обязательно нужно включить на астере.
 iceServers: []
};
export const mediaConstraints = {
 audio: true, // Поддерживаем только аудио
 video: false
};
export const rtcOfferConstraints = {
 offerToReceiveAudio: 1, // Принимаем только аудио
 offerToReceiveVideo: 0
};
