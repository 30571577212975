import React from "react";

interface IProps {
 label: string
}

export const PageError: React.FC<IProps> = ({label}: IProps) => {
 return (
  <div className={"PageError"}>
   <i className={"fas fa-exclamation-triangle"}></i>
   <label>{label}</label>
  </div>
 )
}