import {IChatItem, IChatMessage} from "../../models/messenger";
import {IVcCam, IVcRoomItem, IVcRoomMessage} from "../../models/vc";

/**
 * Сообщение пользователю его liveID
 */

export const LMT_USER_HELLO = 1;

export interface LiveMessageTypeHello {
 type: typeof LMT_USER_HELLO
 liveId: string
 users: string[]
}

/**
 * Пользователь соединился
 */


export const LMT_USER_CONNECTED = 2;

export interface LiveMessageTypeUserConnected {
 type: typeof LMT_USER_CONNECTED
 userId: string
}

/**
 * Пользователь отсоединился
 */

export const LMT_USER_DISCONNECTED = 3;

export interface LiveMessageTypeUserDisconnected {
 type: typeof LMT_USER_DISCONNECTED
 userId: string
 stillConnected: boolean
}

/**
 * Пользователь должен обновиться
 */

export const LMT_USER_SILENT_RELOAD = 4;

export interface LiveMessageTypeUserSilentReload {
 type: typeof LMT_USER_SILENT_RELOAD
 flags: number
}

/**
 * Чат обновлён
 */

export const LMT_CHAT_HANDLED = 11;

export interface LiveMessageChatHandled {
 type: typeof LMT_CHAT_HANDLED
 chatItem: IChatItem
}

/**
 * Чат удалён
 */


export const LMT_CHAT_REMOVED = 12;

export interface LiveMessageChatRemoved {
 type: typeof LMT_CHAT_REMOVED
 chatId: string
}

/**
 * Сообщение оправлено
 */

export const LMT_CHAT_MESSAGE_SENT = 13;

export interface LiveMessageTypeChatMessageSent {
 type: typeof LMT_CHAT_MESSAGE_SENT
 msg: IChatMessage
}

/**
 * Сообщение прочитано
 */

export const LMT_CHAT_MESSAGE_READ = 14;

export interface LiveMessageTypeChatMessageRead {
 type: typeof LMT_CHAT_MESSAGE_READ
 msgId: string
 chatId: string
}

/**
 * Комната обновлена/создана
 */

export const LMT_VC_ROOM_HANDLED = 21;

export interface LiveMessageTypeVcRoomHandled {
 type: typeof LMT_VC_ROOM_HANDLED
 roomItem: IVcRoomItem
}

/**
 * Комната удалена/закрыта
 */

export const LMT_VC_ROOM_REMOVED = 22;

export interface LiveMessageTypeVcRoomRemoved {
 type: typeof LMT_VC_ROOM_REMOVED
 roomId: string
}

/**
 * Сообщение отправлено
 */

export const LMT_VC_MESSAGE_SENT = 23;

export interface LiveMessageTypeVcMessageSent {
 type: typeof LMT_VC_MESSAGE_SENT
 msg: IVcRoomMessage
}

/**
 * Сообщение прочитано
 */

export const LMT_VC_MESSAGE_READ = 24;

export interface LiveMessageTypeVcMessageRead {
 type: typeof LMT_VC_MESSAGE_READ
 msgId: string
 roomId: string
}

/**
 * Камеры комнаты обновлены
 */

export const LMT_VC_ROOM_CAMS_UPDATED = 25;

export interface LiveMessageTypeVcRoomCamsUpdated {
 type: typeof LMT_VC_ROOM_CAMS_UPDATED
 roomId: string
 cams: IVcCam[]
}

// export types

export type LiveMessageType = LiveMessageTypeUserConnected | LiveMessageTypeHello | LiveMessageTypeUserDisconnected
 | LiveMessageTypeUserSilentReload
 // chat
 | LiveMessageChatHandled | LiveMessageChatRemoved
 // chat messages
 | LiveMessageTypeChatMessageSent | LiveMessageTypeChatMessageRead
 // vc
 | LiveMessageTypeVcRoomHandled | LiveMessageTypeVcRoomRemoved | LiveMessageTypeVcRoomCamsUpdated
 // vc messages
 | LiveMessageTypeVcMessageSent | LiveMessageTypeVcMessageRead
