import React from "react";
import {RouteComponentProps} from "react-router";
import {IVcRoomItem} from "../../models/vc";
import {AppState} from "../../store";
import {connect} from "react-redux";
import PageLoader from "../../components/pageloader";
import VcCreateForm from "./create.form";
import {isSameDay} from "../../utils/date";
import {VcListItem} from "./list.item";
import {VcUnreadMessagesMap} from "../../store/vc/types";
import {t} from "../../lang";

interface IRoute {
 id: string
}

interface IProps extends RouteComponentProps<IRoute> {
 isFetching: boolean
 rooms: IVcRoomItem[]
 unread: VcUnreadMessagesMap
}

const mapState = (state: AppState) => ({
 isFetching: state.vcState.isFetching,
 rooms: state.vcState.rooms.sort((a, b) => (a.room.createdAt > b.room.createdAt) ? -1 : 1),
 unread: state.vcState.unread
});

interface IState {
 createFormVisible: boolean
}

class Vc extends React.Component<IProps, IState> {
 state: IState = {
  createFormVisible: false
 }

 toggleCreateForm = () => this.setState({createFormVisible: !this.state.createFormVisible});

 cancelCreateForm = () => this.setState({createFormVisible: false});

 createFormHandler = (roomId: string) => this.props.history.push("/app/vc/" + roomId);

 render() {
  const {isFetching, rooms, unread} = this.props;

  if (isFetching)
   return <PageLoader label={t("Загрузка модуля")}/>;

  let prevDate: Date | undefined = undefined;
  return (
   <div className={"VcList"}>
    <div className="title">
     <h1>{t("Видеоконференции")}</h1>
     <button onClick={this.toggleCreateForm}>{t("Добавить")}</button>
    </div>
    {this.state.createFormVisible && <VcCreateForm createCancelHandler={this.cancelCreateForm} createdOkHandler={this.createFormHandler}/>}
    {(rooms.length > 0) ? (
     <div className={"rooms"}>
      <ul>{rooms.map(roomItem => {
       const showDate = !isSameDay(roomItem.room.createdAt, prevDate);
       prevDate = roomItem.room.createdAt;
       return (
        <li key={roomItem.room.id}>
         <VcListItem roomItem={roomItem} showDate={showDate} unread={unread[roomItem.room.id]? unread[roomItem.room.id].length: 0}/>
        </li>
       )
      })}</ul>
     </div>
    ) : (
     <div className={"no-rooms"}>{t("Нет ни одной конференции")}</div>
    )}
   </div>
  )
 }
}

export default connect(mapState)(Vc);
