import {
 PHONE_ADD_HISTORY,
 PHONE_CONNECTED, PHONE_CONNECTING, PHONE_DEL_HISTORY, PHONE_DISCONNECTED,
 PHONE_END_SESSIONS, PHONE_INCOMING, PHONE_INCOMING_ACCEPTED, PHONE_INI, PHONE_MARK_ALL_MISSED_HISTORY, PHONE_MARK_MISSED_HISTORY, PHONE_OUTGOING, PHONE_OUTGOING_ACCEPTED,
 PHONE_STATUS_ACTIVE,
 PHONE_STATUS_CONNECTING,
 PHONE_STATUS_IDLE,
 PHONE_STATUS_INCOMING,
 PHONE_STATUS_OFFLINE,
 PHONE_STATUS_OUTGOUING,
 PhoneActionTypes,
 PhoneState
} from "./types";
import {CALL_TYPE_MISSED, CALL_TYPE_MISSED_NEW} from "../../models/call";
// import {StubHistoryCalls} from "../../utils/stub";

// const stub = StubHistoryCalls(125);

const iniState: PhoneState = {
 status: PHONE_STATUS_OFFLINE,
 userAgent: null,
 activeSession: null,
 incomingSession: null,
 history: []
 // history: stub
}

export function phoneReducer(state = iniState, action: PhoneActionTypes): PhoneState {
 switch (action.type) {
  case PHONE_INI:
   return {...state, userAgent: action.userAgent};
  case PHONE_CONNECTING:
   return {...state, status: PHONE_STATUS_CONNECTING};
  case PHONE_CONNECTED:
   return {...state, status: PHONE_STATUS_IDLE};
  case PHONE_DISCONNECTED:
   return {...state};
  case PHONE_INCOMING:
   return {...state, incomingSession: action.incomingSession, status: PHONE_STATUS_INCOMING};
  case PHONE_INCOMING_ACCEPTED:
   return {...state, activeSession: action.activeSession, incomingSession: null, status: PHONE_STATUS_ACTIVE};
  case PHONE_OUTGOING:
   return {...state, activeSession: action.activeSession, status: PHONE_STATUS_OUTGOUING};
  case PHONE_OUTGOING_ACCEPTED:
   return {...state, status: PHONE_STATUS_ACTIVE};
  case PHONE_END_SESSIONS:
   return {...state, activeSession: null, incomingSession: null, status: PHONE_STATUS_IDLE};
  case PHONE_ADD_HISTORY:
   return {...state, history: [...state.history, action.call]};
  case PHONE_DEL_HISTORY:
   return {...state, history: state.history.filter(f => f.when !== action.date)}
  case PHONE_MARK_MISSED_HISTORY:
   return {...state, history: state.history.map(f => ((f.when === action.date) ? {...f, type: CALL_TYPE_MISSED} : f))}
  case PHONE_MARK_ALL_MISSED_HISTORY:
   return {...state, history: state.history.map(f => ((f.type === CALL_TYPE_MISSED_NEW) ? {...f, type: CALL_TYPE_MISSED} : f))}
  default:
   return state;
 }
}