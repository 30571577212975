export enum UserRoleEnum {
 Admin = 1,
 User=2
}

export interface IUser {
 id: string;
 name: string;
 login: string;
 post?: string;
 email?: string;
 device?: string;
 sipNumber?: string;
}

export interface IUserFull extends IUser {
 sipPasswd?: string
 roles: UserRoleEnum[]
 nodeId: string
}
