import React from "react";
import VcUsersForm from "./users.form";
import VcCamsForm from "./cams.form";
import {IVcCam, IVcRoomItem} from "../../models/vc";
import {t} from "../../lang";

enum tabEnum {
 users = "users",
 cameras = "cameras"
}

interface IProps {
 closeHandler: Function
 roomItem: IVcRoomItem
 cams: IVcCam[]
}

interface IState {
 tab: tabEnum
}

export class ParticipantsBox extends React.Component<IProps, IState> {
 box: any;
 state = {
  tab: tabEnum.users
 }

 componentDidMount() {
  document.addEventListener("click", this.handleClick);
 }

 componentWillUnmount() {
  document.removeEventListener("click", this.handleClick);
 }

 handleClick = (e: any) => {
  if (this.box && !this.box.contains(e.target))
   this.props.closeHandler();
 }

 changeTab = (tab: tabEnum) => this.setState({tab});

 render() {
  const {tab} = this.state;

  return (
   <div className={"VcParticipansBox"} ref={el => this.box = el}>
    <div className="tabs">
     <ul>
      <li className={(tab === tabEnum.cameras) ? "active" : ""}>
       <button onClick={() => this.changeTab(tabEnum.cameras)}>{t("Камеры")}</button>
      </li>
      <li className={(tab === tabEnum.users) ? "active" : ""}>
       <button onClick={() => this.changeTab(tabEnum.users)}>{t("Участники")}</button>
      </li>
     </ul>
    </div>
    {(tab === tabEnum.users) && <VcUsersForm roomItem={this.props.roomItem}/>}
    {(tab === tabEnum.cameras) && <VcCamsForm roomId={this.props.roomItem.room.id} cams={this.props.cams} />}
    <div className={"actions-row"}>
     <button onClick={() => this.props.closeHandler()}>{t("Закрыть")}</button>
    </div>
   </div>
  )
 }
}