import {LIVE_CONNECT, LIVE_CONNECTED, LIVE_DISCONNECTED, LiveActionTypes} from "./types";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../index";
import {errorLog} from "../../utils/console";
import {
 LiveMessageType, LMT_CHAT_HANDLED, LMT_CHAT_MESSAGE_READ, LMT_CHAT_MESSAGE_SENT, LMT_CHAT_REMOVED, LMT_USER_CONNECTED, LMT_USER_DISCONNECTED,
 LMT_USER_HELLO,
 LMT_VC_MESSAGE_READ,
 LMT_VC_MESSAGE_SENT, LMT_VC_ROOM_CAMS_UPDATED, LMT_VC_ROOM_HANDLED, LMT_VC_ROOM_REMOVED
} from "./messages";
import {MESSENGER_CHAT_HANDLE_OK, MESSENGER_CHAT_REMOVED, MESSENGER_MSG_READ, MESSENGER_MSG_RECEIVED, MessengerActionTypes} from "../messenger/types";
import {responseFixChatItem, responseFixChatMessage, responseFixVcRoomItem, responseFixVcRoomMessage} from "../../utils/responsefix";
import {VC_MESSAGE_READ, VC_MESSAGE_RECEIVED, VC_ROOM_CAMS_UPDATED, VC_ROOM_HANDLE_OK, VC_ROOM_REMOVED, VcActionTypes} from "../vc/types";
import {phonePlaySound} from "../../utils/phone";
import {CONTACT_CONNECTED, CONTACT_DISCONNECTED, CONTACTS_ONLINE, ContactsActionTypes} from "../oc/types";
import {IOnlineUsersMap} from "../../models/contact";
import {ChatSettingsNotifyEnum} from "../../models/messenger";

export const liveConnect = (socket: WebSocket): LiveActionTypes => ({type: LIVE_CONNECT, socket});

export const liveDisconnect = (): LiveActionTypes => ({type: LIVE_DISCONNECTED});

type types = LiveActionTypes | MessengerActionTypes | VcActionTypes | ContactsActionTypes;
type LiveAppAction = ThunkAction<void, AppState, undefined, types>;

export const liveMessageReceived = (e: MessageEvent): LiveAppAction => async (dispatch, getState) => {
 try {
  const msg = JSON.parse(e.data) as LiveMessageType;
  const state = getState();
  // if (window.console)
  //  console.log('Live message', msg);
  switch (msg.type) {
   case LMT_USER_HELLO:
    dispatch({type: LIVE_CONNECTED, liveId: msg.liveId});
    dispatch({
     type: CONTACTS_ONLINE, online: msg.users.reduce((p, c) => {
      if (!p[c])
       p[c] = true;
      return p;
     }, {} as IOnlineUsersMap)
    });
    // if (window.console)
    //  console.log('user hello', msg);
    break;
   case LMT_USER_CONNECTED:
    dispatch({type: CONTACT_CONNECTED, userId: msg.userId});
    // if (window.console) //@todo
    //  console.log('user connected', msg);
    break;
   case LMT_USER_DISCONNECTED:
    if (!msg.stillConnected)
     dispatch({type: CONTACT_DISCONNECTED, userId: msg.userId});
    // if (window.console)
    //  console.log('user disconnected', msg);
    break;

   case LMT_CHAT_MESSAGE_SENT:
    dispatch({type: MESSENGER_MSG_RECEIVED, msg: responseFixChatMessage(msg.msg)});
    if (state.messengerState.selectedChatId === msg.msg.chatId)
     window.scrollTo(0, document.body.scrollHeight);
    const chat = state.messengerState.chats.find(f => f.id === msg.msg.chatId);
    if (chat) {
     const msgHasMyLogin = Boolean(msg.msg.body.indexOf(`@${state.userState.user.login},`) >= 0);
     if(msgHasMyLogin || chat.notify !== ChatSettingsNotifyEnum.Silent) {
      if(window.console)
       console.log('notify_msg', msg.msg);
      phonePlaySound('new_msg.mp3', false);
     }
    }
    break;
   case LMT_CHAT_MESSAGE_READ:
    dispatch({type: MESSENGER_MSG_READ, msgId: msg.msgId, chatId: msg.chatId});
    break;
   case LMT_CHAT_HANDLED:
    dispatch({type: MESSENGER_CHAT_HANDLE_OK, chatItem: responseFixChatItem(msg.chatItem)});
    break;
   case LMT_CHAT_REMOVED:
    dispatch({type: MESSENGER_CHAT_REMOVED, chatId: msg.chatId});
    break;

   case LMT_VC_MESSAGE_SENT:
    dispatch({type: VC_MESSAGE_RECEIVED, msg: responseFixVcRoomMessage(msg.msg)});
    if (state.vcState.selectedRoom === msg.msg.roomId)
     window.scrollTo(0, document.body.scrollHeight);
    break;
   case LMT_VC_MESSAGE_READ:
    dispatch({type: VC_MESSAGE_READ, msgId: msg.msgId, roomId: msg.roomId});
    break;
   case LMT_VC_ROOM_HANDLED:
    dispatch({type: VC_ROOM_HANDLE_OK, room: responseFixVcRoomItem(msg.roomItem)});
    break;
   case LMT_VC_ROOM_REMOVED:
    dispatch({type: VC_ROOM_REMOVED, roomId: msg.roomId});
    break;
   case LMT_VC_ROOM_CAMS_UPDATED:
    if (state.vcState.selectedRoom === msg.roomId)
     dispatch({type: VC_ROOM_CAMS_UPDATED, cams: msg.cams});
    break;
  }
 }
 catch (err) {
  errorLog('Live message handling error', err);
 }
}
