import React from "react";
import {t} from "../../lang";

interface IProps {
 showChatCreateForm: Function
 reloadList: Function
}

const MessengerIntro = ({showChatCreateForm, reloadList}: IProps) => (
 <div className="MessengerIntro">
  <div className="intro">
   {t("Выберите беседу слева или")} <label onClick={() => showChatCreateForm()}>{t("создайте новую")}</label>
   <div className={"reload"}><button onClick={() => reloadList()}><i className={"fas fa-sync"}></i> {t("Обновить список")}</button></div>
  </div>
 </div>
)

export default MessengerIntro;
