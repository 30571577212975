import React from "react";
import {connect} from "react-redux";
import {BrowserRouter as Router, NavLink} from "react-router-dom";

import {httpRegisterGlobalErrorHandler401, httpUnregisterGlobalErrorHandler401} from "../../utils/http";
import {userLogout} from "../../store/user/actions";
// import {ocContactsLoad} from "../../store/contacts/actions";
import {AppState} from "../../store";
import {IUserFull, UserRoleEnum} from "../../models/user";
import Routes from "./routes";
import Phone from "../phone";
import {
 PHONE_STATUS_ACTIVE, PHONE_STATUS_CONNECTING, PHONE_STATUS_IDLE, PHONE_STATUS_INCOMING, PHONE_STATUS_OFFLINE, PHONE_STATUS_OUTGOUING,
 PhoneStatus
} from "../../store/phone/types"
import {phoneActiveToggle} from "../phone/helper";
import {CALL_TYPE_MISSED_NEW} from "../../models/call";
import LiveContainer from "./live";
import {messengerLoad} from "../../store/messenger/actions";
import {vcIni} from "../../store/vc/actions";
import {ocContactsLoad} from "../../store/oc/actions";
import {t} from "../../lang";
import ChatBot from "../chatbot";

const packageJson = require('../../../package.json');
const buildJson = require('../../build.json');

interface IProps {
 user: IUserFull
 userLogout: any
 ocContactsLoad: any
 phoneStatus: PhoneStatus
 missingCount: number
 hasUnreadMessages: boolean
 hasUnreadVcMessages: boolean
 hasActiveVc: boolean

 messengerLoad: any
 vcIni: any
}

interface IState {
 bot: boolean
}

const mapState = (state: AppState) => ({
 user: state.userState.user,
 hasUnreadMessages: Object.values(state.messengerState.unread).reduce((p, c) => {
  if (!p) {
   return (c.length > 0);
  }
  return true;
 }, false),
 hasUnreadVcMessages: Object.values(state.vcState.unread).reduce((p,c) => {
  if(!p)
   return (c.length > 0);
  return true;
 }, false),
 hasActiveVc: state.vcState.rooms.reduce((p,c) => {
  if(!p)
   return !(c.room.closedAt)
  return true;
 }, false),

 phoneStatus: state.phoneState.status,
 missingCount: state.phoneState.history.filter(f => f.type === CALL_TYPE_MISSED_NEW).length,
});
const mapDispatch = {
 userLogout,
 ocContactsLoad,
 messengerLoad,
 vcIni,
}

class Main extends React.Component<IProps> {
 state: IState = {
  bot: false
 };

 componentDidMount() {
  httpRegisterGlobalErrorHandler401(this.props.userLogout);
  this.props.ocContactsLoad();
  this.props.messengerLoad();
  this.props.vcIni();
 }

 componentWillUnmount() {
  httpUnregisterGlobalErrorHandler401();
 }

 logoutClick = () => this.props.userLogout();

 phoneToggle = () => phoneActiveToggle();
 botToggle = () =>{
  console.log(this.state.bot);
  if (this.state.bot)
   this.setState({bot:false});
  else
   this.setState({bot:true});
 };

 appLog = (e: any) => {
  if (e.altKey && window.console) {
   console.log('Version', packageJson.version);
   console.log('Env', process.env.NODE_ENV);
  }
 }

 devClick = (e: any) => {
  // todo удалить, когда будет раздел с пользователями
  this.props.ocContactsLoad();
 }

 phoneStatusClassName = () => {
  switch (this.props.phoneStatus) {
   case PHONE_STATUS_OFFLINE:
    return "phone-button-offline";
   case PHONE_STATUS_CONNECTING:
    return "phone-button-connecting";
   case PHONE_STATUS_IDLE:
    return "phone-button-idle";
   case PHONE_STATUS_INCOMING:
    return "phone-button-incoming";
   case PHONE_STATUS_OUTGOUING:
    return "phone-button-outgoing";
   case PHONE_STATUS_ACTIVE:
    return "phone-button-active";
   default:
    return "phone-button-idle";
  }
 }

 render() {
  const me = this.props.user;
  const isAdmin = (me.roles.indexOf(UserRoleEnum.Admin) >= 0);
  const isUser = (me.roles.indexOf(UserRoleEnum.User) >= 0);
  console.log(isUser);
  console.log(isAdmin);
  // const showPhone = (me.sipNumber && me.sipPasswd && !(window.localStorage && window.localStorage["phone"] && window.localStorage["phone"] === "off"));
  // const showPhone = (me.sipNumber && me.sipPasswd && !(window.localStorage && window.localStorage["phone"] && window.localStorage["phone"] === "off"));
  const showPhone = true;
  const showBot = true;
  return (
   <Router basename={packageJson.homepage}>
    {this.state.bot && (isAdmin || isUser) &&  <ChatBot/>}
    <div>
     <div className="MainHeader">
      <div className="LogoText">
       <span className="Logo"><i className="icon"></i></span>
       <span className="Text">{t("СуперОко")}<i className="version">{buildJson.version}</i></span>
      </div>
      <div className="UserPanel">
       <div className="UserPanelControl Search" onClick={this.devClick}>
        <input type="text" className="Search-Input"/>
        <i className="fa fa-search Search-Toggler"></i>
       </div>
       <i className="UserPanelControl fa fa-plus" onClick={this.appLog}></i>
       <i className="UserPanelControl fa fa-bars"></i>
       <span className="Username">{me.name}</span>
       <button className="LogoutButton btn btn-circle" onClick={this.logoutClick}>
        <i className="fas fa-sign-out-alt" title={t("Выход")}></i>
       </button>
      </div>
     </div>
     <div className="MainSidebar">
      { (isAdmin || isUser) &&(
      <nav>
       <NavLink to={"/app/contacts"}>
        <i className={"fas fa-address-book"}></i>
        <label>{t("Контакты")}</label>
       </NavLink>
       <NavLink to={"/app/wells"}>
        <i className="fas fa-sitemap"></i>
        <label>{t("Объекты")}</label>
       </NavLink>
       <NavLink to={"/app/chat"}>
        <i className="fas fa-comment"></i>
        <label>{t("Сообщения")}</label>
        {this.props.hasUnreadMessages && (<span className="badge"><i className={"fas fa-exclamation-circle"} title={t("непрочитанное")}></i></span>)}
       </NavLink>
       <NavLink to={"/app/vc"}>
        <i className={"fas fa-video" + (this.props.hasActiveVc? " active-vc": "")}></i>
        <label>{t("ВКС")}</label>
        {this.props.hasUnreadVcMessages && (<span className="badge"><i className={"fas fa-exclamation-circle"} title={t("непрочитанное")}></i></span>)}
       </NavLink>
       <NavLink to={"/app/maps"}>
        <i className="fas fa-map-marker-alt"></i>
        <label>{t("Карта")}</label>
       </NavLink>
       <NavLink to={"/app/files"}>
        <i className={"fas fa-folder"}></i>
        <label>{t("Файловое хранилище")}</label>
       </NavLink>
       <NavLink to={"/app/monitor"}>
        <i className="fas fa-eye"></i>
        <label>{t("Мониторинг")}</label>
       </NavLink>       {isAdmin && (
        <NavLink to={"/app/adm"}>
         <i className={"fas fa-cogs"}></i>
         <label>{t("Администрирование")}</label>
        </NavLink>
       )}       {showBot && (
          <div className="bot-box">
           <button className={(this.state.bot)? "bot-button-active":"bot-button"  } onClick={this.botToggle}>
            <i className="fas fa-comment-dots"> </i>

           </button>
          </div>
      )}
       {showPhone && (

        <div className="phone-box" >
         <button className={"phone-button "} onClick={this.phoneToggle} >
          <i className="fas fa-phone"></i>
         </button>
         {this.props.missingCount > 0 && <span className="badge"><i className={"fas fas fa-exclamation-circle"} title={t("непрочитанное")}></i></span>}
        </div>
       )}

      </nav>)}
      {!isAdmin && !isUser && (
          <nav>
           <NavLink to={"/app/contacts"}>
            <i className={"fas fa-address-book"}></i>
            <label>{t("Контакты")}</label>
           </NavLink>
           <NavLink to={"/app/chat"}>
            <i className="fas fa-comment"></i>
            <label>{t("Сообщения")}</label>
            {this.props.hasUnreadMessages && (<span className="badge"><i className={"fas fa-exclamation-circle"} title={t("непрочитанное")}></i></span>)}
           </NavLink>
          </nav>)}
     </div>
     <div className="MainBody">
      <Routes/>
     </div>
    </div>
    <LiveContainer/>
    {showPhone && <Phone/>}
   </Router>
  )
 }
}

export default connect(mapState, mapDispatch)(Main);
