import React from "react";
import {IContact} from "../../models/contact";
import {Link, NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import MapSidebarContactCard, {ContactCardMode} from "./contact.card"
import {getLang, t} from "../../lang";

interface IProps {
 selectedUser?: IContact
 contacts: IContact[]
 date: Date
 dateChange: (d: Date) => void
}

export const HistorySidebar = (props: IProps) => {
 return (
  <div className="Sidebar">
   {props.selectedUser ? (
    <div className="history-date">
     <label><i className={"far fa-clock"}></i><span>{t("Дата")}</span></label>
     <DatePicker selected={props.date}
                 dateFormat={"dd.MM.yyyy"}
                 maxDate={new Date()}
                 locale={getLang()}
                 onChange={props.dateChange}/>
    </div>
   ) : (
    <div className="history-select-user">
     <label><i className={"fas fa-exclamation-circle"}></i>{t("Выберите пользователя")}</label>
    </div>
   )}

   {props.selectedUser ? (
    <MapSidebarContactCard contact={props.selectedUser} mode={ContactCardMode.History}/>
   ) : (
    <div className="history-users">
     <ul className="persons">
      {props.contacts.map((u, i) => <li key={i}>
       <NavLink to={"/app/maps/history/" + u.login}>
        <div className="person">
         <span className="name">{u.name}</span><br/>
        </div>
       </NavLink>
      </li>)}
     </ul>
    </div>
   )}

   {props.selectedUser && <div className="close">
    <Link to={"/app/maps/history/"}>{t("Закрыть")}</Link>
   </div>
   }

  </div>
 )
}


