import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/app';
import {Provider} from "react-redux";
import configureStore from "./store";
import {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';

import './design/index.scss';

registerLocale('ru', ru);
// setDefaultLocale('ru');

const store = configureStore();

const Root = () => (
    <Provider store={store}>
        <App/>
    </Provider>
)

ReactDOM.render(<Root/>, document.getElementById('root'));
