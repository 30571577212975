import React from "react"
import {NavLink} from "react-router-dom";
import {t} from "../../lang";

const NavSettings: React.FC = () => (
    <nav>

        <ul>
            <li>
                <button className="Settings"><NavLink to={"/app/monitor/realtime"}>
                    <i className={"fas fa-arrow-left fa-2x"} title={t("К мониторингу")}></i>
                </NavLink></button>
            </li>
            <li>
                <button className="Settings"><NavLink to={"/app/monitor/settings"}>
                    <i className={"fas fa-bell fa-2x"} title={t("Настройка уведомлений")}></i>
                </NavLink></button>
            </li>
        </ul>
    </nav>
)

export default NavSettings