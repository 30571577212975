import React from "react";
import {ChatUserRoleEnum, IChat, IChatUserRole, IUserRoleMap} from "../../models/messenger";
import {IContact} from "../../models/contact";
import {t} from "../../lang";

interface IUserRoleSelectorProps {
 userId: string
 role: ChatUserRoleEnum
 onChange: (userId: string, role: ChatUserRoleEnum) => void
}

const UserRoleSelector = ({userId, role, onChange}: IUserRoleSelectorProps) => (
 <div className={"role-selector"}><select value={role} onChange={(e: any) => onChange(userId, e.target.value)}>
  <option value={ChatUserRoleEnum.Admin}>{t("Модератор")}</option>
  <option value={ChatUserRoleEnum.User}>{t("Пользователь")}</option>
  <option value={ChatUserRoleEnum.Blocked}>{t("Заблокирован")}</option>
 </select></div>
)

interface IProps {
 contacts: IContact[]
 chat: IChat
 users: IChatUserRole[]
 isHandling: boolean
 cancelModifyChat: Function
 messengerModifyChat: any
}

interface IState {
 name: string
 selected: IUserRoleMap
 search: string
}

export class MessengerModifyRoomChat extends React.Component<IProps, IState> {
 state: IState = {
  name: "",
  selected: {},
  search: ""
 }

 componentDidMount() {
  const name = this.props.chat.name;
  const selected: IUserRoleMap = this.props.users.reduce((p,c) => {
   p[c.userId] = c.role;
   return p;
  }, {} as IUserRoleMap);
  this.setState({name, selected});
 }

 changeName = (e: any) => this.setState({name: e.target.value});
 changeSearch = (e: any) => this.setState({search: e.target.value});
 checkUser = (e: any) => {
  const id = e.target.value;
  const selected = {...this.state.selected};
  if(this.state.selected[id]) {
   delete selected[id];
  }
  else {
   selected[id] = ChatUserRoleEnum.User;
  }
  this.setState({selected});
 }
 changeUserRole = (userId: string, role: ChatUserRoleEnum) => {
  const selected = {...this.state.selected};
  selected[userId] = role;
  this.setState({selected});
 }

 submitForm = async (e: any) => {
  e.preventDefault();
  if(this.canSubmit()) {
   try {
    await this.props.messengerModifyChat(this.props.chat.id, this.state.name, this.state.selected);
    this.props.cancelModifyChat();
   }
   catch (error) {
   }
  }
 }

 canSubmit = (): Boolean => Boolean((Boolean(this.state.name.trim()) && Object.keys(this.state.selected).length > 0));

 render() {
  const search = this.state.search.toLowerCase().trim();
  const contacts = search? this.props.contacts.filter(f => f.name.toLowerCase().indexOf(search) >= 0): this.props.contacts;

  return (
   <div className="MessengerBody">
    <div className={"header"}>
     <h2>{this.props.chat.name}</h2>
    </div>
    <div className={"body"}>
     <div className={"ChatForm"}>
      <form onSubmit={this.submitForm}>
       <div className="name-row">
        <h3>{t("Название")}</h3>
        <input value={this.state.name} onChange={this.changeName}/>
       </div>
       <div className="users-row">
        <div className="title">
         <h3>{t("Участники")}</h3>
         <input type="search" placeholder={t("Поиск")} value={this.state.search} onChange={this.changeSearch}/>
        </div>

        {contacts.length > 0 ? (<ul>
         {contacts.map(c => (
          <li key={c.id}>
           <label><input disabled={this.props.isHandling} type={"checkbox"} value={c.id} checked={Boolean(this.state.selected[c.id])}
                         onChange={this.checkUser}/> {c.name}</label>
           {Boolean(this.state.selected[c.id]) && <UserRoleSelector userId={c.id} role={this.state.selected[c.id]} onChange={this.changeUserRole}/>}
          </li>
         ))}
        </ul>) : (<div className="no-users">{t("Пользователи не найдены")}</div>)}
       </div>
       <div className="actions-row">
        <button disabled={!this.canSubmit() || this.props.isHandling} type={"submit"}>{t("Сохранить")}</button>
        <button type={"button"} onClick={() => this.props.cancelModifyChat()}>{t("Отмена")}</button>
       </div>
      </form>
     </div>
    </div>
   </div>
  )
 }
}