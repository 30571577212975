import {USER_FETCH, USER_FAIL, USER_OK, UserActionTypes, UserState, USER_LOGOUT} from "./types";
import {ThunkAction} from "redux-thunk";
import {httpGet, httpPost} from "../../utils/http";

import {IUserFull} from "../../models/user";
import {errorLog} from "../../utils/console";

// import {tokenRemove, tokenSet} from "../../utils/token";
// interface ILoginResult {
//  token: string
// }

type UserAction = ThunkAction<void, UserState, undefined, UserActionTypes>

export const userLogin = (login: string, passwd: string): UserAction => async dispatch => {
 try {
  dispatch({type: USER_FETCH});
  const user = await httpPost<IUserFull>('/mapi/auth/login', {login, passwd});
  // const {token} = await httpPost<ILoginResult>('/api/ApplicationUser/Login', {UserName, Password});
  // tokenSet(token);
  // const user = await httpGet<IUser>('/api/UserProfile');
  dispatch({type: USER_OK, user});
 }
 catch (error) {
  errorLog('User login error', error);
  dispatch({type: USER_FAIL, error});
 }
}

export const userLogout = (): UserAction => async dispatch => {
 try {
  await httpPost('/mapi/auth/logout', {});
  dispatch({type: USER_LOGOUT});
  document.body.innerHTML = '<div>...</div>';
  // eslint-disable-next-line
  window.location = window.location;
 }
 catch (error) {
  errorLog('User logout error', error);
  dispatch({type: USER_FAIL, error})
 }
}

export const userLoadProfile = (): UserAction => async dispatch => {
 try {
  dispatch({type: USER_FETCH});
  const user = await httpGet<IUserFull>('/mapi/auth/me');
  // const user = await httpGet<IUser>('/api/UserProfile');
  dispatch({type: USER_OK, user});
 }
 catch (error) {
  errorLog('User profile error', error);
  dispatch({type: USER_FAIL, error});
 }
}
