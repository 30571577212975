export const numNames = (num: number, names: string[], withNum: boolean = true): string => {
 const v = num.toString();
 const prefix: string = withNum ? (v + " ") : "";
 if (names.length === 3) {
  if (/1[0-9]$/.test(v))
   return prefix + names[2];
  else if (/1$/.test(v))
   return prefix + names[0];
  else if (/[2-4]$/.test(v))
   return prefix + names[1];
  return prefix + names[2];
 }
 return withNum ? v : "";
};

export const numNamesDummy = (num: number, names: string[], withNum: boolean = true): string => {
 const prefix: string = withNum ? (num + " ") : "";
 if (num === 1) {
  if (names[0])
   return prefix + names[0];
 }
 else {
  if (names[1])
   return prefix + names[1];
 }
 return withNum ? num.toString() : "";
}

export const formatBytes = (bytes: number, decimals = 2): string => {
 if (0 === bytes) return "0 B";
 let c = 1024, d = decimals || 2, e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(bytes) / Math.log(c));
 return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + " " + e[f]
};


