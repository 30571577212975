import React from 'react';
import {connect} from "react-redux";

import Login from "../login";
import {IUser} from "../../models/user";
import {AppState} from "../../store";
import Main from "./main";
import {infoLog} from "../../utils/console";
// import {tokenInit} from "../../utils/token";
import {userLoadProfile} from "../../store/user/actions";
import AppLoader from "../../components/apploader";
import {configLoad} from "../../store/config/actions";
import {langIni, t} from "../../lang";
import {headerRegister} from "../../utils/http";
import Ecm from "../ecm";

const packageJson = require('../../../package.json');

interface IProps {
 me: IUser
 isSigned: boolean
 isFetching: boolean
 isConfigLoaded: boolean
 configLangs: string[]
 userLoadProfile: any
 configLoad: any
 }

const mapState = (state: AppState) => ({
 me: state.userState.user,
 isSigned: state.userState.isSigned,
 isFetching: state.userState.isFetching,
 isConfigLoaded: state.configState.isLoaded,
 configLangs: state.configState.config.langs,
 isEdc:false
})

const mapDispatch = {
 userLoadProfile,
 configLoad
}

interface IState {
 loading: boolean
}

class App extends React.Component<IProps, IState> {
 state: IState = {
  loading: true
 }


 async componentDidMount() {
  const version = packageJson.version;
  infoLog(`App ini [${version}]`);
  await this.props.configLoad();

  // lang ini
  const lang = await langIni(this.props.configLangs);
  infoLog(`App locale [${lang}]`);
  headerRegister("Lang", lang);
  window.document.title = t("ПК «СуперОко»");

  await this.props.userLoadProfile();

  // if (tokenInit()) {
  //  await this.props.userLoadProfile();
  //  this.setState({loading: false});
  // }
  // else {
  //  this.setState({loading: false})
  // }
 }

 componentDidUpdate(prev: IProps) {
  if (prev.isSigned && !this.props.isSigned)
   this.setState({loading: false});
 }

 render() {
  if (this.props.isSigned && this.props.isConfigLoaded)
   return <Main/>;


  return (this.props.isFetching || !this.props.isConfigLoaded) ? <AppLoader label={t("...")}/> : <Login />;
  return (this.state.loading || !this.props.isConfigLoaded) ? <AppLoader label={"Загрузка приложения"}/> : <Login />;
 }
}

export default connect(mapState, mapDispatch)(App);
