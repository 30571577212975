import React from "react";
import {format} from "date-fns";
import Events from "./events";
import Nav from "./nav";
import Select from 'react-select'
import {getLang, t} from "../../lang";
import DatePicker from "react-datepicker";
import {IZMEventResponse} from "../../models/zm_event";
import {IZMObject, IZMObjectResponse} from "../../models/zm_objects";
import {IFilter} from "../../models/filter";
import EventVideo from "./eventvideo";

let ReactTable = require('react-table-v6').default;


interface IProps {


}

interface IState {
    timer: number,
    monitor: IZMEventResponse,
    selectedId: string,
    selectedEventId: string,
    showPhoto: boolean,
    showVideo: boolean,
    objects: IZMObject [],
    optObjects: IFilter[],
    optMonitors: IFilter[],
    selectedObjects: IFilter[],
    selectedMonitors: IFilter[],
    startDate: Date,
    endDate: Date,
    selectedRow: number


}



class MonitorHistory extends React.Component<IProps, IState> {
    state: IState = {
        timer: 10,
        showPhoto: false,
        showVideo: false,
        objects: [],
        optObjects: [],
        optMonitors: [],
        selectedMonitors: [],
        selectedObjects: [],
        startDate: new Date(new Date().getTime() - 3 * 60 * 60 * 1000),
        endDate: new Date(),
        selectedId: "",
        selectedEventId: "",
        selectedRow:-1,

        monitor: {
            "events": []
        },
    };

    componentDidMount(): void {
        const lag = 3 * 60 * 60 * 1000;
        const starttime = format(Date.now() - lag, "yyyy-MM-dd HH:mm:ss");
        console.log(starttime);

        fetch("https://soho.petroviser.ru/vas/api/objects")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    let dataset: IZMObjectResponse = JSON.parse(JSON.stringify(result));
                    if (dataset &&dataset.objects&& dataset.objects.length > 0) {
                        let obj: IFilter[] = [];
                        let monitors: IFilter[] = [];
                        dataset.objects.forEach(o => {
                            obj.push({value: o.id, label: o.name});
                            o.monitors.forEach(m => monitors.push({value: m.id, label: m.name}));
                        });
                        this.setState({
                            objects: dataset.objects,
                            optObjects: obj,
                            optMonitors: monitors,
                            selectedObjects: obj,
                            selectedMonitors: monitors
                        });
                        this.getEvents();


                    }
                },
                (error) => {
                    console.log(error.toString())
                }
            );
    }

    getEvents = () => {
        let zm_obj: string[] = [];
        if (this.state.selectedObjects != null)
            this.state.selectedObjects.forEach(o => zm_obj.push(o.value.toString()));
        let zm_monitors: string[] = [];
        if (this.state.selectedMonitors != null)
            this.state.selectedMonitors.forEach(m => zm_monitors.push(m.value.toString()));
        console.log("getEvents");
        fetch("https://soho.petroviser.ru/vas/api/events", {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            body: JSON.stringify({
                "start_datetime": format(this.state.startDate, "yyyy-MM-dd HH:mm:ss"),
                "end_datetime": format(this.state.endDate, "yyyy-MM-dd HH:mm:ss"),
                "zm_objects": zm_obj,
                "monitors": zm_monitors
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    let dataset: IZMEventResponse = JSON.parse(JSON.stringify(result));
                    if (dataset && dataset.events) {
                        dataset.events.sort((a, b) => new Date(a.start_time) > new Date(b.start_time) ? -1 : 1);
                        this.setState({monitor: dataset});
                    }
                },
                (error) => {
                    console.log(error.toString())
                }
            );
    };
    handleChangeMonitors = event => {
        this.setState({selectedMonitors: event});
        console.log(event)
    };
    handleChangeObjects = event => {
        this.setState({selectedObjects: event});
        console.log(event)
    };
    setStartDate = date => {
        this.setState({startDate: date});
        if (date >= this.state.endDate)
            this.setState({endDate: date});
    };
    setEndDate = date => {
        if (date >= this.state.startDate)
            this.setState({endDate: date});
        else
            this.setState({endDate: this.state.startDate});
    };
    onPhotoClick = e => {
        this.setState({selectedId: e});
        this.showModalPhoto();
    };
    hideModalPhoto = () => {
        this.setState({
            showPhoto: false
        });
    };
    showModalPhoto  = () => {
        this.setState({
            showPhoto: true
        });
    };
    onVideoClick = e => {
        this.setState({selectedId: e});
        this.showModalVideo();
    };
    hideModalVideo = () => {
        this.setState({
            showVideo: false
        });
    };
    showModalVideo  = () => {
        this.setState({
            showVideo: true
        });
    };

    render() {
        const translations = {
            previousText: t('ПРЕД'),
            nextText: t('СЛЕД'),
            loadingText: t('ЗАГРУЗКА...'),
            noDataText: t('нет данных'),
            pageText: t('стр.'),
            ofText: t('из'),
            rowsText: t('строк'),
        };
        let columns = [
            {

                //accessor: 'zm_object_name', // accessor is the "key" in the data
                width:57 ,
                filterable: false,
                Cell: ({  original }) => (
                    <button className="TableActions" >
                        <i className="fas fa-images fa-2x" title={t("фото")} onClick={()=>this.onPhotoClick(original.id)}></i>
                    </button>
                )
            },
            {
                width:57 ,
                filterable: false,
                Cell: ({  original }) => (
                    <button className="TableActions" >
                        <i className="fas fa-film fa-2x" title={t("видео")} onClick={()=>this.onVideoClick(original.zm_event_id)}></i>
                    </button>
                )

            },
            {
                Header: t('Объект'),
                accessor: 'zm_object_name', // accessor is the "key" in the data
                minWidth: 100,
                filterable: false,
            },
            {
                Header: t('Монитор'),
                accessor: 'vas_monitor_name',
                minWidth: 100,
            },
            {
                Header: t('СИЗ'),
                id: 'no_hardhat',
                accessor: h => {
                    return h.no_hardhat ? t("Нет") : t("Да")
                },
                minWidth: 50,
                getProps: (state, rowInfo, column) => {
                    if (rowInfo && rowInfo.row)
                        return {
                            style: {
                                background: column.accessor(rowInfo.original) == t("Нет") ? "#996b66" :
                                    "#7a857b"
                            }
                        };
                    else return {};
                }
            },
            {
                Header: t('Количество человек'),
                accessor: 'number_person',
                minWidth: 50,
            },
            {
                Header: t('Возможные участники'),
                accessor: 'face_owners',
                minWidth: 50,
            },
            {
                Header: t('Id события'),
                accessor: 'zm_event_id',
                minWidth: 50,
            },
            {
                Header: t('Время'),
                accessor: 'start_time',
                minWidth: 100,
            },


        ];
        const data = this.state.monitor.events;
        console.log(this.state.monitor);
        return (
            <div className="MonitorContainer">
                <Nav/>
                <div className="Monitor">
                    <div className="title">
                        <h1>{t("История событий")}</h1>
                    </div>
                    <div className="Filter">
                        <h2> {t("Объект")}</h2>
                        <Select
                            options={this.state.optObjects}
                            isMulti
                            placeholder={t("Выбрать...")}
                            value={this.state.selectedObjects}
                            onChange={value => this.handleChangeObjects(value)}
                            noOptionsMessage={() => t("Нет опций")}/>
                    </div>
                    <div className="Filter">
                        <h2> {t("Монитор")}</h2>
                        <Select options={this.state.optMonitors}
                                isMulti
                                placeholder={t("Выбрать...")}
                                value={this.state.selectedMonitors}
                                onChange={value => this.handleChangeMonitors(value)}
                                noOptionsMessage={() => t("Нет опций")}/>
                    </div>
                    <div className="Filter">
                        <h2> {t("За период с")} </h2>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={date => this.setStartDate(date)}
                            selectsStart
                            showTimeSelect
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            maxDate={new Date()}
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption={t("Время")}
                            locale={getLang()}
                        />
                        <h3> {t("по")} </h3>
                        <DatePicker
                            selected={this.state.endDate}
                            onChange={date => this.setEndDate(date)}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            minDate={this.state.startDate}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                            timeCaption={t("Время")}
                            locale={getLang()}
                        />
                        <button className="Actions">
                            <i className="fas fa-sync fa-2x" title={t("Выполнить")} onClick={this.getEvents}></i>
                        </button>
                    </div>
                    {(this.state.showPhoto) && (
                        <Events eventId={this.state.selectedId} onClose={()=>this.hideModalPhoto()}/>)
                    }
                    {(this.state.showVideo) && (
                        <EventVideo eventId={this.state.selectedId} onClose={()=>this.hideModalVideo()}/>)
                    }
                    {(this.state.monitor.events.length > 0) ? (
                        <div>
                            <ReactTable {...translations}
                                        columns={columns}
                                        data={data}
                                        pageSize={this.state.monitor.events.length}
                                        minRows={15}
                                        showPagination={false}
                                        // getTrProps={(state, rowInfo) => ({
                                        //     onClick: () => this.onRowClick(rowInfo.original.id)
                                        // })}
                                        style={{height: "60vh"}}
                                        getTrGroupProps={(state, rowInfo) => {
                                            if (rowInfo !== undefined) {
                                                return {
                                                    onClick: () => {
                                                        this.setState({
                                                            selectedRow: rowInfo.original.id
                                                        })
                                                    },
                                                    style: {
                                                        cursor: 'pointer',
                                                        background: rowInfo.original.id === this.state.selectedRow ? '#786f6e' : '',

                                                    }
                                                }
                                            }}
                                        }
                            >
                            </ReactTable>
                        </div>) : <div className="NoData">{t("Нет событий")}</div>
                    }
                </div>
            </div>
        );
    }
}

export default MonitorHistory;