import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
// import moment from "moment";
import L from "leaflet";
import {Map, ImageOverlay, LayersControl, Circle, Polygon, LayerGroup, Marker} from "react-leaflet";
import Nav from "./nav";
import {IContact} from "../../models/contact";
import {AppState} from "../../store";
import {getMapRealtime} from "./actions";
import {IUserMapPoint} from "./models";
import {MapSidebarContact} from "./contact";
import {MapSidebarContacts} from "./contacts";
import {errorLog} from "../../utils/console";
import {bounds, center, circleRadius, defaultZoom, image, minZoom, pointInDangerZone} from "./stub";
import {firefoxIcon, peopleIcon} from "./icons";
import {t} from "../../lang";

const {BaseLayer, Overlay} = LayersControl;

interface IContactByDeviceMap {
 [key: string]: IContact
}

interface IRoute {
 user?: string
}

interface IProps extends RouteComponentProps<IRoute> {
 contacts: IContactByDeviceMap
 selectedUser?: IContact
}

const mapState = (state: AppState, orig: RouteComponentProps<IRoute>) => {
 const contacts: IContactByDeviceMap = state.ocState.contacts.reduce((p, c) => {
  p[c.device] = c;
  return p;
 }, {} as IContactByDeviceMap);
 const selectedUser = orig.match.params.user ? state.ocState.contacts.find(f => f.login === orig.match.params.user) : undefined;
 return {contacts, selectedUser}
}

interface IState {
 journalActive: boolean
 points: IUserMapPoint[]
 // selectedDeviceId: string
}


class MapsRealtime extends React.Component<IProps, IState> {
 mounted = false;
 timeout?: number;
 state: IState = {
  journalActive: false,
  points: [],
  // selectedDeviceId: ""
 }

 componentDidMount() {
  this.mounted = true;
  this.schedulerMapPoints();
 }

 componentWillUnmount() {
  this.mounted = false;
  if (this.timeout) {
   clearTimeout(this.timeout);
   this.timeout = undefined;
  }
 }

 schedulerMapPoints = async () => {
  try {
   const res = await getMapRealtime();
   const points = res.map(p => {
    const person = this.props.contacts[p.deviceId];
    if (!person)
     return undefined;
    const dangerZone = pointInDangerZone(p.position);
    return {...p, person, dangerZone} as IUserMapPoint
   }).filter(f => f !== undefined) as IUserMapPoint[];
   this.setState({points: points.sort((a, b) => (a.person.name > b.person.name) ? 1 : -1)});
  }
  catch (e) {
   errorLog('scheduler map points', e);
  }
  finally {
   if (this.mounted) {
    this.timeout = window.setTimeout(this.schedulerMapPoints, 2500);
   }
  }
 }

 setSelectedDeviceId = (id: string) => {
  const user = Boolean(id)? this.props.contacts[id]: undefined;
  if(user && user.login !== this.props.match.params.user)
   this.props.history.push("/app/maps/realtime/" + user.login);
  else
   this.props.history.push("/app/maps/realtime");
  // const selectedDeviceId = (id === this.state.selectedDeviceId) ? "" : id;
  // this.setState({selectedDeviceId});
 }

 // clearSelectedDeviceId = () => {
 //  this.setState({selectedDeviceId: ""});
 // }

 render() {

  return (
   <div className="MapContainer">
    <Nav/>
    <div className="MapWrapper">
     <div className="map-row">
      <div className="MapSidebarBox">
       <div className="Map" id="leafletMap">
        <Map crs={L.CRS.Simple} center={center} minZoom={minZoom} zoom={defaultZoom} maxBounds={bounds}>
         <LayersControl position={"topright"}>
          <BaseLayer checked name={t("Базовый слой")}>
           <ImageOverlay url={image}
                         bounds={bounds}/>
          </BaseLayer>
          <Overlay name={t("Выделенные зоны")}>
           <LayerGroup>
            <Circle center={center} color={"red"} fillColor={"red"} fillOpacity={.3} radius={circleRadius}/>
            <Polygon positions={[
             [5684708, 3588825],
             [5684705, 3588871],
             [5684691, 3588868],
             [5684694, 3588822]
            ]} color={"green"} fillColor={"green"} fillOpacity={.3}/>
           </LayerGroup>
          </Overlay>
         </LayersControl>
         {this.state.points.map(p => {
          return <Marker key={p.deviceId}
                         // icon={p.deviceId === this.state.selectedDeviceId ? firefoxIcon : peopleIcon}
                         icon={(this.props.selectedUser && (this.props.selectedUser.device===p.deviceId)) ? firefoxIcon : peopleIcon}
                         position={p.position}
                         onClick={() => this.setSelectedDeviceId(p.deviceId)}
          />
         })}
        </Map>
       </div>
       {this.props.selectedUser
        ? <MapSidebarContact contact={this.props.selectedUser}/>
        : <MapSidebarContacts contacts={this.state.points}/>
       }
      </div>
     </div>
    </div>
   </div>
  )
 }
}

export default connect(mapState)(MapsRealtime);
