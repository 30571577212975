import React from "react";
import {IContact} from "../../models/contact";
import {connect} from "react-redux";
import {IKeyMap} from "../../models/common";
import {AppState} from "../../store";
import MessengerCreateChat from "./create.room";
import MessengerCreateWellChat from "./create.well";
import MessengerCreatePersonChat from "./create.person";
import {t} from "../../lang";

enum ChatCreateTabEnum {
 RoomChat = "room",
 PersonChat = "person",
 WellChat = "well"
}

interface IProps {
 contacts: IContact[]
 // online: IOnlineUsersMap
 // isHandling: boolean
 cancelCreateChat: Function
 chatCreatedHandler: Function
 wellId?: string
 adminWells: IKeyMap
 // messengerCreateChat: any
}

const mapState = (state: AppState) => ({
 adminWells: state.ocState.admin
});

const MessengerCreatePage: React.FC<IProps> = ({wellId, adminWells, contacts, cancelCreateChat, chatCreatedHandler}) => {
 const [tab, setTab] = React.useState<ChatCreateTabEnum>(wellId ? ChatCreateTabEnum.WellChat : ChatCreateTabEnum.PersonChat);

 return (
  <div className="MessengerBody">
   <div className={"header"}>
    <h2>{t("Создание новой беседы")}</h2>
   </div>
   <div className={"body"}>
    <ul className={"tabs"}>
     <li className={(tab === ChatCreateTabEnum.PersonChat) ? "active" : ""}>
      <button onClick={() => setTab(ChatCreateTabEnum.PersonChat)}><i className={"fas fa-user"}></i> {t("Контакт")}</button>
     </li>
     <li className={(tab === ChatCreateTabEnum.RoomChat) ? "active" : ""}>
      <button onClick={() => setTab(ChatCreateTabEnum.RoomChat)}><i className={"fas fa-users"}></i> {t("Группа")}</button>
     </li>
     <li className={(tab === ChatCreateTabEnum.WellChat) ? "active" : ""}>
      <button disabled={Object.keys(adminWells).length===0} onClick={() => setTab(ChatCreateTabEnum.WellChat)}><i className={"fas fa-sitemap"}></i> {t("По объекту")}</button>
     </li>
    </ul>

    {tab===ChatCreateTabEnum.PersonChat && <MessengerCreatePersonChat cancelCreateChat={cancelCreateChat} chatCreatedHandler={chatCreatedHandler} contacts={contacts} />}
    {tab===ChatCreateTabEnum.WellChat && <MessengerCreateWellChat cancelCreateChat={cancelCreateChat} chatCreatedHandler={chatCreatedHandler} wellId={wellId} />}
    {tab===ChatCreateTabEnum.RoomChat && <MessengerCreateChat cancelCreateChat={cancelCreateChat} chatCreatedHandler={chatCreatedHandler} contacts={contacts} />}
   </div>
  </div>
 )
}

export default connect(mapState)(MessengerCreatePage);

// interface ICheckedMap {
//  [key: string]: boolean
// }
//
// interface IProps {
//  contacts: IContact[]
//  online: IOnlineUsersMap
//  isHandling: boolean
//  cancelCreateChat: Function
//  chatCreatedHandler: Function
//  messengerCreateChat: any
// }
//
// const mapState = (state: AppState) => ({
//  online: state.ocState.online,
//  isHandling: state.messengerState.isChatHandling
// });
//
// const mapDispatch = {
//  messengerCreateChat
// }
//
// interface IState {
//  name: string
//  selected: string[]
//  search: string
// }
//
// class MessengerCreateChat extends React.Component<IProps, IState> {
//  state: IState = {
//   name: "",
//   selected: [],
//   search: ""
//  }
//
//  changeName = (e: any) => this.setState({name: e.target.value});
//  changeSearch = (e: any) => this.setState({search: e.target.value});
//  changeUser = (e: any) => {
//   const id = e.target.value;
//   if(Boolean(this.state.selected.find(f => f===id)))
//    this.setState({selected: this.state.selected.filter(f => f!==id)});
//   else
//    this.setState({selected: [...this.state.selected, id]});
//  }
//  submitForm = async (e: any) => {
//   e.preventDefault();
//   if(this.canSubmit()) {
//    try {
//     const chatId = await this.props.messengerCreateChat(this.state.name, this.state.selected);
//     this.props.chatCreatedHandler(chatId);
//     // const roomId = await this.props.obsvcCreateRoom(this.state.name, this.state.selected);
//     // this.props.createdOkHandler(roomId);
//    }
//    catch (error) {
//    }
//   }
//  }
//
//  canSubmit = (): Boolean => Boolean((Boolean(this.state.name.trim()) && this.state.selected.length > 0));
//
//  render() {
//   const online = this.props.online;
//   const checked = this.state.selected.reduce((p,c) => {
//    p[c] = true;
//    return p;
//   }, {} as ICheckedMap);
//
//   const search = this.state.search.toLowerCase().trim();
//   const contacts = search? this.props.contacts.filter(f => f.name.toLowerCase().indexOf(search) >= 0): this.props.contacts;
//
//   return (
//    <div className="MessengerBody">
//     <div className={"header"}>
//      <h2>Создание новой беседы</h2>
//     </div>
//     <div className={"body"}>
//      <div className={"ChatForm"}>
//       <form onSubmit={this.submitForm}>
//        <div className="name-row">
//         <h3>Название</h3>
//         <input value={this.state.name} onChange={this.changeName}/>
//        </div>
//        <div className="users-row">
//         <div className="title">
//          <h3>Участники</h3>
//          <input type="search" placeholder={"Поиск"} value={this.state.search} onChange={this.changeSearch} />
//         </div>
//
//         {contacts.length > 0? (<ul>
//          {contacts.map(c => (
//           <li key={c.id}>
//            <label>
//             <input disabled={this.props.isHandling} type={"checkbox"} value={c.id} checked={Boolean(checked[c.id])} onChange={this.changeUser} /> {c.name}
//             {c.isMyNode && <span className={"OnlineStatus" + (online[c.id]? " online":" offline")}>{online[c.id]? "online": "offline"}</span>}
//            </label>
//           </li>
//          ))}
//         </ul>): (<div className="no-users">Пользователи не найдены</div>)}
//        </div>
//        <div className="actions-row">
//         <button disabled={!this.canSubmit() || this.props.isHandling} type={"submit"}>Создать</button>
//         <button type={"button"} onClick={() => this.props.cancelCreateChat()}>Отмена</button>
//        </div>
//       </form>
//      </div>
//     </div>
//    </div>
//   )
//  }
// }
//
// export default connect(mapState,mapDispatch)(MessengerCreateChat);
