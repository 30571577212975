import React from "react";
import {ChatCategoryEnum} from "../../models/messenger";
import {t} from "../../lang";

interface ICategorySelectorProps {
 category: ChatCategoryEnum
 onChange: (category: ChatCategoryEnum) => void
}

export const CategorySelector: React.FC<ICategorySelectorProps> = ({category, onChange}) => (
 <select value={category} onChange={(e: any) => onChange(e.target.value)}>
  <option value={ChatCategoryEnum.Normal}>{t("Работа в обычном режиме")}</option>
  <option value={ChatCategoryEnum.Lag}>{t("Работа с отставанием")}</option>
  <option value={ChatCategoryEnum.Deffect}>{t("Работа с недочётами")}</option>
  <option value={ChatCategoryEnum.Accident}>{t("Авария")}</option>
 </select>
)
