import {
 VC_FETCHED, VC_INI, VC_MESSAGE_POST, VC_MESSAGE_POST_FAIL, VC_MESSAGE_POST_OK, VC_MESSAGE_READ, VC_MESSAGE_RECEIVED, VC_MESSAGES_FETCH,
 VC_MESSAGES_FETCH_FAIL,
 VC_MESSAGES_FETCH_OK, VC_ROOM_CAMS_UPDATED,
 VC_ROOM_DESELECT,
 VC_ROOM_HANDLE,
 VC_ROOM_HANDLE_FAIL,
 VC_ROOM_HANDLE_OK, VC_ROOM_REMOVED,
 VC_ROOM_SELECT, VC_UNREAD_MESSAGES_FETCHED, VcActionTypes,
 VcState, VcUnreadMessagesMap
} from "./types";
import {IVcRoomItem, IVcRoomMessage, VcMessageStatusEnum} from "../../models/vc";

const iniState: VcState = {
 isFetching: false,
 rooms: [],
 isRoomHandling: false,
 selectedRoom: "",
 isMessagesFetching: false,
 messages: [],
 cams: [],
 isMessagePosting: false,
 unread: {},
}

export function vcReducer(state: VcState = iniState, action: VcActionTypes): VcState {
 switch (action.type) {
  case VC_INI:
   return {...state, isFetching: true};
  case VC_FETCHED:
   return {...state, isFetching: false, rooms: action.rooms};

  case VC_ROOM_HANDLE:
   return {...state, isRoomHandling: true};
  case VC_ROOM_HANDLE_FAIL:
   return {...state, isRoomHandling: false};
  case VC_ROOM_HANDLE_OK:
   return vcRoomHandle(state, action.room);
  case VC_ROOM_REMOVED:
   return {...state, rooms: state.rooms.filter(f => f.room.id !== action.roomId)};

  case VC_ROOM_SELECT:
   return {...state, selectedRoom: action.roomId};
  case VC_ROOM_DESELECT:
   return {...state, selectedRoom: "", isMessagesFetching: false, messages: [], cams: []};

  case VC_MESSAGES_FETCH:
   return {...state, isMessagesFetching: true};
  case VC_MESSAGES_FETCH_FAIL:
   return {...state, isMessagesFetching: false};
  case VC_MESSAGES_FETCH_OK:
   return {...state, isMessagesFetching: false, messages: action.messages, cams: action.cams}; // @todo add unread?

  case VC_MESSAGE_POST:
   return {...state, isMessagePosting: true};
  case VC_MESSAGE_POST_FAIL:
   return {...state, isMessagePosting: false};
  case VC_MESSAGE_POST_OK:
   return {...state, isMessagePosting: false, messages: [...state.messages, action.msg]}; //@todo is room selected checks.

  case VC_MESSAGE_READ:
   return vcMessageReadHandle(state, action.msgId, action.roomId);
  case VC_MESSAGE_RECEIVED:
   return vcMessageReceivedHandle(state, action.msg);
  case VC_UNREAD_MESSAGES_FETCHED:
   return vcUnreadMessagesFetchedHandle(state, action.messages);

  case VC_ROOM_CAMS_UPDATED:
   return {...state, cams: action.cams};

  default:
   return state;
 }
}

const vcRoomHandle = (state: VcState, roomItem: IVcRoomItem): VcState => {
 const rooms: IVcRoomItem[] = (state.rooms.find(f => f.room.id === roomItem.room.id))
  ? state.rooms.map(f => (f.room.id === roomItem.room.id) ? roomItem : f)
  : [...state.rooms, roomItem];
 return {...state, isRoomHandling: false, rooms};
}

const vcMessageReadHandle = (state: VcState, msgId: string, roomId: string): VcState => {
 const idx = (state.unread[roomId]) ? state.unread[roomId].indexOf(msgId) : -1;
 if (idx >= 0) {
  const unread = {...state.unread};
  unread[roomId].splice(idx, 1);
  if (unread[roomId].length <= 0)
   delete unread[roomId];
  if (state.selectedRoom === roomId) {
   const messages = state.messages.map(m => ((m.id === msgId) ? {...m, status: VcMessageStatusEnum.Read} : m));
   return {...state, unread, messages};
  }
  return {...state, unread};
 }
 return state;
}

const vcMessageReceivedHandle = (state: VcState, msg: IVcRoomMessage): VcState => {
 let unread: VcUnreadMessagesMap | undefined = undefined;
 if (msg.status && msg.status === VcMessageStatusEnum.Unread) {
  unread = {...state.unread};
  if(!unread[msg.roomId])
   unread[msg.roomId] = [];
  unread[msg.roomId].push(msg.id);
 }
 let messages: IVcRoomMessage[] | undefined = undefined;
 if (state.selectedRoom === msg.roomId) {
  messages = [...state.messages, msg];
 }
 if(unread !== undefined || messages !== undefined) {
  let updatedState = {...state};
  if(unread !== undefined)
   updatedState.unread = unread;
  if(messages !== undefined)
   updatedState.messages = messages;
  return updatedState;
 }
 return state;
}

const vcUnreadMessagesFetchedHandle = (state: VcState, messages: IVcRoomMessage[]): VcState => {
 const unread: VcUnreadMessagesMap = messages.reduce((p,c) => {
  if(!p[c.roomId])
   p[c.roomId] = [];
  p[c.roomId].push(c.id);
  return p;
 }, {} as VcUnreadMessagesMap);
 return {...state, unread};
}
