import React from "react";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {messengerChatSettings, messengerLoadChat, messengerUnloadChat} from "../../store/messenger/actions";
import {
 ChatCategoryEnum, ChatSettingsNotifyEnum, ChatTypeEnum, ChatUserRoleEnum, IChatExt, IChatMessage,
 IChatUserRole
} from "../../models/messenger";
import Message from "./message";
import MessengerForm from './form';
import PageLoader from "../../components/pageloader";
import MessengerModifyChat from "./modify";
import {IContact, IOnlineUsersMap} from "../../models/contact";
import {isSameDay} from "../../utils/date";
import {ChatUsers} from "./chat.users";
import {IVWell} from "../../models/struct";
import {IKeyStringMap} from "../../models/common";
import {t} from "../../lang";

interface IChatWellCategoryHelperProps {
 category: ChatCategoryEnum
}

const ChatWellCategoryHelper: React.FC<IChatWellCategoryHelperProps> = ({category}) => {
 if (category === ChatCategoryEnum.Accident)
  return <span className={"label-right cat-accident"}>{t("Авария")}</span>;
 if (category === ChatCategoryEnum.Deffect)
  return <span className={"label-right cat-deffect"}>{t("Работа с недочётами")}</span>;
 if (category === ChatCategoryEnum.Lag)
  return <span className={"label-right cat-lag"}>{t("Работа с отставанием")}</span>;
 return null;
}

interface IProps {
 chatId: string
 myId: string
 contacts: IContact[]
 chat?: IChatExt
 myRole: ChatUserRoleEnum
 selectedChatId: string
 online: IOnlineUsersMap,
 users: IChatUserRole[]
 messages: IChatMessage[]
 messengerLoadChat: any
 messengerUnloadChat: any
 messengerChatSettings: any
 well?: IVWell
}

const mapState = (state: AppState) => {
 const chat = state.messengerState.chats.find(f => (f.id === state.messengerState.selectedChatId));
 const currentChatRole = (chat && chat.id === state.messengerState.selectedChatId) ? state.messengerState.users.find(f => f.userId === state.userState.user.id) : undefined;
 const myRole: ChatUserRoleEnum = currentChatRole ? currentChatRole.role : ChatUserRoleEnum.Blocked;
 const myId = state.userState.user.id;
 const well = (chat && chat.type === ChatTypeEnum.Well && chat.targetId) ? state.ocState.wells.find(f => f.id === chat.targetId) : undefined;
 return {
  myId,
  myRole,
  chat,
  well,
  online: state.ocState.online,
  users: state.messengerState.users,
  messages: state.messengerState.messages,
  selectedChatId: state.messengerState.selectedChatId,
 }
}

const mapDispatch = {
 messengerChatSettings,
 messengerLoadChat,
 messengerUnloadChat
}

interface IState {
 chatModifying: boolean
 mentioned: IKeyStringMap
}

class MessengerChat extends React.Component<IProps, IState> {
 state: IState = {
  chatModifying: false,
  mentioned: {}
 }

 componentDidMount() {
  this.loadChat();
 }

 componentDidUpdate(prevProps: IProps) {
  if (prevProps.chatId !== this.props.chatId) {
   this.setState({chatModifying: false});
   this.loadChat();
  }
 }

 componentWillUnmount() {
  this.props.messengerUnloadChat();
 }

 loadChat = () => {
  if (this.props.chatId !== this.props.selectedChatId) {
   this.props.messengerLoadChat(this.props.chatId);
  }
 }

 showChatModifyForm = () => {
  this.setState({chatModifying: true});
 }

 cancelChatModify = () => {
  this.setState({chatModifying: false});
 }

 userMention = (userId: string): void => {
  if(this.state.mentioned[userId]) {
   const mentioned = {...this.state.mentioned};
   delete mentioned[userId];
   this.setState({mentioned});
  }
  else {
   const user = this.props.contacts.find(f => f.id===userId);
   if(user) {
    const mentioned = {...this.state.mentioned};
    mentioned[userId] = user.login;
    this.setState({mentioned});
   }
  }
 }

 userMentionByLogin = (userLogin: string): void => {
  const user = this.props.contacts.find(f => f.login===userLogin);
  if(user && !this.state.mentioned[user.id])
   this.userMention(user.id);
 }

 clearUserMentions = (): void => {
  this.setState({mentioned:{}});
 }

 render() {
  const {selectedChatId, chat, well, myRole, myId, contacts, users, online, messages} = this.props;
  if (!chat)
   return <PageLoader label={t("Беседа не найдена")}/>;

  if (this.state.chatModifying)
   return <MessengerModifyChat chat={chat} users={users} contacts={contacts} cancelModifyChat={this.cancelChatModify}/>

  let prevDate: Date | undefined = undefined;
  const showForm = (!Boolean(chat.closedAt) && (myRole !== ChatUserRoleEnum.Blocked));

  const isSilentNotify = Boolean(chat.notify === ChatSettingsNotifyEnum.Silent);

  return (
   <div className="MessengerBody">
    <div className={"header"}>
     {well && (
      <div className={"helper-row"}>
       <span className={"label-left"}><i className={"fas fa-sitemap"}></i> {well.path}</span>
       {chat.category && <ChatWellCategoryHelper category={chat.category*1}/>}
      </div>
     )}
     <h2>
      <label className={"chat-name"}>{chat.name}</label>
     </h2>
     <div className="controls">
      <button onClick={() => this.props.messengerChatSettings(chat.id, (isSilentNotify? ChatSettingsNotifyEnum.Normal: ChatSettingsNotifyEnum.Silent))}>
       <i className={isSilentNotify? "far fa-bell-slash": "far fa-bell"}></i>
       <label>{isSilentNotify? t("Включить уведомления"): t("Выключить уведомления")}</label>
      </button>
      {(myRole === ChatUserRoleEnum.Admin) &&
      <button onClick={() => this.showChatModifyForm()}><i className={"fas fa-user-edit"}></i><label>{t("Изменить")}</label></button>}
      {(chat.type !== ChatTypeEnum.Personal) && <ChatUsers myId={myId} users={users} online={online} mentioned={this.state.mentioned} userMention={this.userMention} />}
     </div>
    </div>
    {showForm && <MessengerForm chatId={selectedChatId} mentioned={this.state.mentioned} userMention={this.userMention} userMentionByLogin={this.userMentionByLogin} clearUserMentions={this.clearUserMentions} />}
    <div className={"body ChatMsgs"}>
     {messages.length > 0 ? (
      <ul className="MessengerChat">
       {messages.map(m => {
        const showDate = !isSameDay(m.createdAt, prevDate);
        const isMy = Boolean(m.ownerId === myId);
        const isUserOnline = Boolean(!isMy && online[m.ownerId]);
        prevDate = m.createdAt;
        return (
         <li key={m.id}><Message isMy={isMy} isUserOnline={isUserOnline} showDate={showDate} msg={m}/></li>
        )
       })}
      </ul>
     ) : (
      <div className={"no-messages"}>{t("пока нет ни одного сообщения")}</div>
     )}
    </div>
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(MessengerChat);
