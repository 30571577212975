import {errorLog} from "./console";

const soundsControl = document.createElement('audio');
soundsControl.autoplay = true;

export const phonePlaySound = (sound: string, loop = false) => {
 if (soundsControl) {
  try {
   soundsControl.pause();
   soundsControl.currentTime = 0.0;
   soundsControl.src = process.env.PUBLIC_URL + "/assets/sounds/" + sound;
   soundsControl.loop = loop;
   soundsControl.play();
  }
  catch (err) {
   errorLog("phone play sound", err);
  }
 }
}

export const phoneStopSound = () => {
 if (soundsControl) {
  soundsControl.pause();
  soundsControl.currentTime = 0.0;
 }
}

export const phoneLog = (msg: string, param: any = null) => {
 if(window.console) {
  const time = (new Date().toLocaleTimeString());
  if(param)
   console.log("Phone", time, msg, param);
  else
   console.log("Phone", time, msg);
 }
}