import React from "react";
import {IChat} from "../../models/messenger";
import {MessengerUnreadMap} from "../../store/messenger/types";
import {AppState} from "../../store";
import {connect} from "react-redux";
import SidebarHistory from "./sidebar.history";
import SidebarContacts from "./sidebar.contacts";
import {t} from "../../lang";

interface IProps {
 iniMode: string
 chatCreation: boolean
 showChatCreateForm: Function
 chats: IChat[]
 unread: MessengerUnreadMap
}

const mapState = (state: AppState) => ({
 chats: state.messengerState.chats,
 unread: state.messengerState.unread,
})

enum TabEnum {
 History = "history",
 Contacts = "contacts"
}

interface IState {
 tab: TabEnum
}

class Sidebar extends React.Component<IProps, IState> {
 state: IState = {
  tab: ((this.props.iniMode==="u")? TabEnum.Contacts: TabEnum.History)
 }

 componentDidMount() {
 }

 changeTab = (tab: TabEnum) => this.setState({tab});

 render() {
  const tab = this.state.tab;
  // const {chatItems, unread} = this.props;
  return (
   <div className={"MessengerSidebar"}>
    <div className="header">
     <ul className={"tabs"}>
      <li className={tab===TabEnum.History? "active":""}>
       <button className={"history"} onClick={()=>this.changeTab(TabEnum.History)}>{t("История")}</button>
      </li>
      <li className={tab===TabEnum.Contacts? "active":""}>
       <button className={"contacts"} onClick={()=>this.changeTab(TabEnum.Contacts)}>{t("Контакты")}</button>
      </li>
     </ul>
     <div className={"add"}>
      <button disabled={this.props.chatCreation} onClick={() => this.props.showChatCreateForm()}><i className={"fas fa-plus"}></i><label>{t("Создать новый чат")}</label></button>
     </div>
    </div>
    {(tab===TabEnum.History) && <SidebarHistory />}
    {(tab===TabEnum.Contacts) && <SidebarContacts />}
    {/*
    <div className="list">
     {chatItems.length > 0 ? (
      <ul>
       {chatItems.map(c => {
        const u = unread[c.chat.id]? unread[c.chat.id].length: 0;
        return <li key={c.chat.id}>
         <NavLink to={"/app/msg/g/" + (c.chat.id)}>{c.chat.name}</NavLink>
         {u > 0 && <span className={"unread"}>{u}</span>}
        </li>
       })}
      </ul>
     ) : (<div className={"no-chats"}>Пока нет ни одной беседы</div>)}
    </div>
    */}
   </div>
  )
 }
}

export default connect(mapState)(Sidebar);
