import {t} from "../../lang"
import botHelpOptions from "./helpOptions";
class ActionProvider {
    private createChatBotMessage: any;
    private setState: any;
    private createClientMessage: any;
    constructor(createChatBotMessage: any, setStateFunc: any, createClientMessage:any) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
    }
    rude() {
        const rudeAnswer = this.createChatBotMessage(t("Фу как грубо"));
        this.updateChatbotState(rudeAnswer)
    }
    thanks() {
        const thanksAnswer = this.createChatBotMessage(t("Всегда пожалуйста"));
        this.updateChatbotState(thanksAnswer)
    }
    repeatoptions() {
        const optAnswer = this.createChatBotMessage(t("Что вас интересует?"),
        {widget: "helpOptions", withAvatar: true});
        this.updateChatbotState(optAnswer);
    }




    updateChatbotState(message) {

// NOTE: This function is set in the constructor, and is passed in      // from the top level Chatbot component. The setState function here     // actually manipulates the top level state of the Chatbot, so it's     // important that we make sure that we preserve the previous state.


        this.setState(prevState => ({
            ...prevState, messages: [...prevState.messages, message]
        }))
    }

}

export default ActionProvider;