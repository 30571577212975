export const CALL_TYPE_INCOMING = 1;
export const CALL_TYPE_OUTGOING = 2;
export const CALL_TYPE_CANCELED = 3;
export const CALL_TYPE_MISSED = 10;
export const CALL_TYPE_MISSED_NEW = 11;

export type CallType = typeof CALL_TYPE_INCOMING
 | typeof CALL_TYPE_OUTGOING
 | typeof CALL_TYPE_CANCELED
 | typeof CALL_TYPE_MISSED
 | typeof CALL_TYPE_MISSED_NEW

export interface ICall {
 type: CallType
 when: Date
 contact: string
}
