import L from "leaflet";

export const firefoxIcon = L.icon({
 iconUrl: process.env.PUBLIC_URL + '/assets/marker3.png',
 iconSize: [28, 38], // size of the icon
});
export const peopleIcon = L.icon({
 iconUrl: process.env.PUBLIC_URL + '/assets/marker2.png',
 iconSize: [28, 38], // size of the icon
})

export const svgCircleIcon = L.icon({
 iconUrl: process.env.PUBLIC_URL + '/assets/svg/circle.svg',
 iconSize: [8, 8],
})

export const circleSmallIcon = L.icon({
 iconUrl: process.env.PUBLIC_URL + '/assets/marker_circle_small.png',
 iconSize: [16, 16],
})
