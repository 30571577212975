import React from "react";
import {connect} from "react-redux";

import {AppState} from "../../store";
import {userLogin, userLoadProfile} from "../../store/user/actions";
import {t} from "../../lang";

interface IProps {
 isFetching: boolean
 error: any
 userLogin: any

}

const mapState = (state: AppState) => ({
 isFetching: state.userState.isFetching,
 error: state.userState.error
})

const mapDispatch = {
 userLogin,
 userProfile: userLoadProfile
}

interface IState {
 login: string
 passwd: string
}

class Login extends React.Component<IProps, IState> {
 state = {
  login: "",
  passwd: ""
 }

 changeInputValue = (e: any) => this.setState({[e.target.name]: e.target.value} as IState)

 submitForm = (e: any) => {
  e.preventDefault();
  this.props.userLogin(this.state.login, this.state.passwd);
 }

 render() {
  return (
   <div className="Login">
    <div className="SignInBlock">
     <div className="Logo">
      <h1>{t("Супер-око")}</h1>
     </div>
     <form className="SignInForm" onSubmit={this.submitForm}>
      <input type="text" placeholder={t("Пользователь")} name="login" value={this.state.login} onChange={this.changeInputValue} disabled={this.props.isFetching} required autoComplete="off"/>
      <input type="password" placeholder={t("Пароль")} name="passwd" value={this.state.passwd} onChange={this.changeInputValue} disabled={this.props.isFetching} required/>
      <button type="submit" className="SignInForm-SubmitBtn" disabled={this.props.isFetching}>{t("Войти")}</button>
     </form>
    </div>
   </div>
  );

 }
}

export default connect(mapState, mapDispatch)(Login);
