import React from "react";
import {IContact} from "../../models/contact";
import {t} from "../../lang";

interface IProps {
 contacts: IContact[]
 onCall: (num: string) => void
}

interface IState {
 search: string
}


export class Contacts extends React.Component<IProps, IState> {
 state: IState = {
  search: ""
 }

 changeSearch = (e: any) => this.setState({search: e.target.value});

 render() {
  const search = this.state.search.toLowerCase().trim();
  const contacts = Boolean(search) ? this.props.contacts.filter(f => f.name.toLowerCase().indexOf(search) >= 0) : this.props.contacts;
  return (
   <div className="ContactsTab">
    <div className="search-box">
     <input type="search" placeholder={t("Поиск")} value={this.state.search} onChange={this.changeSearch}/>
     <i className={"fas fa-search"}></i>
    </div>
    <div className="contacts">
     {(contacts.length > 0) ? (
      <ul>
       {contacts.map((c, i) => <li key={i}>
        <button onClick={() => this.props.onCall(c.sipNumber)}>
         <label className="name"><i className={"far fa-user-circle"}></i>{c.name}</label>
         <label className="number">{c.sipNumber}</label>
        </button>
       </li>)}
      </ul>
     ) : (
      <div className="no-contact">{t("Пользователь не найден")}</div>
     )}
    </div>
   </div>
  )
 }
}