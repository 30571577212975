import React from "react"
import {NavLink} from "react-router-dom";
import {t} from "../../lang";

const Nav: React.FC = () => (
    <nav>

        <ul>
            <li><NavLink to={"/app/monitor/realtime"}>{t("Реальное время")}</NavLink></li>
            <li><NavLink to={"/app/monitor/history"}>{t("История")}</NavLink></li>
            {/*<li><button className="Settings"><NavLink to={"/app/monitor/settings"}>*/}
            {/*    <i className={"fas fa-bell fa-2x"} title={t("Настройка уведомлений")}></i>*/}
            {/*    </NavLink></button>*/}
            {/*</li>*/}
        </ul>
    </nav>
);

export default Nav
