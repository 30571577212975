import {IChatExt, IChatItem, IChatLastUpdateMap, IChatMessage, IChatUserRole} from "../../models/messenger";

export interface MessengerUnreadMap {
 [key: string]: string[];
}

export interface MessengerState {
 // chatItems: IChatItem[]
 chats: IChatExt[]
 selectedChatId: string
 // notify: ChatSettingsNotifyEnum;
 // targetId: string;
 users: IChatUserRole[]
 messages: IChatMessage[]
 isChatHandling: boolean
 isSelecting: boolean
 isFetching: boolean
 isPosting: boolean
 unread: MessengerUnreadMap
 updates: IChatLastUpdateMap
}

export const MESSENGER_INI = 'Messenger/ini';

interface MessengerIniAction {
 type: typeof MESSENGER_INI;
}

export const MESSENGER_FETCHED = 'Messenger/fetched';

interface MessengerFetchedAction {
 type: typeof MESSENGER_FETCHED
 chats: IChatExt[]
 updates: IChatLastUpdateMap
}

//

export const MESSENGER_CHAT_HANDLE = 'Messenger/chatHandle';

interface MessengerChatHandleAction {
 type: typeof MESSENGER_CHAT_HANDLE
}

export const MESSENGER_CHAT_HANDLE_OK = 'Messenger/chatHandleOk';

interface MessengerChatHandleOkAction {
 type: typeof MESSENGER_CHAT_HANDLE_OK
 chatItem: IChatItem
}

export const MESSENGER_CHAT_HANDLE_FAIL = 'Messenger/chatHandleFail';

interface MessengerChatHandleFailAction {
 type: typeof MESSENGER_CHAT_HANDLE_FAIL
}

export const MESSENGER_CHAT_UPDATED = 'Messenger/chatUpdated';

interface MessengerChatUpdatedAction {
 type: typeof MESSENGER_CHAT_UPDATED
 chatItem: IChatItem
}

//

export const MESSENGER_CHAT_SELECT = 'Messenger/chatSelect';

interface MessengerChatSelectAction {
 type: typeof MESSENGER_CHAT_SELECT
 selectedChat: string
}

export const MESSENGER_CHAT_SELECT_OK = 'Messenger/chatSelectOk';

interface MessengerChatSelectOkAction {
 type: typeof MESSENGER_CHAT_SELECT_OK
 messages: IChatMessage[]
 users: IChatUserRole[]
}

export const MESSENGER_CHAT_SELECT_FAIL = 'Messenger/chatSelectFail';

interface MessengerChatSelectFailAction {
 type: typeof MESSENGER_CHAT_SELECT_FAIL
}

export const MESSENGER_CHAT_DESELECT = 'Messenger/chatDeselect';

interface MessengerChatDeselectAction {
 type: typeof MESSENGER_CHAT_DESELECT
}

export const MESSENGER_CHAT_REMOVED = 'Messenger/ChatRemoved';

interface MessengerChatRemovedAction {
 type: typeof MESSENGER_CHAT_REMOVED
 chatId: string
}

//

export const MESSENGER_MSG_POST = 'Messenger/messagePost';

interface MessengerMsgPostAction {
 type: typeof MESSENGER_MSG_POST
}

export const MESSENGER_MSG_POST_OK = 'Messenger/messagePostOk';

interface MessengerMsgPostOkAction {
 type: typeof MESSENGER_MSG_POST_OK
 msg: IChatMessage
}

export const MESSENGER_MSG_POST_FAIL = 'Messenger/messagePostFail';

interface MessengerMsgPostFailAction {
 type: typeof MESSENGER_MSG_POST_FAIL
}

export const MESSENGER_MSG_RECEIVED = 'Messenger/messageReceived';

interface MessengerMsgReceivedAction {
 type: typeof MESSENGER_MSG_RECEIVED
 msg: IChatMessage
}

//

export const MESSENGER_MSG_READ = 'Messenger/readMsg';
interface MessengerMsgReadAction {
 type: typeof MESSENGER_MSG_READ
 msgId: string
 chatId: string
}

export const MESSENGER_UNREADS_RECEIVED = 'Messenger/unreadReceived';
interface MessengerUnreadsReceived {
 type: typeof MESSENGER_UNREADS_RECEIVED
 messages: IChatMessage[]
}

export type MessengerActionTypes = MessengerIniAction
 | MessengerFetchedAction

 | MessengerChatHandleAction
 | MessengerChatHandleOkAction
 | MessengerChatHandleFailAction
 | MessengerChatUpdatedAction
 | MessengerChatRemovedAction

 | MessengerChatSelectAction
 | MessengerChatSelectOkAction
 | MessengerChatSelectFailAction
 | MessengerChatDeselectAction

 | MessengerMsgPostAction
 | MessengerMsgPostOkAction
 | MessengerMsgPostFailAction
 | MessengerMsgReceivedAction

 | MessengerMsgReadAction
 | MessengerUnreadsReceived
