import {
 PHONE_ADD_HISTORY,
 PHONE_CONNECTED, PHONE_CONNECTING, PHONE_DISCONNECTED, PHONE_END_SESSIONS, PHONE_INCOMING, PHONE_INCOMING_ACCEPTED, PHONE_INI, PHONE_MARK_ALL_MISSED_HISTORY, PHONE_OUTGOING,
 PHONE_OUTGOING_ACCEPTED,
 PhoneActionTypes
} from "./types";
import {CALL_TYPE_OUTGOING, ICall} from "../../models/call";
import {mediaConstraints, pcConfig, rtcOfferConstraints} from "../../containers/phone/config";
import {phoneLog, phonePlaySound} from "../../utils/phone";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../index";
import {errorLog} from "../../utils/console";
import {phoneActivate} from "../../containers/phone/helper";


export const phoneIni = (userAgent: any): PhoneActionTypes => {
 return {type: PHONE_INI, userAgent};
}

export const phoneConnecting = (): PhoneActionTypes => {
 return {type: PHONE_CONNECTING};
}

export const phoneConnected = (): PhoneActionTypes => {
 return {type: PHONE_CONNECTED}
}

export const phoneDisconnected = (): PhoneActionTypes => {
 return {type: PHONE_DISCONNECTED};
}

export const phoneIncoming = (incomingSession: any): PhoneActionTypes => {
 return {type: PHONE_INCOMING, incomingSession}
}

export const phoneIncomingAccepted = (activeSession: any): PhoneActionTypes => {
 return {type: PHONE_INCOMING_ACCEPTED, activeSession}
}

export const phoneOutgoing = (activeSession: any): PhoneActionTypes => {
 return {type: PHONE_OUTGOING, activeSession}
}

export const phoneOutgoingAccepted = (): PhoneActionTypes => {
 return {type: PHONE_OUTGOING_ACCEPTED}
}

export const phoneEndSessions = (): PhoneActionTypes => {
 return {type: PHONE_END_SESSIONS}
}

export const phoneAddHistory = (call: ICall): PhoneActionTypes => {
 return {type: PHONE_ADD_HISTORY, call}
}

export const phoneMarkAllMissedHistory = (): PhoneActionTypes => {
 return {type: PHONE_MARK_ALL_MISSED_HISTORY}
}


type PhoneAction = ThunkAction<void, AppState, undefined, PhoneActionTypes>;

export const phoneOutgoingCall = (contact: string): PhoneAction => async(dispatch, setState) => {
 const state = setState();
 try {
  phoneActivate();
  phoneLog("Outgoing call", contact);

  const session = state.phoneState.userAgent.call(contact, {pcConfig, mediaConstraints, rtcOfferConstraints});
  phoneLog("Outgoing session", session);

  // Астер нас соединил с абонентом
  session.on('connecting', () => {
   phoneLog("Outgoing connecting");
   phonePlaySound("ringback.ogg", true);
   dispatch({type:PHONE_OUTGOING, activeSession: session});
   // this.props.phoneOutgoing(session);
  });

  // В процессе дозвона
  session.on('progress', () => {
   phonePlaySound("ringback.ogg", true);
  });

  // Дозвон завершился неудачно, например, абонент сбросил звонок
  session.on('failed', (data) => {
   phoneLog("Outgoing session failed", data);
   phonePlaySound('rejected.mp3', false);
   dispatch({type: PHONE_END_SESSIONS});
   dispatch({type: PHONE_ADD_HISTORY, call: {when: new Date(), type: CALL_TYPE_OUTGOING, contact: session.remote_identity.uri.user}})
   // this.props.phoneEndSessions();
   // this.props.phoneAddHistory({when: new Date(), type: CALL_TYPE_OUTGOING, contact: session.remote_identity.uri.user})
  });

  session.on('ended', () => {
   phoneLog("Outgoing session ended");
   phonePlaySound("rejected.mp3", false);
   dispatch({type: PHONE_END_SESSIONS});
   dispatch({type: PHONE_ADD_HISTORY, call: {when: new Date(), type: CALL_TYPE_OUTGOING, contact: session.remote_identity.uri.user}})
   // this.props.phoneEndSessions();
   // this.props.phoneAddHistory({when: new Date(), type: CALL_TYPE_OUTGOING, contact: session.remote_identity.uri.user})
  });

  // Звонок принят, можно начинать говорить
  session.on('accepted', (e: any) => {
   phoneLog("Outgoing session accepted", e);
   phonePlaySound("answered.mp3", false);
   dispatch({type: PHONE_OUTGOING_ACCEPTED});
   // this.props.phoneOutgoingAccepted();
  });
 }
 catch (error) {
  errorLog("Phone error", error);
  // phoneLog("Phone error", error);
 }
}
