import {ThunkAction} from "redux-thunk";
import {CONFIG_ERROR, CONFIG_FETCH, CONFIG_OK, ConfigActionTypes, ConfigState} from "./types";
import {errorLog} from "../../utils/console";
import {httpGet} from "../../utils/http";
import {IAppConfig} from "../../models/config";

type ConfigAction = ThunkAction<void, ConfigState, undefined, ConfigActionTypes>

export const configLoad = (): ConfigAction => async dispatch => {
 try {
  dispatch({type: CONFIG_FETCH});
  const config = await httpGet<IAppConfig>(process.env.PUBLIC_URL+'/config.json');
  dispatch({type: CONFIG_OK, config});
  if(config && config.vc && config.vc.script) {
   const script = document.createElement('script');
   // script.src = process.env.PUBLIC_URL+'/vc.js';
   script.src = config.vc.script;
   document.body.appendChild(script);
  }
 }
 catch (error) {
  errorLog('Config load error', error);
  dispatch({type: CONFIG_ERROR, error});
 }
}
