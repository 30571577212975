import React from "react";
import {t} from "../../lang"

class MessageParser {
    private actionProvider: any;
    private state: any;
    constructor(actionProvider:any, state:any) {
        this.actionProvider = actionProvider;
        this.state = state;
    }

    parse(message:any) {
        console.log(message);
        let isKnown=false;
        const lowerCaseMessage = message.toLowerCase();

        if (lowerCaseMessage.includes(t("дурак"))||lowerCaseMessage.includes(t("идиот"))) {
                this.actionProvider.rude();
                isKnown=true;
        }
        if (lowerCaseMessage.includes(t("спасибо"))||lowerCaseMessage.includes(t("благодарю"))) {
            this.actionProvider.thanks();
            isKnown=true;
        }
        if (!isKnown)
        {
            this.actionProvider.repeatoptions();
        }
    }
}

export default MessageParser;