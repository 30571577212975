import {AppLevelEnum, IAppConfig} from "../../models/config";
import {CONFIG_ERROR, CONFIG_FETCH, CONFIG_OK, ConfigActionTypes, ConfigState} from "./types";

const defaultConfig: IAppConfig = {
 langs: ["ru"],
 sip: {uri:"sip:__USER__@localhost", socket:"wss://localhost/sip", realm:"localhost"},
 vc: {server:"https://localhost", script:"https://localhost", schedulerTimeout:2500},
 chat: {schedulerTimeout:2000},
 level: AppLevelEnum.Station,
};

const iniState: ConfigState = {
 isFetching: false,
 isLoaded: false,
 config: defaultConfig,
 error: undefined
}

export function configReducer(state = iniState, action: ConfigActionTypes): ConfigState {
 switch (action.type) {
  case CONFIG_FETCH:
   return {...state, isFetching: true};
  case CONFIG_OK:
   return {...state, isFetching: false, isLoaded: true, config: action.config};
  case CONFIG_ERROR:
   return {...state, isFetching: false, error: action.error};
  default:
   return state;
 }
}