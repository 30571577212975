import React from "react";
import {RouteComponentProps} from "react-router";
import {vcCloseRoom, vcLoadRoom, vcUnloadRoom} from "../../store/vc/actions";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {IVcCam, IVcRoomItem, IVcRoomMessage, VcUserRoleEnum} from "../../models/vc";
import PageLoader from "../../components/pageloader";
import {VcMessages} from "./messages";
import VcMessageForm from "./message.form";
import {VcVideo} from "./video";
import {PageError} from "../../components/pageerror";
import {ParticipantsBox} from "./participants.box";
import {t} from "../../lang";

interface IRoute {
 id: string;
}

interface IProps extends RouteComponentProps<IRoute> {
 myId: string
 myName: string
 server: string
 script: string
 isFetching: boolean
 roomItem?: IVcRoomItem
 myRole: VcUserRoleEnum
 isMessagesFetching: boolean
 messages: IVcRoomMessage[]
 cams: IVcCam[]
 vcLoadRoom: any
 vcUnloadRoom: any,
 vcCloseRoom: any,
}

const mapState = (state: AppState, own: RouteComponentProps<IRoute>) => {
 const roomItem = state.vcState.rooms.find(f => f.room.id===own.match.params.id);
 const myRoleItem = (roomItem)? roomItem.users.find(f => f.userId===state.userState.user.id): undefined;
 const myRole: VcUserRoleEnum = myRoleItem? myRoleItem.role: VcUserRoleEnum.Blocked;
 return {
  roomItem,
  myRole,
  myId: state.userState.user.id,
  myName: state.userState.user.name,
  server: state.configState.config.vc.server,
  script: state.configState.config.vc.script,
  isFetching: state.vcState.isFetching,
  isMessagesFetching: state.vcState.isMessagesFetching,
  messages: state.vcState.messages,
  cams: state.vcState.cams,
 }
}

const mapDispatch = {
 vcLoadRoom,
 vcUnloadRoom,
 vcCloseRoom,
}

interface IState {
 isVertical: boolean
 participantsVisible: boolean
}

class VcRoom extends React.Component<IProps, IState> {
 state: IState = {
  isVertical: false,
  participantsVisible: false,
 }

 componentDidMount() {
  this.props.vcLoadRoom(this.props.match.params.id);
 }

 componentDidUpdate(prevProps: IProps) {
  if(this.props.match.params.id !== prevProps.match.params.id)
   this.props.vcLoadRoom(this.props.match.params.id);
 }

 componentWillUnmount() {
  this.props.vcUnloadRoom();
 }

 toggleLayout = () => this.setState({isVertical: !this.state.isVertical});

 toggleParticipants = () => this.setState({participantsVisible: !this.state.participantsVisible});

 hideParticipants = () => this.setState({participantsVisible: false});

 clickCloseRoom = () => {
  if(this.props.roomItem && window.confirm(t("Завершить конференцию?"))) {
   this.props.vcCloseRoom(this.props.roomItem.room.id);
  }
 }
  render() {
  if(this.props.isFetching)
   return <PageLoader label={t("Загрузка")}/>;
  if(!this.props.roomItem)
   return <PageError label={t("Видеоконференция не найдена")}/>;
  const {roomItem, myId, myName, myRole, messages, cams, server, script} = this.props;
  const {isVertical, participantsVisible} = this.state;
  const isAdmin = (myRole === VcUserRoleEnum.Admin);
  const isActive = (!Boolean(roomItem.room.closedAt) && myRole !== VcUserRoleEnum.Blocked);
  return (
   <div className={"VcRoom" + (isActive ? (" active-room" + (isVertical ? " body-vertical" : " body-horizontal")) : " closed-room")}>
    <div className={"header"}>
     <h1>{roomItem.room.name}</h1>
     <div className={"controls"}>
      {isActive && isAdmin && (
       <button onClick={this.clickCloseRoom}>
        <i className={"fas fa-video-slash"}></i>
        <label>{t("Завершить видеоконференцию")}</label>
       </button>
      )}
      {isActive && isAdmin && (
       <button onClick={this.toggleParticipants}>
        <i className="fas fa-users"></i>
        {!participantsVisible && <label>{t("Участники")}</label>}
       </button>
      )}
      {isActive && (
       <button onClick={this.toggleLayout}>
        <i className={"fas " + (isVertical ? "fa-grip-lines" : "fa-grip-lines-vertical")}></i>
        <label>{isVertical ? t("Вертикальное отображение") : t("Горизонтальное отображение")}</label>
       </button>
      )}
     </div>
     {isActive && isAdmin && participantsVisible && <ParticipantsBox roomItem={roomItem} cams={cams} closeHandler={this.hideParticipants} />}
    </div>
    {isActive && <VcVideo userId={myId} userName={myName} userRole={myRole} room={roomItem.room.id} server={server} script={script} />}
    <VcMessages myId={myId} messages={messages}/>
    {isActive && <VcMessageForm roomId={roomItem.room.id} />}
   </div>
  )
 }
}

export default connect(mapState,mapDispatch)(VcRoom);