import {IAppConfig} from "../../models/config";

export interface ConfigState {
 config: IAppConfig
 isLoaded: boolean
 isFetching: boolean
 error: any
}

export const CONFIG_FETCH = 'config/fetch';
interface ConfigFetchAction {
 type: typeof CONFIG_FETCH
}

export const CONFIG_OK = 'config/ok';
interface ConfigOkAction {
 type: typeof CONFIG_OK
 config: IAppConfig
}

export const CONFIG_ERROR = 'config/error';
interface ConfigErrorAction {
 type: typeof CONFIG_ERROR
 error: any
}

export type ConfigActionTypes = ConfigFetchAction | ConfigOkAction | ConfigErrorAction
