import React from "react";
import {t} from "../../lang";
import NavSettings from "./navsettings";
import {INotifyRule, INotifyRuleResponse} from "../../models/notification";
import {Link, RouteComponentProps} from "react-router-dom";
import {IUserFull} from "../../models/user";
import {AppState} from "../../store";
import {connect} from "react-redux";



interface IProps {
    me: IUserFull,
}
const mapState = (state: AppState) => {
    return {
        me: state.userState.user
    };
}

interface IState {
    rules: INotifyRule[]
}

const testRules: INotifyRuleResponse={
    rules:[
        {
            id:"1",
            description:"first test rule",
            owner_id:"1",
            owner_name:"Admin",
            active: true
        },
        {
            id:"2",
            description:"second test rule",
            owner_id:"6f2c2f6a-0d0d-4442-8f33-f97dadf9d978",
            owner_name:"Виктория Ро",
            active: false
        },
        {
            id:"3",
            description:"Test rule",
            owner_id:"6f2c2f6a-0d0d-4442-8f33-f97dadf9d978",
            owner_name:"Виктория Ро",
            active: true
        }
    ]
};


class MonitorSettings extends React.Component<IProps & RouteComponentProps, IState> {
    state: IState = {
        rules: testRules.rules

}
    componentDidMount(): void {

    }

    activeStatus(id: string, status: boolean):void{
        const index = this.state.rules.findIndex(r => r.id === id),
            updatedrules = [...this.state.rules];
        updatedrules[index].active = status;
        this.setState({rules: updatedrules});


        //let rules =this.state.rules.map(el=>(el.id===id? {...el, status} : el));
        //this.setState({rules: rules});

    }

    render() {
        return (
            <div className="MonitorSettingsContainer">
                <NavSettings/>
                <div className="Monitor">
                    <div className="title">
                        <h1>{t("Параметры уведомлений")}</h1>
                        <div className="SettingsList">
                        <table>
                            <thead>
                            <tr>
                                <th>{t("Описание")}</th>
                                <th>{t("Владелец")}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.rules.map(w => (
                                <tr key={w.id}>
                                    <td>{w.description}</td>
                                    <td>{w.owner_name}</td>
                                    <td className={"actions"}></td>
                                    <td className={"actions"}>{
                                        w.owner_id===this.props.me.id ?
                                        <Link className={"labeled-link"} to={`/app/monitor/settings/edit/${w.id}`}><i className={"fas fa-edit"}></i><label>{t("Посмотреть/Изменить")}</label></Link>:
                                            <Link className={"labeled-link"} to={`/app/monitor/settings/view/${w.id}`}><i className={"fas fa-edit"}></i><label>{t("Посмотреть/Изменить")}</label></Link>

                                    }</td>
                                    <td className={"actions"}>{w.active ?  <span onClick={()=>this.activeStatus(w.id, false)}><i className={"fas fa-toggle-on "}> </i>{t("Вкл.")}</span>: <span onClick={()=>this.activeStatus(w.id, true)}><i className={"fas fa-toggle-off"}> </i>{t("Выкл.")}</span>}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                </div>
            </div>
            </div>
        )
    }
}

export default connect(mapState) (MonitorSettings);