import {sprintf} from "sprintf-js";
import {httpGet} from "../utils/http";
import {getQueryParams} from "../utils/url";
import {numNames, numNamesDummy} from "../utils/str";

interface ILangLabelMap {
 [key: string]: string
}

interface ILangPack {
 lang: string
 labels: ILangLabelMap
}

const _langPack: ILangPack = {lang: "", labels: {}};


export const langIni = async (langs: string[]): Promise<string> => {
 let lang = langs[0];
 const queryParams = getQueryParams(window.location.search);
 if (queryParams["lang"] && langs.indexOf(queryParams["lang"]) >= 0) {
  lang = queryParams["lang"];
  if (window.localStorage) {
   window.localStorage["lang"] = queryParams["lang"];
   const otherParams = {...queryParams};
   delete otherParams["lang"];
   const newSearch = Object.keys(otherParams).reduce((p, c) => {
    return p + (Boolean(p) ? ('&' + c + '=' + otherParams[c]) : (c + '=' + otherParams[c]));
   }, "");
   window.location.search = newSearch;
  }
 }
 if (window.localStorage && window.localStorage["lang"] && langs.indexOf(window.localStorage["lang"]) >= 0)
  lang = window.localStorage["lang"];

 const labels = await httpGet<ILangLabelMap>(process.env.PUBLIC_URL + '/lang/' + lang + '.json');
 _langPack.lang = lang;
 _langPack.labels = labels;

 return lang;
};

export const getLang = (): string => _langPack.lang;

export const t = (label: string, ...params: string[]): string => {
 if (_langPack.lang && _langPack.labels[label])
  return (params.length > 0) ? sprintf(_langPack.labels[label], params) : _langPack.labels[label];
 return (params.length > 0) ? sprintf(label, params) : label;
};

export const tn = (num: number, names: string[], withNum: boolean = true): string => {
 const lineNames = names.join("||");
 if(_langPack.lang && _langPack.labels[lineNames]) {
  const lngNames = _langPack.labels[lineNames].split("||");
  return numNamesDummy(num, lngNames, withNum);
 }
 return numNames(num, names, withNum);
}
