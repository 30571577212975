import React from "react"
import {NavLink} from "react-router-dom";
import {t} from "../../lang";

const Nav: React.FC = () => (
 <nav>
  <ul>
   <li><NavLink to={"/app/maps/realtime"}>{t("Реальное время")}</NavLink></li>
   <li><NavLink to={"/app/maps/history"}>{t("История перемещений")}</NavLink></li>
  </ul>
 </nav>
)

export default Nav
