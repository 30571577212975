import React from "react";
import {t} from "../../lang";
import {consoleLog} from "../../utils/console";

interface IProps {
    onNumChange: (num: string) => void
    onDTMF: (num: string) => void
    isToneModeEnabled: boolean;
}

interface IState {
    num: string
}

export class Keyboard extends React.Component<IProps, IState> {
    state: IState = {
        num: ""
    };
    timeout=0;

timerDTMF = (num:string)=>{
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
        clearTimeout(this.timeout);
        this.DTMF(num)
    }, 3000);

};
cancelDTMFTimer=()=> {
    clearTimeout(this.timeout);
}
    changeNum = (e: any) =>
    {
        e.preventDefault();
        this.props.onNumChange(e.target.value);
        this.setState({num: e.target.value});
        if (this.props.isToneModeEnabled) {
            this.timerDTMF(e.target.value);
        }
    };

    enterNum= (e: any) => {

        e.preventDefault();
        this.setState({num: this.state.num+e.target.id});
        this.props.onNumChange(this.state.num+e.target.id);
        if (this.props.isToneModeEnabled) {
           this.timerDTMF(this.state.num+e.target.id);
        }
    };

    deleteNum = (e: any) => {
        e.preventDefault();
        this.setState({num: this.state.num.slice(0,-1)});
        this.props.onNumChange(this.state.num.slice(0,-1));
        if (this.props.isToneModeEnabled) {
            this.timerDTMF(this.state.num.slice(0,-1));
        }
    };

    clearNum = () => {
        this.setState({num: ""});
        this.props.onNumChange("");
    };

    DTMF = (num:string) => {

        this.props.onDTMF(num);
        this.clearNum();
    };
    componentWillUnmount() {
        clearTimeout(this.timeout);
    }


    render() {
        return (
            <div className="PhoneKeyboard"   >
                <div className="input">
                <input type="text" value={this.state.num} onChange={this.changeNum.bind(this)} placeholder={t("Укажите номер")}/>
                <button type="button" onClick={this.deleteNum}  disabled={!Boolean(this.state.num)}  ><i className={"fas fa-backspace " }></i></button>
                </div>

                <div className="actions">
                    <button type="button" onClick={this.enterNum} id={"1"}>1</button>
                    <button type="button" onClick={this.enterNum} id={"2"}>2</button>
                     <button type="button" onClick={this.enterNum} id={"3"}>3</button>
                </div>
                <div className="actions">
                    <button type="button" onClick={this.enterNum} id={"4"}>4</button>
                    <button type="button" onClick={this.enterNum} id={"5"}>5</button>
                    <button type="button" onClick={this.enterNum} id={"6"}>6</button>

                </div>
                <div className="actions">
                    <button type="button" onClick={this.enterNum} id={"7"}>7</button>
                    <button type="button" onClick={this.enterNum} id={"8"}>8</button>
                    <button type="button" onClick={this.enterNum} id={"9"}>9</button>
                </div>
                <div className="actions">
                    <button type="button"  onClick={this.enterNum} id={"#"}>#</button>
                    <button type="button"  onClick={this.enterNum} id={"0"}>0</button>
                    <button type="button" onClick={this.enterNum} id={"*"} ><i >*</i></button>
                </div>
            </div>
        )
    }
}