
import React from "react";
import {IZMFrame, IZMFrameResponse, IZMFullScreenFrame} from "../../models/zm_frame";
import {t} from "../../lang";



interface IProps {
    eventId: string;
    onClose: any
}

interface IState {
    frames: IZMFrame[]
    selectedFrame: IZMFrame | any
    windows: Window []
}

class EventVideo extends React.Component<IProps, IState> {
    state: IState = {
        frames: [],
        selectedFrame: {id: "", fs_frame: ""},
        windows:[]
    };

    clickedParent = () => {
        this.props.onClose()
    };

    clickedChild = () => {
    };

    componentDidMount(): void {
    }




    render() {
        return (<div className="Modal" onClick={this.clickedParent}>
            <div className="ModalActions">
                <button >
                    <i className="far fa-window-close fa-2x" title={t("Закрыть")} onClick={this.clickedParent}></i>
                </button>
            </div>
            <div className="Data" onClick={e => {
                e.stopPropagation();
                this.clickedChild();
            }}>

                <video controls autoPlay >
                    <source
                        src={"https://soho.petroviser.ru/vas/api/video?mode=mpeg&format=h264&eid="+this.props.eventId+"&view=view_video"}
                        type="video/mp4"/>
                </video>


            </div>
        </div>)
    }

}

export default EventVideo;