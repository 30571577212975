export interface ISipConfig {
 socket: string
 uri: string
 realm: string
}

export interface IVcConfig {
 server: string
 script: string
 schedulerTimeout: number
}

export interface IChatConfig {
 schedulerTimeout: number
}

export enum AppLevelEnum {
 Station = "station",
 Office = "office"
}

export interface IAppConfig {
 langs: string[]
 sip: ISipConfig
 vc: IVcConfig
 chat: IChatConfig
 level: AppLevelEnum // уровень приложения office | station
}