import React from "react";
import {IVWell} from "../../models/struct";
import {Link} from "react-router-dom";
import {IKeyMap} from "../../models/common";
import {t} from "../../lang";

interface IProps {
 wells: IVWell[]
 admin: IKeyMap
}

// todo remove asterisk in well name

const OcWellsList: React.FC<IProps> = ({wells, admin}) => {
 return (wells.length > 0) ? (
  <div className={"oc-list"}>
   <table>
    <thead>
    <tr>
     <th>{t("Компания")}</th>
     <th>{t("Месторождение")}</th>
     <th>{t("Куст")}</th>
     <th>{t("Скважина")}</th>
     <th></th>
    </tr>
    </thead>
    <tbody>
    {wells.map(w => (
     <tr key={w.id}>
      <td>{w.company}</td>
      <td>{w.locality}</td>
      <td>{w.cluster ? w.cluster : <span className={"empty"}>{t("нет")}</span>}</td>
      <td>{w.well}</td>
      <td className={"actions"}>
       {admin[w.id] && <Link className={"labeled-link"} to={`/app/chat/cw/${w.id}`}><i className={"fas fa-comment"}></i><label>{t("Создать чат")}</label></Link>}
       <Link className={"labeled-link"} to={`/app/wells/${w.id}/contacts`}><i className={"fas fa-address-book"}></i><label>{t("Контакты")}</label></Link>
      </td>
     </tr>
    ))}
    </tbody>
   </table>
  </div>
 ): (
  <div className={"oc-empty"}>{t("Объекты не найдены")}</div>
 )
}

export default OcWellsList;