// export interface IHttpResponse<T> extends Response {
//  parsedBody?: T;
// }

interface HeadersMap {
 [key: string]: string
}

let headers: HeadersMap = {"content-type": "application/json"};

const commonHeaders = () => {
 return headers;
}

export const headerRegister = (name: string, value: string) => {
 headers[name] = value;
}

export const headerUnregister = (name: string) => {
 if (headers[name])
  delete headers[name];
}

export const headerIsRegistred = (name: string): boolean => {
 return Boolean(headers[name]);
}

export const headerRegistredValue = (name: string): string => {
 return headers[name] || "";
}

let errorHandler401 = () => {
};

export const httpRegisterGlobalErrorHandler401 = (handler: any) => {
 errorHandler401 = handler
}

export const httpUnregisterGlobalErrorHandler401 = () => {
 errorHandler401 = () => {
 };
}

export const http = <T>(request: RequestInfo): Promise<T> => {
 return fetch(request)
  .then(response => {
   const ok = response.ok;
   if (response.status === 401 && Boolean(errorHandler401))
    errorHandler401();
   return response.json()
    .then(json => {
     if (!ok) {
      throw json;
     }
     return json as T;
    })
  })
};

export const httpSilent = (method: string, path: string, body: object | undefined = undefined): Promise<boolean> => {
 const headers = commonHeaders();
 const credentials = 'include';
 const args: RequestInit = (body) ? {method, headers, credentials, body: JSON.stringify(body)} : {method, headers, credentials}
 return fetch(new Request(path, args)).then(
  response => {
   const ok = response.ok;
   if (response.status === 401 && Boolean(errorHandler401))
    errorHandler401();
   if (!ok)
    throw ok;
   return ok;
  }
 )
}

export const httpGet = async <T>(path: string): Promise<T> => {
 const headers = commonHeaders();
 const args: RequestInit = {method: "get", headers, credentials: 'include'};
 return await http<T>(new Request(path, args))
}

export const httpPost = async <T>(path: string, body: any): Promise<T> => {
 const headers = commonHeaders();
 const args: RequestInit = {method: "post", headers, credentials: 'include', body: JSON.stringify(body)};
 return await http<T>(new Request(path, args));
}

export const httpPostRawBody = async <T>(path: string, body: any): Promise<T> => {
 const common = commonHeaders();
 const headers = {...common};
 delete headers['content-type'];
 const args: RequestInit = {method: "post", headers, credentials: 'include', body};
 return await http<T>(new Request(path, args));
}

export const httpPut = async <T>(path: string, body: any): Promise<T> => {
 const headers = commonHeaders();
 const args: RequestInit = {method: "put", headers, credentials: 'include', body: JSON.stringify(body)};
 return await http<T>(new Request(path, args));
}

export const httpDelete = async <T>(path: string, body: any): Promise<T> => {
 const headers = commonHeaders();
 const args: RequestInit = {method: "delete", headers, credentials: 'include', body: JSON.stringify(body)};
 return await http<T>(new Request(path, args));
}
