const monthNames: string[] = [
 "января",
 "февраля",
 "марта",
 "апреля",
 "мая",
 "июня",
 "июля",
 "августа",
 "сентября",
 "октября",
 "ноября",
 "декабря"
];

type dateFormatType = "md" | "mn" | "t" | "mnt" | "mdt" | "default";

export const d = (date: Date, format: dateFormatType = "default"): string => {
 switch (format) {
  case "md":
   return date.getDate() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
  case "mn":
   return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear();
  case "t":
   return date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
  case "mdt":
   return date.getDate() + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " в " + date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
  case "mnt":
   return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear() + " в " + date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
  default:
   return date.toString();
 }
}


export const isSameDay = (a: Date, b?: Date): boolean => {
 if(b && a.getFullYear()===b.getFullYear() && a.getMonth()===b.getMonth() && a.getDate()===b.getDate())
  return true;
 return false;
}
