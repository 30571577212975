import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import L, {LatLng} from "leaflet";
import {Map, LayersControl, ImageOverlay, Circle, Polygon, LayerGroup, Polyline, Tooltip} from "react-leaflet";

import Nav from "./nav";
import {AppState} from "../../store";
import {IContact} from "../../models/contact";
import {bounds, center, circleRadius, defaultZoom, image, minZoom} from "./stub";
import {HistorySidebar} from "./historysidebar";
import {httpPost} from "../../utils/http";
import {errorLog} from "../../utils/console";
import {IMapHistory, IMapHistoryResult, timeToTick, tickTimeString} from "./models";
import HistoryTimeline from "./historytimeline";
import HistoryRoute from "./historyroute";
import {t} from "../../lang";

const {BaseLayer, Overlay} = LayersControl;

interface IRoute {
 user?: string
}

interface IProps extends RouteComponentProps<IRoute> {
 contacts: IContact[]
}

const mapState = (state: AppState) => {
 const contacts = state.ocState.contacts;
 return {contacts};
}

interface IState {
 date: Date
 isFetching: boolean
 points: IMapHistory[]
 time?: number
 interval: number
 pointLabel: string
}

interface IMapHistoryCx {
 times: number[]
 lines: ILinesMap
 route: LatLng[]
 cx: number
}

interface ILinesMap {
 [key: string]: LatLng[]
}

class MapsHistory extends React.Component<IProps, IState> {
 state: IState = {
  date: new Date(),
  isFetching: false,
  interval: 1,
  points: [],
  pointLabel: ""
 }

 componentDidMount() {
  const interval = window.localStorage ? Number(window.localStorage["historyInterval"]) : undefined;
  if (interval)
   this.setState({interval});
  this.dataLoad(this.state.date, this.props.match.params.user);
 }

 componentDidUpdate(prevProps: IProps, prevState: IState) {
  if (this.props.match.params.user !== prevProps.match.params.user
   || this.state.date.toDateString() !== prevState.date.toDateString()
   || this.props.contacts !== prevProps.contacts
  )
   this.dataLoad(this.state.date, this.props.match.params.user);
 }

 dataLoad = async (date: Date, user?: string) => {
  const contact = this.props.contacts.find(f => f.login === user);
  if (contact) {
   this.setState({isFetching: true});
   try {
    const res = await httpPost<IMapHistoryResult[]>('/mapi/gps/GetOneDayTrackStory', {id: contact.device, startTime: date});
    const points: IMapHistory[] = res.map((r: IMapHistoryResult) => {
     // const hour = Number(r.hour);
     const minute = Number(r.minute);
     const tick = timeToTick({hour: 0, minute});
     // const {hour, minute} = r;
     // const tick = timeToTick({0, minute});
     // const coordinates = JSON.parse(r.coordinates).map(f => {
     //  return (f.length===2)? [f[1], f[0]]: null;
     // }).filter(f => f !== null);
     const coordinates: LatLng[] = [];
     for (let i = 0; i < r.coordinates.length; i++) {
      const c = r.coordinates[i];
      if (c.length === 2)
       coordinates.push({lat: c[1], lng: c[0]} as LatLng)
     }
     return {tick, coordinates};
    })
    const time = points[0].tick;
    this.setState({points, time});
   }
   catch (error) {
    errorLog('data load error', error);
    this.setState({points: [], time: undefined});
   }
   this.setState({isFetching: false});
  }
  else {
   // console.log('cleared');
  }
 }

 dateChange = (date: Date) => {
  if (date)
   this.setState({date});
 }

 selectInterval = (interval: number) => {
  this.setState({interval});
  if (window.localStorage)
   window.localStorage["historyInterval"] = interval;
 }

 selectTime = (time: number) => this.setState({time});

 render() {
  const selectedUser = this.props.contacts.find(f => f.login === this.props.match.params.user);
  const {interval, time} = this.state;
  const res: IMapHistoryCx = this.state.points.reduce((p, c) => {
   const t = c.tick;
   if (p.cx < 0 || (t - p.cx >= interval)) {
    p.times.push(t);
    p.cx = t;
   }
   if (time) {
    if (time <= t && t < (time + interval)) {
     const ts = t.toString();
     if (!p.lines[ts]) {
      const otherLines = Object.keys(p.lines);
      if (otherLines.length > 0)
       p.lines[otherLines[otherLines.length - 1]].push(c.coordinates[0]);
      p.lines[ts] = [];
     }
     c.coordinates.forEach(point => {
      p.lines[ts].push(point);
      p.route.push(point);
     })
    }
   }
   return p;
  }, {route: [], lines: {}, times: [], cx: -1} as IMapHistoryCx);


  return (
   <div className="MapContainer">
    <Nav/>
    <div className="MapWrapper">
     <div className="map-row">
      <div className="MapSidebarBox">
       <div className="Map HistoryMapBox" id="leafletMap">
        <HistoryTimeline times={res.times} interval={interval} selectInterval={this.selectInterval} selected={time} selectTime={this.selectTime}/>
        <div className="HistoryMap">
         <Map crs={L.CRS.Simple} center={center} minZoom={minZoom} zoom={defaultZoom} maxBounds={bounds}>
          <LayersControl position={"topright"}>
           <BaseLayer checked name={t("Базовый слой")}>
            <ImageOverlay url={image}
                          bounds={bounds}/>
           </BaseLayer>
           <Overlay name={t("Выделенные зоны")}>
            <LayerGroup>
             <Circle center={center} color={"red"} fillColor={"red"} fillOpacity={.3} radius={circleRadius}/>
             <Polygon positions={[
              [5684708, 3588825],
              [5684705, 3588871],
              [5684691, 3588868],
              [5684694, 3588822]
             ]} color={"green"} fillColor={"green"} fillOpacity={.3}/>
            </LayerGroup>
           </Overlay>
          </LayersControl>
          {Object.keys(res.lines).map((t, i) => (
           <Polyline key={i}
                     positions={res.lines[t]}
                     onMouseOver={(e: any) => e.target.setStyle({color: "#999", opacity: 1})}
                     onMouseOut={(e: any) => e.target.setStyle({color: "#ccc", opacity: 0.3})}
                     weight={7}
                     opacity={0.3}
                     color={"#ccc"}>
            <Tooltip sticky={true}>{tickTimeString(Number(t))}</Tooltip>
           </Polyline>
          ))}
          <HistoryRoute positions={res.route}/>
         </Map>
        </div>
       </div>
       <HistorySidebar contacts={this.props.contacts}
                       selectedUser={selectedUser}
                       date={this.state.date}
                       dateChange={this.dateChange}
       />
      </div>
     </div>
     {this.state.isFetching && <div className={"loader"}><i className={"fas fa-spinner"}></i></div>}
    </div>

   </div>
  )
 }
}

export default connect(mapState)(MapsHistory);
