import React from "react";
import {connect} from "react-redux";

import {HideButton} from "./hide.button";
import {DialPad} from "./dialpad";
import {Contacts} from "./contacts";
import History from "./history";
import {IUser} from "../../models/user";
import {AppState} from "../../store";
import {IContact} from "../../models/contact";
import {CALL_TYPE_MISSED_NEW} from "../../models/call";
import {phoneMarkAllMissedHistory} from "../../store/phone/actions";
import {t} from "../../lang";

const TAB_DIALPAD = "dialpad";
const TAB_CONTACTS = "contacts";
const TAB_HISTORY = "history";
type TabEnum = typeof TAB_DIALPAD | typeof TAB_CONTACTS | typeof TAB_HISTORY;

const storageLoadTab = (): TabEnum => {
 if (window.localStorage && window.localStorage["dialerTab"]) {
  switch (window.localStorage["dialerTab"]) {
   case TAB_CONTACTS:
    return TAB_CONTACTS;
   case TAB_HISTORY:
    return TAB_HISTORY;
  }
 }
 return TAB_DIALPAD;
}

interface IProps {
 me: IUser
 contacts: IContact[]
 missingCount: number
 phoneMarkAllMissedHistory: typeof phoneMarkAllMissedHistory
 onCall: (num: string) => void
}

const mapState = (state: AppState) => {
 return {
  me: state.userState.user,
  contacts: state.ocState.contacts.filter(f => Boolean(f.sipNumber)),
  missingCount: state.phoneState.history.filter(f => f.type === CALL_TYPE_MISSED_NEW).length,
 }
}
const mapDispatch = {
 phoneMarkAllMissedHistory
}

interface IState {
 tab: TabEnum
}

class Dialer extends React.Component<IProps, IState> {
 state: IState = {
  tab: TAB_DIALPAD
 };

 componentDidMount() {
  const tab = storageLoadTab();
  this.setState({tab});
 }

 changeTab = (tab: TabEnum) => {
  const clearMissedHistory = (this.state.tab===TAB_HISTORY && tab !== TAB_HISTORY);
  this.setState({tab}, () => {
   if (window.localStorage)
    window.localStorage["dialerTab"] = tab;
   if(clearMissedHistory)
    this.props.phoneMarkAllMissedHistory();
  });
 }

 render() {
  const {tab} = this.state;
  return (
   <div className="DialBox">
    <div className="tabs">
     <ul>
      <li className={(tab === TAB_DIALPAD) ? "active" : ""}>
       <button className="dialpad" onClick={() => this.changeTab(TAB_DIALPAD)}>{t("Номер")}</button>
      </li>
      <li className={(tab === TAB_CONTACTS) ? "active" : ""}>
       <button className="contacts" onClick={() => this.changeTab(TAB_CONTACTS)}>{t("Контакты")}</button>
      </li>
      <li className={(tab === TAB_HISTORY) ? "active" : ""}>
       <button className="history" onClick={() => this.changeTab(TAB_HISTORY)}>
        {this.props.missingCount > 0 && <span className="counter">{this.props.missingCount}</span>}
        {t("История")}
       </button>
      </li>
     </ul>
    </div>
    <div className="body">
     {tab === TAB_DIALPAD && <DialPad onCall={this.props.onCall}/>}
     {tab === TAB_CONTACTS && <Contacts onCall={this.props.onCall} contacts={this.props.contacts}/>}
     {tab === TAB_HISTORY && <History onCall={this.props.onCall} contacts={this.props.contacts}/>}
    </div>
    <HideButton/>
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(Dialer);
