import {IChatExt, IChatItem, IChatMessage} from "../models/messenger";
import {IVcRoomItem, IVcRoomMessage} from "../models/vc";

export const responseFixChat = (chat: IChatExt): IChatExt => {
 chat.createdAt = new Date(chat.createdAt);
 if(chat.closedAt)
  chat.closedAt = new Date(chat.closedAt);
 return chat;
}

export const responseFixChatItem = (chatItem: IChatItem): IChatItem => {
 chatItem.chat = responseFixChat(chatItem.chat);
 // chatItem.chat.createdAt = new Date(chatItem.chat.createdAt);
 // if(chatItem.chat.closedAt)
 //  chatItem.chat.closedAt = new Date(chatItem.chat.closedAt);
 return chatItem;
}

export const responseFixChatMessage = (m: IChatMessage): IChatMessage => {
 m.createdAt = new Date(m.createdAt);
 return m;
}

export const responseFixVcRoomItem = (roomItem: IVcRoomItem): IVcRoomItem => {
 roomItem.room.createdAt = new Date(roomItem.room.createdAt);
 if(roomItem.room.closedAt)
  roomItem.room.closedAt = new Date(roomItem.room.closedAt);
 return roomItem;
}

export const responseFixVcRoomMessage = (m: IVcRoomMessage): IVcRoomMessage => {
 m.createdAt = new Date(m.createdAt);
 return m;
}
