import React from "react";
import {IContact} from "../../models/contact";
import MapSidebarContactCard, {ContactCardMode} from "./contact.card";
import {Link} from "react-router-dom";
import {t} from "../../lang";


interface IProps {
 contact: IContact
 // onClose: () => void
}

export const MapSidebarContact = (props: IProps) => {


 return (
  <div className="Sidebar">
   <h3>{t("Пользователь")}
    {/*<button className="fas fa-times-circle" onClick={props.onClose}></button>*/}
   </h3>

   {props.contact ? (<MapSidebarContactCard contact={props.contact} mode={ContactCardMode.Realtime} />
   ) : (
    <div className="Card">
     <div className="error">
      {t("Невозможно загрузить карточку")}
     </div>
    </div>
   )}

   <div className="close">
    <Link to={"/app/maps/realtime"}>{t("Закрыть")}</Link>
   </div>

  </div>
 )
}
