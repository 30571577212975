import React from "react";
import {IContact} from "../../models/contact";
import {AppState} from "../../store";
import {ocContactsLoad} from "../../store/oc/actions";
import {connect} from "react-redux";
import PageLoader from "../../components/pageloader";
import OcUsersList from "./users.list";
import {t} from "../../lang";

interface IProps {
 contacts: IContact[]
 isFetching: boolean
 reloadContacts: any
}

const mapState = (state: AppState) => ({
 contacts: state.ocState.contacts,
 isFetching: state.ocState.isFetching,
});

const mapDispatch = {
 reloadContacts: ocContactsLoad
}

const OcUsers: React.FC<IProps> = ({contacts, isFetching, reloadContacts}) => {
 const [search, setSearch] = React.useState<string>("");
 const [users, setUsers] = React.useState<IContact[]>([]);
 React.useEffect(() => {
  const query = search.toLowerCase().trim();
  const _users = query
   ? contacts.filter(f => (f.name.toLowerCase().indexOf(query) >= 0 || (f.post && f.post.toLowerCase().indexOf(query) >= 0)))
   : contacts;
  setUsers(_users);
 }, [contacts, search]);

 return (
  (isFetching) ? <PageLoader label={t("Загрузка")}/> :
   <div className={"OcPage"}>
    <div className={"header"}>
     <h1>{t("Контакты")}</h1>
     {contacts.length > 0 && <input type={"search"} placeholder={t("Поиск")} value={search} onChange={(e: any) => setSearch(e.target.value)}/>}
    </div>

    {(contacts.length > 0)? <OcUsersList contacts={users}/>: <h2 className={"empty-list"}>{t("Нет ни одного пользователя")}</h2>}

    <div className={"bottom-actions"}>
     <button onClick={() => reloadContacts()}><i className={"fas fa-sync"}></i> {t("обновить список")}</button>
    </div>

   </div>
 )
}

export default connect(mapState, mapDispatch)(OcUsers);
