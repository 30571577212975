import React from "react";
import {t} from "../../lang";
import NavSettings from "./navsettings";
import {INotifyRuleDetail, INotifyRuleResponse} from "../../models/notification";
import {Link, NavLink} from "react-router-dom";
import {AppState} from "../../store";
import {IUserFull} from "../../models/user";
import {connect} from "react-redux";


interface IProps {
    me: IUserFull,
}
const ruleDetails: INotifyRuleDetail[] = [{
    id: "1",
    description: "first test rule",
    owner_id: "1",
    owner_name: "Admin",
    active: true,
    selected_monitors: ["b2308ff5-b00b-43dc-a437-d3dc73211bae", "be464b64-5920-4f56-a4b3-1cc033fdf2b9"],
    method: "e-mail",
    method_params: "vv_ro@petroviser.ru",
    schedule: "По событию",
    time: ""

},
    {
        id:"2",
        description:"second test rule",
        owner_id:"6f2c2f6a-0d0d-4442-8f33-f97dadf9d978",
        owner_name:"Виктория Ро",
        active: false,
        selected_monitors: ["b2308ff5-b00b-43dc-a437-d3dc73211bae"],
        method: "e-mail",
        method_params: "vv_ro@petroviser.ru",
        schedule: "Настраиваемое",
        time: "10:30 UTC+3:00"

    },
    {
        id:"3",
        description:"Test rule",
        owner_id:"6f2c2f6a-0d0d-4442-8f33-f97dadf9d978",
        owner_name:"Виктория Ро",
        active: true,
        selected_monitors: ["b2308ff5-b00b-43dc-a437-d3dc73211bae", "be464b64-5920-4f56-a4b3-1cc033fdf2b9","cea5df8c-df38-4204-ae24-68a38b4906f3"],
        method: "e-mail",
        method_params: "vv_ro@petroviser.ru",
        schedule: "Каждый час",
        time: ""

    }];

interface IState {
    ruleDetails: INotifyRuleDetail;
}

const mapState = (state: AppState) => {
    return {
        me: state.userState.user
    };
}

class ViewMonitorRule extends React.Component<IProps, IState> {
    state: IState = {

        ruleDetails: {
            id: "",
            description: "",
            owner_id: "",
            owner_name: "",
            active: true,
            selected_monitors: [],
            method: "",
            method_params: "",
            schedule: "",
            time: ""
        }
    };

    componentDidMount(): void {
        console.log(this.props.me);
        this.getRuleDetails();

    }
    getRuleDetails (): void{
        console.log("id");
        // @ts-ignore
        const id = this.props.match.params.id;
        const index = ruleDetails.findIndex(r => r.id === id);
        this.setState({ruleDetails: ruleDetails[index]});

        console.log(id);

    }

    render() {
        return (
            <div className="MonitorSettingsContainer">
                <div className="Monitor">
                    <div className="RuleFormWrapper">
                        <form className="RuleForm">
                            <div>
                                <div className="title">
                                    <h1>{t("Посмотреть подписку")} : {this.state.ruleDetails.id}</h1>
                                </div>
                                <h2> {t("Описание")}</h2>
                                <input className="ReadOnly" readOnly value={t(this.state.ruleDetails.description)}/>
                                <div className="Container">
                                    <div>
                                        <h2> {t("Метод доставки")}</h2>
                                        <input className="ReadOnly" readOnly value={t(this.state.ruleDetails.method)}/>
                                    </div>
                                    <div>
                                        <h2> {t("Параметры")}</h2>
                                        <input className="ReadOnly" readOnly
                                               value={t(this.state.ruleDetails.method_params)}/>
                                    </div>
                                </div>
                                <div className="Container">
                                    <div>
                                        <h2> {t("Расписание")}</h2>
                                        <input className="ReadOnly" readOnly
                                               value={t(this.state.ruleDetails.schedule)}/>
                                        {this.state.ruleDetails.schedule === "Настраиваемое" && (
                                              <div className="Time">
                                                   <div>
                                            <input className="ReadOnly" readOnly
                                            value={t(this.state.ruleDetails.time.split(':')[0])+t("ч")}/>
                                            </div>
                                                  <div>
                                            <input className="ReadOnly" readOnly
                                            value={t(this.state.ruleDetails.time.split(':')[1].split(' ')[0]+t("мин"))}/>
                                                  </div>
                                                  <div>
                                            <input className="ReadOnly" readOnly
                                            value={t(this.state.ruleDetails.time.split(':')[1].split(' ')[1])}/>
                                                  </div>
                                            </div>)}
                                    </div>
                                    <div>
                                        <h2> {t("Объекты")}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="Actions">
                                <NavLink to={"/app/monitor/settings"}>
                                    <button type="button">{t("Отмена")}</button>
                                </NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapState)(ViewMonitorRule);