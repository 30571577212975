import React from "react";
import {LayerGroup, Polyline} from "react-leaflet";
import {LatLng} from "leaflet";

interface IProps {
 positions: LatLng[]
}

interface IState {
 ticks: number
}

class HistoryRoute extends React.Component<IProps, IState> {
 timeout: number = -1;
 state: IState = {
  ticks: 0
 };

 componentDidMount() {
  this.timeout = window.setTimeout(this.routeTick, 100);
 }

 componentWillUnmount() {
  clearTimeout(this.timeout);
  this.timeout = -1;
 }

 routeTick = () => {
  if (this.timeout) {
   const total = this.props.positions.length;
   const ticks = (this.state.ticks < total) ? this.state.ticks + 1 : 0;
   this.setState({ticks}, () => {
    this.timeout = window.setTimeout(this.routeTick, 250);
   })
  }
 }

 render() {
  return (this.props.positions.length > 0) ? (
   <LayerGroup>
    <Polyline positions={this.props.positions.filter((p, i) => ((this.state.ticks - 3 <= i) && (i <= this.state.ticks)))}
              color={'#504748'}
              weight={7}
              interactive={false}
              opacity={0.9}
    />
   </LayerGroup>
  ) : null;
 }
}

export default HistoryRoute;
