import React from "react";
import {IContact} from "../../models/contact";
import {AppState} from "../../store";
import {connect} from "react-redux";
import MessengerChat from "./chat";
import PageLoader from "../../components/pageloader";
import {messengerCreatePersonalChat} from "../../store/messenger/actions";
import {httpGet} from "../../utils/http";
import {t} from "../../lang";

interface IOwnProps {
 userId: string
 contacts: IContact[]
}

interface IProps extends IOwnProps {
 // chat?: IChat
 user?: IContact
 // myId: string
 messengerCreatePersonalChat: any
}

const mapState = (state: AppState, own: IOwnProps) => {
 // const myId = state.userState.user.id;
 const user = own.contacts.find(f => f.login === own.userId);
 // const chatItem = (user && user.id !== myId) ? state.messengerState.chats.find(f => {
 //  // if (f.type === ChatTypeEnum.Personal && f.users.length === 2) {
 //  //  if ((f.users[0].userId === myId && f.users[1].userId === user.id)
 //  //   || (f.users[1].userId === myId && f.users[0].userId === user.id))
 //  //   return true;
 //  // }
 //  return false;
 // }) : undefined;
 return {user}
}

const mapDispatch = {
 messengerCreatePersonalChat
}

interface IResponse {
 id: string
}

const MessengerPersonalWrapper: React.FC<IProps> = ({user, contacts, messengerCreatePersonalChat}: IProps) => {
 const [chatId, setChatId] = React.useState<string>();

 React.useEffect(() => {
  if(user && user.id)
   httpGet<IResponse>('/mapi/chats/person/'+user.id).then(res => {
    setChatId(res.id);
   }).catch(() => {
    setChatId("")
   })
 }, [user]);

 const click = async () => {
  if(user) {
   const id = await messengerCreatePersonalChat(user.id);
   if(window.console)
    console.log('Chat created', id);
   setChatId(id);
  }
 }

 if(chatId===undefined)
  return <PageLoader label={t("Поиск чата")}/>;

 if (!user)
  return <PageLoader label={t("Пользователь не найден")}/>;

 if (chatId)
  return <MessengerChat chatId={chatId} contacts={contacts}/>;

 return (
  <div className="MessengerIntro">
   <div className="intro">
    <label onClick={click}>{t("Начать беседу")}</label>
   </div>
  </div>
 )
}

export default connect(mapState, mapDispatch)(MessengerPersonalWrapper);

