import React from "react";
import {IContact, IOnlineUsersMap} from "../../models/contact";
import {Link} from "react-router-dom";
import {PHONE_STATUS_IDLE, PHONE_STATUS_OFFLINE, PhoneStatus} from "../../store/phone/types";
import {AppState} from "../../store";
import {connect} from "react-redux";
import {phoneOutgoingCall} from "../../store/phone/actions";
import {t} from "../../lang";

interface IProps {
 contacts: IContact[]
 online: IOnlineUsersMap
 status: PhoneStatus
 phoneOutgoingCall: any
}

const mapState = (state: AppState) => ({
 online: state.ocState.online,
 status: state.phoneState.status
});

const mapDispatch = {
 phoneOutgoingCall
}

const OcUsersList: React.FC<IProps> = ({contacts, online, status, phoneOutgoingCall}) => {
 const hasPhone = (status > PHONE_STATUS_OFFLINE);

 const clickPhone = (sip: string) => {
  phoneOutgoingCall(sip);
 }

 return (contacts.length > 0) ? (
  <div className={"oc-list"}>
   <table>
    <thead>
    <tr>
     <th>{t("Имя")}</th>
     <th>{t("Должность")}</th>
     <th>{t("Е-мейл")}</th>
     {hasPhone && <th></th>}
     <th></th>
    </tr>
    </thead>
    <tbody>
    {contacts.map(c => (
     <tr key={c.id}>
      <td className={"name-cell"}>
       {c.name}
       {c.isMyNode && <label className={"OnlineStatus" + (online[c.id]? " online":" offline")}>{online[c.id]? "online": "offline"}</label>}
      </td>
      <td>{c.post ? c.post : <span className={"empty"}>{t("нет")}</span>}</td>
      <td>{c.email ? c.email : <span className={"empty"}>{t("нет")}</span>}</td>
      {hasPhone && <td>{Boolean(c.sipNumber) && <button disabled={(status!==PHONE_STATUS_IDLE)} onClick={()=>clickPhone(c.sipNumber)} className={"labeled-button"}><i className={"fas fa-phone"}></i><label>{t("Позвонить")}</label></button>}</td>}
      <td><Link className={"labeled-link"} to={"/app/chat/u/"+c.login}><i className={"fas fa-comment"}></i><label>{t("Чат")}</label></Link></td>
     </tr>
    ))}
    </tbody>
   </table>
  </div>
 ) : (
  <div className={"oc-empty"}>{t("Пользователи не найдены")}</div>
 )
}

export default connect(mapState, mapDispatch)(OcUsersList);