import {IVcCam, IVcRoomItem, IVcRoomMessage} from "../../models/vc";

export interface VcUnreadMessagesMap {
 [key: string]: string[];
}

export interface VcState {
 isFetching: boolean // загрузка списка
 rooms: IVcRoomItem[] // список комнат
 isRoomHandling: boolean // обработка списка
 selectedRoom: string // id выбранного списка
 isMessagesFetching: boolean // загрузка сообщений
 messages: IVcRoomMessage[] // сообщения выбранного списка
 cams: IVcCam[] // внешние камеры комнаты
 isMessagePosting: boolean // отправка сообщения
 unread: VcUnreadMessagesMap // непрочитанные сообщения
}

/**
 * Запрос на загрузку списка комнат
 * @type {string}
 */
export const VC_INI = 'Vc/ini';

interface VcIniAction {
 type: typeof VC_INI
}

/**
 * Список комнат загружен
 * @type {string}
 */
export const VC_FETCHED = 'Vc/fetched';

interface VcFetchedAction {
 type: typeof VC_FETCHED
 rooms: IVcRoomItem[]
}

/**
 * Запрос на обработку комнаты
 * @type {string}
 */
export const VC_ROOM_HANDLE = 'Vc/room_handle';

interface VcRoomHandleAction {
 type: typeof VC_ROOM_HANDLE
}

/**
 * Запрос обработки комнаты выполнен
 * @type {string}
 */
export const VC_ROOM_HANDLE_OK = 'Vc/room_handle_ok';

interface VcRoomHandleOkAction {
 type: typeof VC_ROOM_HANDLE_OK
 room: IVcRoomItem
}

/**
 * Ошибка запроса обработки комнаты
 * @type {string}
 */
export const VC_ROOM_HANDLE_FAIL = 'Vc/room_handle_fail';

interface VcRoomHandleFailAction {
 type: typeof VC_ROOM_HANDLE_FAIL;
}

/**
 * Комната удалена
 * @type {string}
 */
export const VC_ROOM_REMOVED = 'Vc/room_removed';
interface VcRoomRemovedAction {
 type: typeof VC_ROOM_REMOVED
 roomId: string
}

/**
 * Выбор комнаты
 * @type {string}
 */
export const VC_ROOM_SELECT = 'Vc/room_select';

interface VcRoomSelectAction {
 type: typeof VC_ROOM_SELECT
 roomId: string;
}

/**
 * Запрос на загрузку сообщений
 * @type {string}
 */
export const VC_MESSAGES_FETCH = 'Vc/messages_fetch';

interface VcMessagesFetchAction {
 type: typeof VC_MESSAGES_FETCH
}

/**
 * Запрос на загрузку сообщений выполнен
 * @type {string}
 */
export const VC_MESSAGES_FETCH_OK = 'Vc/messages_fetch_ok';

interface VcMessagesFetchOkAction {
 type: typeof VC_MESSAGES_FETCH_OK
 messages: IVcRoomMessage[]
 cams: IVcCam[]
}

/**
 * Ошибка запроса получения сообщений
 * @type {string}
 */
export const VC_MESSAGES_FETCH_FAIL = 'Vc/messages_fetch_fail';

interface VcMessagesFetchFailAction {
 type: typeof VC_MESSAGES_FETCH_FAIL
}

/**
 * Комната не выбрана
 * @type {string}
 */
export const VC_ROOM_DESELECT = 'Vc/room_deselect';

interface VcRoomDeselectAction {
 type: typeof VC_ROOM_DESELECT
}

/**
 * Отправка сообщения
 * @type {string}
 */
export const VC_MESSAGE_POST = 'Vc/message_post';
interface VcMessagePostAction {
 type: typeof VC_MESSAGE_POST
}

/**
 * Сообщение отправлено
 * @type {string}
 */
export const VC_MESSAGE_POST_OK = 'Vc/message_post_ok';
interface VcMessagePostOkAction {
 type: typeof VC_MESSAGE_POST_OK
 msg: IVcRoomMessage
}

/**
 * Ошибка при отправке сообщения
 * @type {string}
 */
export const VC_MESSAGE_POST_FAIL = 'Vc/message_post_fail';
interface VcMessagePostFailAction {
 type: typeof VC_MESSAGE_POST_FAIL
}

/**
 * Сообщение прочитано
 * @type {string}
 */
export const VC_MESSAGE_READ = 'Vc/message_read';
interface VcMessageReadAction {
 type: typeof VC_MESSAGE_READ
 msgId: string
 roomId: string
}

/**
 * Получено сообщение
 * @type {string}
 */
export const VC_MESSAGE_RECEIVED = 'Vc/message_received';
interface VcMessageReceivedAction {
 type: typeof VC_MESSAGE_RECEIVED
 msg: IVcRoomMessage
}

/**
 * Получены непрочитанные сообщения
 * @type {string}
 */
export const VC_UNREAD_MESSAGES_FETCHED = 'Vc/unread_messages_fetched';
interface VcUnreadMessagesFetchedAction {
 type: typeof VC_UNREAD_MESSAGES_FETCHED
 messages: IVcRoomMessage[]
}

/**
 * Обновлены камеры в комнате
 * @type {string}
 */
export const VC_ROOM_CAMS_UPDATED = 'Vc/room_cams_updated';
interface VcRoomCamsUpdatedAction {
 type: typeof VC_ROOM_CAMS_UPDATED
 cams: IVcCam[]
}


export type VcActionTypes = VcIniAction | VcFetchedAction
 | VcRoomHandleAction | VcRoomHandleOkAction | VcRoomHandleFailAction | VcRoomRemovedAction
 | VcRoomSelectAction | VcRoomDeselectAction | VcRoomCamsUpdatedAction
 | VcMessagesFetchAction | VcMessagesFetchOkAction | VcMessagesFetchFailAction
 | VcMessagePostAction | VcMessagePostOkAction | VcMessagePostFailAction
 | VcMessageReadAction | VcMessageReceivedAction | VcUnreadMessagesFetchedAction
