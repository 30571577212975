export const userMentionRegex = /@(([a-zA-Z0-9])+(_([a-zA-Z0-9])+)*),/g;

interface IUserMentionText {
 text: string
 mentions: string[]
}

export const userMentionText = (msg: string): IUserMentionText => {
 let text = msg;
 let mentions: string[] = [];
 const match = msg.match(userMentionRegex);
 if (match && match.length > 0) {
  for (let i = 0; i < match.length; i++) {
   text = text.replace(match[i], "");
   const login = match[i].substring(1);
   mentions.push(login.substring(0, login.length - 1));
  }
 }
 return {text, mentions};
}


