import {
 CONTACT_CONNECTED, CONTACT_DISCONNECTED,
 CONTACTS_FAIL, CONTACTS_FETCH, CONTACTS_OK, CONTACTS_ONLINE, CONTACTS_RESET, CONTACTS_WELLS_LOADED, ContactsActionTypes,
 OcState
} from "./types";
import {IOnlineUsersMap} from "../../models/contact";

const iniState: OcState = {
 contacts: [],
 wells: [],
 admin: {},
 online: {},
 isFetching: false,
 error: undefined
}

export function ocReducer(state = iniState, action: ContactsActionTypes): OcState {
 switch (action.type) {
  case CONTACTS_FETCH:
   return {...state, isFetching: true, error: undefined};
  case CONTACTS_OK:
   return {...state, isFetching: false, contacts: action.contacts};
  case CONTACTS_WELLS_LOADED:
   return {...state, isFetching: false, wells: action.wells, admin: action.admin};
  case CONTACTS_FAIL:
   return {...state, isFetching: false, error: action.error};
  case CONTACTS_RESET:
   return iniState;

  case CONTACTS_ONLINE:
   return {...state, online: action.online};
  case CONTACT_CONNECTED:
   return {...state, online: userOnlineStatus(state.online, action.userId, true)};
  case CONTACT_DISCONNECTED:
   return {...state, online: userOnlineStatus(state.online, action.userId, false)};

  default:
   return state;
 }
}

const userOnlineStatus = (online: IOnlineUsersMap, userId: string, connected: boolean): IOnlineUsersMap => {
 const res = {...online}
 res[userId] = connected;
 return res;
};


