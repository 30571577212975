import React from "react";
import {IVcRoomMessage} from "../../models/vc";
import VcMessage from "./message";
import {isSameDay} from "../../utils/date";
import {t} from "../../lang";

interface IProps {
 myId: string
 messages: IVcRoomMessage[];
}

export const VcMessages: React.FC<IProps> = ({messages, myId}: IProps) => {

 let prevDate: Date | undefined = undefined;

 return (
  <div className="VcMessages">
   {(messages.length > 0)? (
    <ul>
     {messages.map(msg => {
      const showDate = !isSameDay(msg.createdAt, prevDate);
      prevDate = msg.createdAt;
      return (
       <li key={msg.id}>
        <VcMessage msg={msg} isMy={msg.ownerId===myId} showDate={showDate} />
       </li>
      )
     })}
    </ul>
   ): (<div className={"no-messages"}>{t("Нет сообщений")}</div>)}
  </div>
 )
}