export interface LiveState {
 socket?: WebSocket;
 liveId: string;
}

export const LIVE_CONNECT = 'Live/Connect';
interface LiveConnectAction {
 type: typeof LIVE_CONNECT
 socket: WebSocket
}

export const LIVE_CONNECTED = 'Live/Connected';
interface LiveConnectedAction {
 type: typeof LIVE_CONNECTED
 liveId: string
}

export const LIVE_DISCONNECTED = 'Live/Disconnected';
interface LiveDisconnectedAction {
 type: typeof LIVE_DISCONNECTED
}

export type LiveActionTypes = LiveConnectAction | LiveConnectedAction | LiveDisconnectedAction
