import React from "react";
import {IContact} from "../../models/contact";
import {Link} from "react-router-dom";
import {AppState} from "../../store";
import {connect} from "react-redux";
import {PHONE_STATUS_IDLE, PhoneStatus} from "../../store/phone/types";
import {phoneOutgoingCall} from "../../store/phone/actions";
import {t} from "../../lang";

export enum ContactCardMode {
 Realtime = 1,
 History = 2
}

interface IProps {
 contact: IContact
 mode: ContactCardMode
 phoneStatus: PhoneStatus
 phoneOutgoingCall: any
}

const mapState = (state: AppState) => ({
 phoneStatus: state.phoneState.status
})
const mapDispatch = {
 phoneOutgoingCall
}

const MapSidebarContactCard = (props: IProps) => {
 return (
  <div className="Card">
   <h2>{props.contact.name}</h2>
   <div className="email">{props.contact.email}</div>
   <div className="links">
    {(props.mode === ContactCardMode.History && <Link to={"/app/maps/realtime/" + props.contact.login}>
     <i className={"fas fa-map-marker-alt"}></i>
     <span>{t("Реальное время")}</span>
    </Link>)}
    {(props.mode === ContactCardMode.Realtime && <Link to={"/app/maps/history/" + props.contact.login}>
     <i className={"fas fa-history"}></i>
     <span>{t("История перемещений")}</span>
    </Link>)}
    <Link to={"/app/chat/u/" + props.contact.login}>
     <i className={"fas fa-comments"}></i>
     <span>{t("Чат")}</span>
    </Link>
    {(Boolean(props.contact.sipNumber) && props.phoneStatus >= PHONE_STATUS_IDLE) && (
     <button disabled={(props.phoneStatus !== PHONE_STATUS_IDLE)} onClick={() => props.phoneOutgoingCall(props.contact.sipNumber)}>
      <i className={"fas fa-phone"}></i>
      <span>{t("Позвонить")}</span>
     </button>)}
   </div>
  </div>
 )
}

export default connect(mapState, mapDispatch)(MapSidebarContactCard)
