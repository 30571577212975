import React from "react";
import JsSIP from "jssip";
import {getDisplayNameOrUserFromSession} from "./helper";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {IContact} from "../../models/contact";
import {HideButton} from "./hide.button";
import {t} from "../../lang";
import {Keyboard} from "./keyboard";

interface IProps {
 session: any
 contacts: IContact[]
}

const mapState = (state: AppState) => ({
 contacts: state.ocState.contacts
})

interface IState {
 localHold: boolean
 remoteHold: boolean
 canHold: boolean
 ringing: boolean
}

class Session extends React.Component<IProps, IState> {
 mounted = false;
 localAudio: any;
 remoteAudio: any;
 localClonedStream: any;

 state: IState = {
  localHold: false,
  remoteHold: false,
  canHold: false,
  ringing: false
 }

 componentDidMount() {
  this.mounted = true;
  const session = this.props.session;
  const peerconnection = session.connection;
  const localStream = peerconnection.getLocalStreams()[0];
  const remoteStream = peerconnection.getRemoteStreams()[0];

  // Handle local stream
  if (localStream) {
   // Clone local stream
   this.localClonedStream = localStream.clone();
   // local stream
   this.localAudio.srcObject = this.localClonedStream;
  }

  // If incoming all we already have the remote stream
  if (remoteStream) {
   this.remoteAudio.srcObject = remoteStream;
  }

  if (session.isEstablished()) {
   setTimeout(() => {
    if (!this.mounted)
     return;
    this.setState({canHold: true});
   });
  }

  session.on('progress', (data) => {
   if (!this.mounted)
    return;

   console.debug('session "progress" event [data:%o]', data);

   if (session.direction === 'outgoing')
    this.setState({ringing: true});
  });

  session.on('accepted', (data) => {
   if (!this.mounted)
    return;

   console.debug('session "accepted" event [data:%o]', data);

   // if (session.direction === 'outgoing') {
   //  this.props.onNotify(
   //   {
   //    level: 'success',
   //    title: 'Call answered'
   //   });
   // }

   this.setState({canHold: true, ringing: false});
  });

  session.on('failed', (data) => {
   if (!this.mounted)
    return;

   // this.props.onNotify(
   //  {
   //   level   : 'error',
   //   title   : 'Call failed',
   //   message : `Cause: ${data.cause}`
   //  });

   if (session.direction === 'outgoing')
    this.setState({ringing: false});
  });

  session.on('ended', (data) => {
   if (!this.mounted)
    return;

   console.debug('session "ended" event [data:%o]', data);

   // this.props.onNotify(
   //  {
   //   level: 'info',
   //   title: 'Call ended',
   //   message: `Cause: ${data.cause}`
   //  });

   if (session.direction === 'outgoing')
    this.setState({ringing: false});
  });

  session.on('hold', (data) => {
   if (!this.mounted)
    return;

   const originator = data.originator;

   console.debug('session "hold" event [originator:%s]', originator);

   switch (originator) {
    case 'local':
     this.setState({localHold: true});
     break;
    case 'remote':
     this.setState({remoteHold: true});
     break;
   }
  });

  session.on('unhold', (data) => {
   if (!this.mounted)
    return;

   const originator = data.originator;

   console.debug('session "unhold" event [originator:%s]', originator);

   switch (originator) {
    case 'local':
     this.setState({localHold: false});
     break;
    case 'remote':
     this.setState({remoteHold: false});
     break;
   }
  });

  peerconnection.addEventListener('addstream', (event) => {
   console.debug('peerconnection "addstream" event');

   if (!this.mounted) {
    console.error('_handleRemoteStream() | component not mounted');

    return;
   }

   this.remoteAudio.srcObject = event.stream;
  });
 }

 componentWillUnmount() {
  this.mounted = false;
  JsSIP.Utils.closeMediaStream(this.localClonedStream);
 }

 clickHangUp = () => {
  this.props.session.terminate();


 };

 dtmfCall=(num:string)=>{
  this.props.session.sendDTMF(num);
 };

 render() {
  const state = this.state;
  const session = this.props.session;
  const contact = this.props.contacts.find(f => f.sipNumber === session.remote_identity.uri.user);
  const name = contact? contact.name: getDisplayNameOrUserFromSession(session.remote_identity);
  const uri = session.remote_identity.uri.toString();

  let msg = "";
  if (session.isInProgress() && !state.ringing)
   msg = t("Соединение ...");
  else if (state.ringing)
   msg = t("Звонок ...");
  else if (state.localHold && state.remoteHold)
   msg = "both hold";
  else if (state.localHold)
   msg = "local hold";
  else if (state.remoteHold)
   msg = "remote hold";

  return (
      <div className="CallBox ActiveCall">
       <audio ref={el => this.localAudio = el} autoPlay muted/>
       <audio ref={el => this.remoteAudio = el} autoPlay/>
       <div className="title">{msg || t("Разговор")}</div>
       <div className="name">{name}</div>
       <div className="contact">{uri}</div>
       <div   hidden={Boolean(msg==t("Звонок ...")||msg==t("Соединение ..."))}>
        <Keyboard onNumChange={()=> void undefined} onDTMF={this.dtmfCall.bind(this)}  isToneModeEnabled={true} />
       </div>
       <div className="actions">
        <button className="reject" onClick={() => this.clickHangUp()}><i className={"fas fa-phone"}></i><label>{t("Завершить")}</label></button>
       </div>
       <HideButton />
      </div>
  )
 }

 _handleRemoteStream(stream) {
  // Display remote stream
  this.remoteAudio.srcObject = stream;

  // this._checkRemoteVideo(stream);
  //
  // stream.addEventListener('addtrack', (event) =>
  // {
  //  const track = event.track;
  //
  //  if (remoteVideo.srcObject !== stream)
  //   return;
  //
  //  logger.debug('remote stream "addtrack" event [track:%o]', track);
  //
  //  // Refresh remote video
  //  remoteVideo.srcObject = stream;
  //
  //  this._checkRemoteVideo(stream);
  //
  //  track.addEventListener('ended', () =>
  //  {
  //   logger.debug('remote track "ended" event [track:%o]', track);
  //  });
  // });
  //
  // stream.addEventListener('removetrack', () =>
  // {
  //  if (remoteVideo.srcObject !== stream)
  //   return;
  //
  //  logger.debug('remote stream "removetrack" event');
  //
  //  // Refresh remote video
  //  remoteVideo.srcObject = stream;
  //
  //  this._checkRemoteVideo(stream);
  // });
 }
}


export default connect(mapState)(Session);