import React from "react";
import {IVWell} from "../../models/struct";
import {AppState} from "../../store";
import {ocContactsLoad} from "../../store/oc/actions";
import {connect} from "react-redux";
import PageLoader from "../../components/pageloader";
import OcWellsList from "./wells.list";
import {IKeyMap} from "../../models/common";
import {t} from "../../lang";

interface IProps {
 wells: IVWell[]
 admin: IKeyMap
 isFetching: boolean
 reloadWells: any
}

const mapState = (state: AppState) => ({
 wells: state.ocState.wells,
 admin: state.ocState.admin,
 isFetching: state.ocState.isFetching
});

const mapDispatch = {
 reloadWells: ocContactsLoad
}

const OcWells: React.FC<IProps> = ({wells, admin, isFetching, reloadWells}) => {
 const [search, setSearch] = React.useState<string>("");
 const [filteredWells, setWells] = React.useState<IVWell[]>([]);
 React.useEffect(() => {
  const query = search.toLowerCase().trim();
  const _wells = query
   ? wells.filter(f => (f.path.toLowerCase().indexOf(query) >= 0))
   : wells;
  setWells(_wells);
 }, [wells, search]);

 return (
  (isFetching) ? <PageLoader label={t("Загрузка")}/> :
   <div className={"OcPage"}>
    <div className={"header"}>
     <h1>{t("Список объектов")}</h1>
     {wells.length > 0 && <input type={"search"} placeholder={t("Поиск")} value={search} onChange={(e: any) => setSearch(e.target.value)}/>}
    </div>

    {(wells.length > 0)? <OcWellsList wells={filteredWells} admin={admin}/>: <h2 className={"empty-list"}>{t("Нет ни одного объекта")}</h2>}

    <div className={"bottom-actions"}>
     <button onClick={() => reloadWells()}><i className={"fas fa-sync"}></i> {t("Обновить список")}</button>
    </div>

   </div>
 )
}

export default connect(mapState, mapDispatch)(OcWells);