import React from "react";
import {t} from "../../lang";
import {Keyboard} from "./keyboard";

interface IProps {
 onCall: (num: string) => void
}

interface IState {
 num: string
}

export class DialPad extends React.Component<IProps, IState> {
 state: IState = {
  num: ""
 };

 changeNum =(num)=>{
  this.setState({num: num})
 };

 submitForm = (e: any) => {
  e.preventDefault();
  this.props.onCall(this.state.num);
 }

 render() {
  return (
       <form className="CallForm" onSubmit={this.submitForm}>
        <Keyboard onNumChange={this.changeNum.bind(this)} onDTMF={()=> void undefined}  isToneModeEnabled={false}/>
    <div className="actions">
     <button type="submit" disabled={!Boolean(this.state.num)}><i className={"fas fa-phone"}></i><label>{t("Позвонить")}</label></button>
    </div>
   </form>
  )
 }
}