import React from "react";
import {connect} from "react-redux";
import moment, {Moment} from "moment";
import {IContact} from "../../models/contact";
import {CALL_TYPE_INCOMING, CALL_TYPE_MISSED, CALL_TYPE_MISSED_NEW, CALL_TYPE_OUTGOING, CallType, ICall} from "../../models/call";
import {AppState} from "../../store";
import {phoneMarkAllMissedHistory} from "../../store/phone/actions";
import {t} from "../../lang";

interface IProps {
 history: ICall[]
 contacts: IContact[]
 phoneMarkAllMissedHistory: typeof phoneMarkAllMissedHistory
 onCall: (num: string) => void
}

const mapState = (state: AppState) => ({
 history: state.phoneState.history
})
const mapDispatch = {
 phoneMarkAllMissedHistory
}

interface IHistoryIconProps {
 type: CallType
}

const HistoryIcon = ({type}: IHistoryIconProps) => {
 switch (type) {
  case CALL_TYPE_INCOMING:
   return <div className="history-icon"><i className="icon-incoming" /><label>{t("Входящий вызов")}</label></div>
  case CALL_TYPE_OUTGOING:
   return <div className="history-icon"><i className="icon-outgoing" /><label>{t("Исходящий вызов")}</label></div>
  case CALL_TYPE_MISSED:
   return <div className="history-icon"><i className="icon-missed" /><label>{t("Пропущенный вызов")}</label></div>
  case CALL_TYPE_MISSED_NEW:
   return <div className="history-icon"><i className="icon-missed-new" /><label>{t("Пропущенный вызов")}</label></div>
  default:
   return null;
 }
}

const historyCallClassName = (type: CallType): string => {
 switch (type) {
  case CALL_TYPE_MISSED_NEW:
   return "new-missed-call";
  default:
   return "";
 }
}

class History extends React.Component<IProps> {
 listElement: any;

 componentDidMount() {
  if(this.listElement) {
   this.listElement.scrollTo(0, this.listElement.scrollHeight);
  }
 }

 componentWillUnmount() {
  // this.props.phoneMarkAllMissedHistory();
 }

 devClick = (e: any) => {
  if(e.altKey) {
   this.props.phoneMarkAllMissedHistory();
  }
 }

 render() {
  let prevDate: Moment | undefined = undefined;
  return (
   <div className="HistoryTab" onClick={this.devClick}>
    {(this.props.history.length > 0) ? (
     <ul ref={el => this.listElement = el}>{
      this.props.history.map((h, i) => {
       const d = moment(h.when);
       const showDate = (!prevDate || !d.isSame(prevDate, 'day'));
       prevDate = d;
       const user = this.props.contacts.find(f => f.sipNumber === h.contact);
       return (
        <li key={i}>
         {showDate && <div className="date"><span>{d.format("DD.MM.YYYY")}</span></div>}
         <button className={historyCallClassName(h.type)} onClick={()=>this.props.onCall(h.contact)}>
          <span className={"who"}>
           <HistoryIcon type={h.type}/>
           {user ? <span className="name">{user.name}</span> : <span className="number">{h.contact}</span>}
          </span>
          <span className="when">{d.format("H:mm")}</span>
         </button>
        </li>
       )
      })}
     </ul>
    ) : (<div className="no-history">{t("Данные отсутствуют")}</div>)}
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(History);
