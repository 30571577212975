import React from "react";
import {AppState} from "../../store";
import {connect} from "react-redux";
import TextareaAutosize from "react-autosize-textarea";
import {vcPostMessage} from "../../store/vc/actions";
import {t} from "../../lang";

interface IProps {
 roomId: string
 isPosting: boolean
 sendMessage: any
}

interface IState {
 message: string
}

const mapState = (state: AppState) => ({
 isPosting: state.vcState.isMessagePosting
})
const mapDispatch = {
 sendMessage: vcPostMessage
}

class VcMessageForm extends React.Component<IProps, IState> {
 state = {
  message: ""
 }

 changeMessage = (e: any) => this.setState({message: e.target.value});

 keyDown = (e: any) => {
  if(e.keyCode===13 && (e.ctrlKey || e.altKey)) {
   this.submitHandler();
  }
 }

 submitForm = async (e: any) => {
  e.preventDefault();
  this.submitHandler();
 }

 submitHandler = async () => {
  try {
   await this.props.sendMessage(this.props.roomId, this.state.message);
   this.setState({message:""});
  }
  catch (e) {}
 }

 canSubmit = (): boolean => (Boolean(this.state.message.trim()) && this.state.message.length >= 1);

 render() {
  return (
   <div className="VcMessageForm">
    <form onSubmit={this.submitForm}>
     <TextareaAutosize rows={1} maxRows={3} value={this.state.message} onKeyDown={this.keyDown} onChange={this.changeMessage} disabled={this.props.isPosting} placeholder={t("Сообщение...")}/>
     <button disabled={!this.canSubmit() || this.props.isPosting} type="submit" className={"fa fa-paper-plane"}/>
    </form>
   </div>
  )
 }
}

export default connect(mapState,mapDispatch)(VcMessageForm);