import React from "react";
import {AppState} from "../../store";
import {messengerCreateWellChat} from "../../store/messenger/actions";
import {connect} from "react-redux";
import {ChatCategoryEnum} from "../../models/messenger";
import {CategorySelector} from "./category.selector";
import {IVWell} from "../../models/struct";
import {IKeyMap} from "../../models/common";
import {t} from "../../lang";

interface IProps {
 wells: IVWell[]
 wellId?: string
 adminWells: IKeyMap
 isHandling: boolean
 cancelCreateChat: Function
 chatCreatedHandler: Function
 messengerCreateWellChat: any
}

const mapState = (state: AppState) => {
 const wells: IVWell[] = state.ocState.wells.filter(f => state.ocState.admin[f.id]);
 return {
  wells,
  adminWells: state.ocState.admin,
  isHandling: state.messengerState.isChatHandling
 };
}

const mapDispatch = {
 messengerCreateWellChat
}

interface IState {
 name: string
 category: ChatCategoryEnum
 well: string
}

class MessengerCreateWellChat extends React.Component<IProps, IState> {
 state: IState = {
  name: "",
  category: ChatCategoryEnum.Normal,
  well: ""
 }

 componentDidMount() {
  if(this.props.wellId)
   this.setState({well: this.props.wellId});
  else
   this.setState({well: (this.props.wells && this.props.wells.length > 0)? this.props.wells[0].id: ""});
 }

 changeName = (e: any) => this.setState({name: e.target.value});
 changeCategory = (category: ChatCategoryEnum) => {
  this.setState({category})
 }
 changeWell = (e: any) => {
  this.setState({well: e.target.value});
 }


 submitForm = async (e: any) => {
  e.preventDefault();
  if(this.canSubmit()) {
   try {
    const chatId = await this.props.messengerCreateWellChat(this.state.name, this.state.well, this.state.category);
    this.props.chatCreatedHandler(chatId);
   }
   catch (error) {
   }
  }
 }

 canSubmit = (): Boolean => {
  const str = this.state.name.trim();
  return Boolean(Boolean(str.length > 3) && this.props.adminWells[this.state.well])
 }
 // Boolean(this.state.name.trim());// && Object.keys(this.state.selected).length > 0));

 render() {
  if(!this.props.wells.length)
   return (
    <div className={"ChatFormError"}><i className={"fas fa-exclamation-triangle"}></i> {t("Недостаточно прав")}</div>
   )

  return (
   <div className={"ChatForm"}>
    <form onSubmit={this.submitForm}>
     <div className="name-row">
      <h3>{t("Объект")}</h3>
      <select value={this.state.well} onChange={this.changeWell}>
       {this.props.wells.map(w => (
        <option key={w.id} value={w.id}>{w.path}</option>
       ))}
      </select>
     </div>
     <div className="name-row">
      <h3>{t("Название")}</h3>
      <input value={this.state.name} onChange={this.changeName}/>
     </div>
     <div className="name-row">
      <h3>{t("Категория")}</h3>
      <CategorySelector category={this.state.category} onChange={this.changeCategory}/>
     </div>
     <div className="actions-row">
      <button disabled={!this.canSubmit() || this.props.isHandling} type={"submit"}>{t("Создать")}</button>
      <button type={"button"} onClick={() => this.props.cancelCreateChat()}>{t("Отмена")}</button>
     </div>
    </form>
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(MessengerCreateWellChat);