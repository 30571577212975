import {USER_FETCH, USER_FAIL, USER_OK, UserActionTypes, UserState, USER_LOGOUT, USER_LANG} from "./types";
import {IUserFull} from "../../models/user";

const guestUser: IUserFull = {id: "", name: "", login: "", roles: [], nodeId:""} as IUserFull;

const iniState: UserState = {
 isFetching: false,
 isSigned: false,
 user: guestUser,
 lang: "ru",
 error: undefined
}

export function userReducer(state = iniState, action: UserActionTypes): UserState {
 switch (action.type) {
  case USER_FETCH:
   return {...iniState, isFetching: true};
  case USER_OK:
   return {...state, isFetching: false, isSigned: true, user: action.user};
  case USER_FAIL:
   return {...state, isFetching: false, error: action.error};
  case USER_LOGOUT:
   return {...iniState};

  case USER_LANG:
   return {...state, lang: action.lang};

  default:
   return state;
 }
}
