import {ThunkAction} from "redux-thunk";
import {
 VC_FETCHED, VC_INI, VC_MESSAGE_POST, VC_MESSAGE_POST_FAIL, VC_MESSAGE_POST_OK, VC_MESSAGE_READ, VC_MESSAGES_FETCH, VC_MESSAGES_FETCH_OK,
 VC_ROOM_CAMS_UPDATED,
 VC_ROOM_DESELECT,
 VC_ROOM_HANDLE,
 VC_ROOM_HANDLE_FAIL,
 VC_ROOM_HANDLE_OK,
 VC_ROOM_SELECT, VC_UNREAD_MESSAGES_FETCHED,
 VcActionTypes
} from "./types";
import {AppState} from "../index";
import {errorLog} from "../../utils/console";
import {IVcCam, IVcRoomItem, IVcRoomMessage, VcUserRoleEnum} from "../../models/vc";
import {httpGet, httpPost, httpPut} from "../../utils/http";
import {responseFixVcRoomItem, responseFixVcRoomMessage} from "../../utils/responsefix";

type VcThunkAction = ThunkAction<void, AppState, undefined, VcActionTypes>;

export const vcIni = (): VcThunkAction => async (dispatch) => {
 try {
  dispatch({type: VC_INI});
  const resp: IVcRoomItem[] = await httpGet<IVcRoomItem[]>('/mapi/vc/rooms');
  const rooms: IVcRoomItem[] = resp.map(f => responseFixVcRoomItem(f));
  dispatch({type: VC_FETCHED, rooms});
  const messages = await httpGet<IVcRoomMessage[]>('/mapi/vc/unread-messages');
  dispatch({type: VC_UNREAD_MESSAGES_FETCHED, messages});
 }
 catch (err) {
  errorLog("vc ini", err);
  dispatch({type: VC_FETCHED, rooms: []})
 }
};

export const vcCreateRoom = (name: string, users: string[]): VcThunkAction => async (dispatch) => {
 try {
  dispatch({type: VC_ROOM_HANDLE});
  const resp = await httpPost<IVcRoomItem>('/mapi/vc/create-room', {name, users});
  const room = responseFixVcRoomItem(resp);
  dispatch({type: VC_ROOM_HANDLE_OK, room});
  return room.room.id;
 }
 catch (err) {
  errorLog("vc create room", err);
  dispatch({type: VC_ROOM_HANDLE_FAIL});
 }
};

export const vcCloseRoom = (roomId: string): VcThunkAction => async (dispatch) => {
 try {
  dispatch({type: VC_ROOM_HANDLE});
  const resp = await httpPost<IVcRoomItem>('/mapi/vc/room/' + roomId + '/close', {});
  const room = responseFixVcRoomItem(resp);
  dispatch({type: VC_ROOM_HANDLE_OK, room});
 }
 catch (err) {
  errorLog("vc close room", err);
  dispatch({type: VC_ROOM_HANDLE_FAIL});
 }
}

export const vcAddUser = (room: string, user: string, role: VcUserRoleEnum): VcThunkAction => async (dispatch) => {
 try {
  dispatch({type: VC_ROOM_HANDLE});
  const resp = await httpPost<IVcRoomItem>('/mapi/vc/add-user', {room, user, role});
  const roomItem = responseFixVcRoomItem(resp);
  dispatch({type: VC_ROOM_HANDLE_OK, room: roomItem});
 }
 catch (err) {
  errorLog("vc add user", err);
  dispatch({type: VC_ROOM_HANDLE_FAIL});
 }
}

export const vcUpdateUser = (room: string, user: string, role: VcUserRoleEnum): VcThunkAction => async (dispatch) => {
 try {
  dispatch({type: VC_ROOM_HANDLE});
  const resp = await httpPost<IVcRoomItem>('/mapi/vc/update-user', {room, user, role});
  const roomItem = responseFixVcRoomItem(resp);
  dispatch({type: VC_ROOM_HANDLE_OK, room: roomItem});
 }
 catch (err) {
  errorLog("vc update user", err);
  dispatch({type: VC_ROOM_HANDLE_FAIL});
 }
}

export const vcRemoveUser = (room: string, user: string): VcThunkAction => async (dispatch) => {
 try {
  dispatch({type: VC_ROOM_HANDLE});
  const resp = await httpPost<IVcRoomItem>('/mapi/vc/remove-user', {room, user});
  const roomItem = responseFixVcRoomItem(resp);
  dispatch({type: VC_ROOM_HANDLE_OK, room: roomItem});
 }
 catch (err) {
  errorLog("vc remove user", err);
  dispatch({type: VC_ROOM_HANDLE_FAIL});
 }
}

export const vcLoadRoom = (roomId: string): VcThunkAction => async (dispatch) => {
 interface IResp {
  messages: IVcRoomMessage[]
  cams: IVcCam[]
 }

 try {
  dispatch({type: VC_ROOM_SELECT, roomId});
  dispatch({type: VC_MESSAGES_FETCH});
  // const resp = await httpGet<IResp>('/mapi/vc/room/' + roomId + '/messages');
  const resp = await httpGet<IResp>('/mapi/vc/room/' + roomId);
  const messages: IVcRoomMessage[] = resp.messages
   .map(f => responseFixVcRoomMessage(f))
   .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
  const cams: IVcCam[] = resp.cams.sort((a, b) => (a.name > b.name) ? 1 : -1);
  dispatch({type: VC_MESSAGES_FETCH_OK, messages, cams});
  window.scrollTo(0, document.body.scrollHeight);
 }
 catch (err) {
  errorLog('vc load room', err);
  dispatch({type: VC_ROOM_DESELECT});
 }
}

export const vcUnloadRoom = (): VcActionTypes => ({type: VC_ROOM_DESELECT});

export const vcPostMessage = (room: string, message: string): VcThunkAction => async (dispatch) => {
 try {
  dispatch({type: VC_MESSAGE_POST});
  const resp = await httpPost<IVcRoomMessage>('/mapi/vc/send-message', {room, message});
  const msg = responseFixVcRoomMessage(resp);
  dispatch({type: VC_MESSAGE_POST_OK, msg});
  window.scrollTo(0, document.body.scrollHeight);
 }
 catch (err) {
  errorLog("vc post message", err);
  dispatch({type: VC_MESSAGE_POST_FAIL});
 }
}

export const vcReadMessage = (messageId: string): VcThunkAction => async (dispatch) => {
 interface IResp {
  messageId: string
  roomId: string
 }

 try {
  const resp = await httpPut<IResp>('/mapi/vc/messages/' + messageId + '/read', {});
  dispatch({type: VC_MESSAGE_READ, msgId: resp.messageId, roomId: resp.roomId});
 }
 catch (err) {
  errorLog("vc read message", err);
 }
}

export const vcRoomAddCam = (room: string, cam: string): VcThunkAction => async (dispatch) => {
 try {
  const resp = await httpPost<IVcCam[]>('/mapi/vc/add-camera', {room, cam});
  const cams: IVcCam[] = resp.sort((a, b) => (a.name > b.name) ? 1 : -1);
  dispatch({type: VC_ROOM_CAMS_UPDATED, cams});
 }
 catch (err) {
  errorLog('vc room add cam', err);
 }
}

export const vcRoomRemoveCam = (room: string, cam: string): VcThunkAction => async (dispatch) => {
 try {
  const resp = await httpPost<IVcCam[]>('/mapi/vc/remove-camera', {room, cam});
  const cams: IVcCam[] = resp.sort((a, b) => (a.name > b.name) ? 1 : -1);
  dispatch({type: VC_ROOM_CAMS_UPDATED, cams});
 }
 catch (err) {
  errorLog('vc room remove cam', err);
 }
}

export const vcRoomGetFreeCams = async (room: string): Promise<IVcCam[]> => {
 return await httpGet<IVcCam[]>('/mapi/vc/room/' + room + '/free-cams');
}

export const vcRoomGetCams = async (room: string): Promise<IVcCam[]> => {
 return await httpGet<IVcCam[]>('/mapi/vc/room/' + room + '/cams');
}

export const vcRoomGetFreeCamDevices = async (room: string): Promise<string[]> => {
 return await httpGet<string[]>('/mapi/vc/room/' + room + '/free-cam-devices');
}
