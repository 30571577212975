import React from "react";
import {phoneActiveToggle} from "./helper";
import {t} from "../../lang";

const hideBoxClick = (e: any) => {
 e.preventDefault();
 phoneActiveToggle();
}

// fa-arrow-left
// fa-compress
export const HideButton = () => (
 <button type="button" className="hide-button" onClick={hideBoxClick}><i className="fas fa-arrow-left"></i><label>{t("Скрыть")}</label></button>
);