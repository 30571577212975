import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

import {userReducer} from "./user/reducers";
import {phoneReducer} from "./phone/reducers";
import {configReducer} from "./config/reducers";
import {liveReducer} from "./live/reducers";
import {messengerReducer} from "./messenger/reducers";
import {vcReducer} from "./vc/reducers";
import {ocReducer} from "./oc/reducers";


const rootReducer = combineReducers({
 configState: configReducer,
 userState: userReducer,
 ocState: ocReducer,
 phoneState: phoneReducer,
 liveState: liveReducer,
 messengerState: messengerReducer,
 vcState: vcReducer,

});

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
 const middlewares = [thunk];
 const middlewareEnhancer = applyMiddleware(...middlewares);

 const store = createStore(
  rootReducer,
  composeWithDevTools(middlewareEnhancer)
 )

 return store;
}