import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {messengerMsgPost, messengerMsgPostFiles} from "../../store/messenger/actions";
import {IKeyStringMap} from "../../models/common";
import {userMentionText} from "../../utils/mention";
import {t} from "../../lang";

interface IProps {
 chatId: string
 isPosting: boolean
 sendMessage: any
 sendFiles: any
 mentioned: IKeyStringMap
 userMention: (userId: string) => void
 userMentionByLogin: (userLogin: string) => void
 clearUserMentions: () => void
}

const mapState = (state: AppState) => ({
 isPosting: state.messengerState.isPosting
})

const mapDispatch = {
 sendMessage: messengerMsgPost,
 sendFiles: messengerMsgPostFiles
}

interface IState {
 message: string
}

class MessengerForm extends React.Component<IProps, IState> {
 state: IState = {
  message: ""
 }
 filesInput: any = null;

 componentDidMount() {

 }

 componentDidUpdate() {
 }

 changeMessage = (e: any) => {
  const um = userMentionText(e.target.value);
  if(um.mentions && um.mentions.length > 0)
   for(let i = 0; i< um.mentions.length; i++)
    this.props.userMentionByLogin(um.mentions[i]);
  this.setState({message: um.text});
  // let msg = e.target.value;
  // const match = msg.match(userMentionRegex);
  // if(match && match.length > 0) {
  //  for(let i = 0; i < match.length; i++) {
  //   msg = msg.replace(match[i], "");
  //   const login = match[i].substring(1);
  //   this.props.userMentionByLogin(login.substring(0, login.length-1));
  //  }
  // }
  // this.setState({message: msg});
 }

 keyDown = (e: any) => {
  if (e.keyCode === 13 && (e.ctrlKey || e.altKey)) {
   this.submitHandler();
  }
 }

 submitForm = async (e: any) => {
  e.preventDefault();
  this.submitHandler();
 }

 submitHandler = async () => {
  try {
   const msg: string = Object.keys(this.props.mentioned).reduce((p, c) => {
    return p + `@${this.props.mentioned[c]}, `
   }, "") + this.state.message;
   await this.props.sendMessage(this.props.chatId, msg);
   this.props.clearUserMentions();
   this.setState({message: ""});
  }
  catch (e) {
  }
 }

 canSubmit = (): boolean => (Boolean(this.state.message.trim()) && this.state.message.length >= 1);

 filesSubmitClick = () => {
  this.filesInput.click();
 }

 filesChange = async (e: any) => {
  const files = e.target.files;
  if (files && files.length > 0) {
   await this.props.sendFiles(this.props.chatId, files);
  }
 }

 render() {
  const mentionedIds = Object.keys(this.props.mentioned);

  return (
   <div className="ChatMsgForm">
    <div className={"files-input-box"}><input ref={el => this.filesInput = el} type={"file"} multiple={true} name={"files"}
                                              onChange={this.filesChange}/></div>
    <form onSubmit={this.submitForm}>
     <button disabled={this.props.isPosting} onClick={this.filesSubmitClick} type={"button"} className={"fa fa-paperclip button-send-files"}/>
     <div>
      {(mentionedIds.length > 0) && <ul className={"mentioned"}>
       {mentionedIds.map(id => <li key={id}><label onClick={()=>this.props.userMention(id)}>@{this.props.mentioned[id]}<i className={"fas fa-times"}></i></label></li>)}
      </ul>}
      <TextareaAutosize rows={1} maxRows={3} value={this.state.message} onKeyDown={this.keyDown} onChange={this.changeMessage}
                        disabled={this.props.isPosting} placeholder={t("Сообщение...")}/>
     </div>
     <button disabled={!this.canSubmit() || this.props.isPosting} type="submit" className={"button-send-msg fa fa-paper-plane"}/>
    </form>
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(MessengerForm)