// enums

export enum ChatTypeEnum {
 Personal = 1,
 Room = 2,
 Group = 3,
 Well = 4
}

export enum ChatUserRoleEnum {
 Admin = 1,
 User = 2,
 Blocked = 3,
}

export enum ChatMessageTypeEnum {
 Text = 1,
 Files = 2
}

export enum ChatMessageStatusEnum {
 Read = 0,
 Unread = 1,
}

export enum ChatSettingsNotifyEnum {
 Normal = 0,
 Silent = 1,
}

export enum ChatCategoryEnum {
 Default = 0,
 Normal = 1, // обычный режим
 Lag = 4, // оставание
 Deffect = 16, // недочёты
 Accident = 64, // авария
}

// helper interfaces

export interface IUserRoleMap {
 [key: string]: ChatUserRoleEnum
}

// model interfaces

export interface IChat {
 id: string
 ownerId: string
 createdAt: Date
 closedAt?: Date
 name: string
 type: ChatTypeEnum
 category?: ChatCategoryEnum
}

export interface IChatExt extends IChat {
 notify: ChatSettingsNotifyEnum;
 targetId: string
}

export interface IChatUserRole {
 userId: string;
 userName?: string;
 role: ChatUserRoleEnum;
}

export interface IChatItem {
 chat: IChatExt;
 users: IChatUserRole[];
 notify: ChatSettingsNotifyEnum;
 targetId: string;
}

export interface IChatMessage {
 id: string
 chatId: string
 ownerId: string
 createdAt: Date
 type: ChatMessageTypeEnum
 body: string
 ownerName?: string
 status?: ChatMessageStatusEnum;
}

export interface IChatLastUpdateMap {
 [key: string]: Date
}

export interface IChatBodyFile {
 file: string
 size: number
}
