export const phoneStorageActive = (): boolean => {
 return (window.localStorage && window.localStorage["phone-active"]==="active");
}

export const phoneActivate = () => {
 document.body.classList.add("phone-active");
 if(window.localStorage)
  window.localStorage["phone-active"] = "active";
}

export const phoneActiveToggle = () => {
 document.body.classList.toggle("phone-active");
 if(window.localStorage) {
  window.localStorage["phone-active"] = document.body.classList.contains("phone-active")? "active": "";
 }
}

export const getDisplayNameOrUserFromSession = (identity: any) => {
 return identity.display_name? identity.display_name: identity.uri.user;
}

export const phoneCall = (contact: string) => {

}