import React from "react"
import {AppState} from "../../store";
import {connect} from "react-redux";
import {IContact, IOnlineUsersMap} from "../../models/contact";
import PageLoader from "../../components/pageloader";
import {NavLink} from "react-router-dom";
import {t} from "../../lang";

interface IProps {
 myId: string
 contacts: IContact[]
 online: IOnlineUsersMap
 isFetching: boolean
}

const mapState = (state: AppState) => ({
 myId: state.userState.user.id,
 contacts: state.ocState.contacts, //.filter(f => f.id !== state.userState.user.id).sort((a, b) => (a.name > b.name? 1: -1)),
 online: state.ocState.online,
 isFetching: state.ocState.isFetching
})

const SidebarContacts: React.FC<IProps> = ({myId, contacts, online, isFetching}: IProps) => {
 React.useEffect(() => {
  // console.log('sidebar contacts', contacts);
 }, []);

 if (isFetching)
  return <PageLoader label={t("Загрузка")}/>

 return (
  <div className="list">
   {contacts.length > 0 ? (
    <ul>
     {contacts.map(c => {
      // console.log('user', c, online[c.id]);
      return <li key={c.id}>
       <NavLink to={"/app/chat/u/" + c.login}>
        <span className={"chat-user"}>
         {c.name}
         {c.isMyNode && <label className={"OnlineStatus" + (online[c.id] ? " online" : " offline")}>{online[c.id] ? "online" : "offline"}</label>}
        </span>
        {c.post && <span className={"chat-user-post"}>{c.post}</span>}
       </NavLink>
      </li>
     })}
    </ul>
   ) : (<div className={"no-chats"}>{t("Пользователи не загружены")}</div>)}
  </div>
 )
}

export default connect(mapState)(SidebarContacts);
