import React from "react";
import {Route, Switch} from "react-router-dom";
import {Redirect} from "react-router";

import MapsRealtime from "../maps/realtime"
import MapsHistory from "../maps/history";
import Messenger from "../messenger";
import Vc from "../vc";
import VcRoom from "../vc/room";
import AppLoader from "../../components/apploader";
import OcUsers from "../oc/users";
import OcWells from "../oc/wells";
import OcWellUsers from "../oc/well.users";
import Monitor from "../monitoring";
import MonitorHistory from "../monitoring/history";
import Files from "../files";
import Ecm from "../ecm"
import MonitorSettings from "../monitoring/settings";
import ViewMonitorRule from "../monitoring/viewrule";
import EditMonitorRule from "../monitoring/editrule";import {IUserFull, UserRoleEnum} from "../../models/user";
import {AppState} from "../../store";
import {connect} from "react-redux";

interface IProps {
 user: IUserFull
}
const mapState = (state: AppState) => ({
 user: state.userState.user
});


const Adm = React.lazy(() => import('../adm'));
const me = this;
console.log("routes");
console.log(me);
// const Home1 = () => <Redirect to={"/app/chat"}/>;
const Maps = () => <Redirect to={"/app/maps/realtime"}/>;
const monitor = () => <Redirect to={"/app/monitor/realtime"}/>;
const Home: React.FC = () => {
 return <Redirect to={"/app/chat"}/>
}


const Routes: React.FC<IProps> = ({user}) => (

 <React.Suspense fallback={<AppLoader label={"Загрузка компонентов"}/>}>
  {((user.roles.indexOf(UserRoleEnum.User) >= 0) || (user.roles.indexOf(UserRoleEnum.Admin) >= 0)) &&(

  <Switch>
   <Route exact path={"/"} component={Home}/>
   <Route exact path={"/app/edc"} component={Ecm}/>

   <Route exact path={"/app/chat"} component={Messenger}/>
   <Route exact path={"/app/chat/:mode(c|u|cw)/:id"} component={Messenger}/>

   <Route exact path={"/app/vc"} component={Vc}/>
   <Route exact path={"/app/vc/:id"} component={VcRoom}/>

   <Route exact path={"/app/contacts"} component={OcUsers}/>
   <Route exact path={"/app/wells"} component={OcWells}/>
   <Route exact path={"/app/wells/:wellId/contacts"} component={OcWellUsers} />

   <Route exact path={"/app/maps"} component={Maps}/>
   <Route exact path={"/app/maps/realtime"} component={MapsRealtime}/>
   <Route exact path={"/app/maps/realtime/:user"} component={MapsRealtime}/>
   <Route exact path={"/app/maps/history"} component={MapsHistory}/>
   <Route exact path={"/app/maps/history/:user"} component={MapsHistory}/>
   <Route exact path={"/app/maps/history/:user/:date"} component={MapsHistory}/>
   <Route exact path={"/app/files"} component={Files}/>
   <Route path={"/app/adm"} component={Adm}/>
   <Route exact path={"/app/monitor/realtime"} component={Monitor}/>
   <Route exact path={"/app/monitor/history"} component={MonitorHistory}/>
   <Route exact path={"/app/monitor/settings"} component={MonitorSettings}/>
   <Route exact path={"/app/monitor/settings/view/:id"} component={ViewMonitorRule}/>
   <Route exact path={"/app/monitor/settings/edit/:id"} component={EditMonitorRule}/>
   <Route exact path={"/app/monitor"} component={monitor}/>

  </Switch>)}
  {(!(user.roles.indexOf(UserRoleEnum.User) >= 0) && !(user.roles.indexOf(UserRoleEnum.Admin) >= 0)) && (

      <Switch>
       <Route exact path={"/"} component={Home}/>
       <Route exact path={"/app/chat"} component={Messenger}/>
       <Route exact path={"/app/chat/:mode(c|u|cw)/:id"} component={Messenger}/>
       <Route exact path={"/app/contacts"} component={OcUsers}/>
      </Switch>)}
 </React.Suspense>
)

export default connect(mapState) (Routes)
