import React from "react";
import {connect} from "react-redux";
import {IContact, IOnlineUsersMap} from "../../models/contact";
import {AppState} from "../../store";
import {messengerCreatePersonalChat} from "../../store/messenger/actions";
import {errorLog} from "../../utils/console";
import {ChatTypeEnum} from "../../models/messenger";
import {Link} from "react-router-dom";
import {t} from "../../lang";

interface IUserChatMap {
 [key: string]: string;
}

interface IProps {
 contacts: IContact[]
 online: IOnlineUsersMap
 isHandling: boolean
 personMap: IUserChatMap
 cancelCreateChat: Function
 chatCreatedHandler: Function
 messengerCreatePersonalChat: any
}

const mapState = (state: AppState) => ({
 personMap: state.messengerState.chats.reduce((p, c) => {
  if (c.type === ChatTypeEnum.Personal && c.targetId)
   p[c.targetId] = c.id;
  return p;
 }, {} as IUserChatMap),
 online: state.ocState.online,
 isHandling: state.messengerState.isChatHandling
});

const mapDispatch = {
 messengerCreatePersonalChat
}

interface IState {
 search: string;
}

class MessengerCreatePersonChat extends React.Component<IProps, IState> {
 state: IState = {
  search: ""
 }

 changeSearch = (e: any) => this.setState({search: e.target.value});

 createChat = async (userId: string) => {
  try {
   const chatId = await this.props.messengerCreatePersonalChat(userId);
   this.props.chatCreatedHandler(chatId);
  }
  catch (err) {
   errorLog("create chat", err);
  }
 }

 render() {
  const online = this.props.online;
  const search = this.state.search.toLowerCase().trim();
  const contacts = search ? this.props.contacts.filter(f => f.name.toLowerCase().indexOf(search) >= 0) : this.props.contacts;

  return (
   <div className={"ChatForm"}>
    <div className="users-row">
     <div className="title">
      <h3>{t("Участники")}</h3>
      <input type="search" placeholder={t("Поиск")} value={this.state.search} onChange={this.changeSearch}/>
     </div>

     {contacts.length > 0 ? (<ul>
      {contacts.map(c => (
       <li key={c.id}>
        <label>
         {c.name}
         {c.isMyNode && <span className={"OnlineStatus" + (online[c.id] ? " online" : " offline")}>{online[c.id] ? "online" : "offline"}</span>}
        </label>

        {
         Boolean(this.props.personMap[c.id])
          ? <Link className={"chat-link"} to={`/app/chat/c/${this.props.personMap[c.id]}`}>{t("Чат существует")} <i className={"fas fa-share"}></i></Link>
          : <button className={"chat-button"} onClick={() => this.createChat(c.id)}>{t("Начать беседу")}</button>
        }

       </li>
      ))}
     </ul>) : (<div className="no-users">{t("Пользователи не найдены")}</div>)}
    </div>
    <div className="actions-row">
     <button type={"button"} onClick={() => this.props.cancelCreateChat()}>{t("Отмена")}</button>
    </div>
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(MessengerCreatePersonChat);
