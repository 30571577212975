import {useEffect, useCallback} from "react";

export const useOutsideClick = (ref: any, callback: any) => {
 const handleClick = useCallback((e: any) => {
  if (ref.current && !ref.current.contains(e.target))
   callback();
 }, [ref, callback]);

 useEffect(() => {
  document.addEventListener("click", handleClick);
  return () => {
   document.removeEventListener("click", handleClick);
  }
 }, [handleClick]);
};