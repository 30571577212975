import {ThunkAction} from "redux-thunk";
import {AppState} from "../index";
import {errorLog} from "../../utils/console";
import {
 MESSENGER_CHAT_DESELECT,
 MESSENGER_CHAT_HANDLE,
 MESSENGER_CHAT_HANDLE_FAIL,
 MESSENGER_CHAT_HANDLE_OK,
 MESSENGER_CHAT_SELECT,
 MESSENGER_CHAT_SELECT_FAIL,
 MESSENGER_CHAT_SELECT_OK,
 MESSENGER_FETCHED,
 MESSENGER_INI,
 MESSENGER_MSG_POST,
 MESSENGER_MSG_POST_FAIL,
 MESSENGER_MSG_POST_OK,
 MESSENGER_MSG_READ,
 MESSENGER_UNREADS_RECEIVED,
 MessengerActionTypes
} from "./types";
import {httpGet, httpPost, httpPostRawBody, httpPut} from "../../utils/http";
import {ChatCategoryEnum, ChatSettingsNotifyEnum, IChatExt, IChatItem, IChatLastUpdateMap, IChatMessage, IUserRoleMap} from "../../models/messenger";
import {responseFixChatMessage, responseFixChatItem, responseFixChat} from "../../utils/responsefix";

type MessengerThunkAction = ThunkAction<void, AppState, undefined, MessengerActionTypes>;

export const messengerLoad = (): MessengerThunkAction => async (dispatch) => {
 interface IResponse {
  chats: IChatExt[]
  updates: IChatLastUpdateMap
 }

 try {
  dispatch({type: MESSENGER_INI});
  const response = await httpGet<IResponse>('/mapi/chats/');
  const chats: IChatExt[] = response.chats.map(c => responseFixChat(c));
  const updates: IChatLastUpdateMap = response.updates || {};
  Object.keys(updates).forEach(k => {
   updates[k] = new Date(updates[k]);
  })
  dispatch({type: MESSENGER_FETCHED, chats, updates});
  const messages = await httpGet<IChatMessage[]>('/mapi/chats/unread-messages');
  dispatch({type: MESSENGER_UNREADS_RECEIVED, messages});
 }
 catch (err) {
  errorLog('messenger load', err);
  dispatch({type: MESSENGER_FETCHED, chats: [], updates: {}})
 }
}

export const messengerCreateChat = (name: string, users: string[]): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_CHAT_HANDLE});
  const chatItemResponse = await httpPost<IChatItem>('/mapi/chats/create-room-chat', {name, users});
  const chatItem = responseFixChatItem(chatItemResponse);
  // handleChatItem(chatItem);
  dispatch({type: MESSENGER_CHAT_HANDLE_OK, chatItem});
  return chatItem.chat.id;
 }
 catch (err) {
  errorLog('messenger create chat', err);
  dispatch({type: MESSENGER_CHAT_HANDLE_FAIL});
 }
}

export const messengerCreateWellChat = (name: string, well: string, category: ChatCategoryEnum): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_CHAT_HANDLE});
  const chatItemResponse = await httpPost<IChatItem>('/mapi/chats/create-well-chat', {name, well, category});
  const chatItem = responseFixChatItem(chatItemResponse);
  dispatch({type: MESSENGER_CHAT_HANDLE_OK, chatItem});
  return chatItem.chat.id;
 }
 catch (err) {
  errorLog("messenger create well chat", err);
  dispatch({type: MESSENGER_CHAT_HANDLE_FAIL});
 }
}

export const messengerCreatePersonalChat = (user: string): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_CHAT_HANDLE});
  const chatItemResponse = await httpPost<IChatItem>('/mapi/chats/create-person-chat', {user});
  const chatItem = responseFixChatItem(chatItemResponse);
  // handleChatItem(chatItem);
  dispatch({type: MESSENGER_CHAT_HANDLE_OK, chatItem});
  return chatItem.chat.id;
 }
 catch (err) {
  errorLog('messenger create personal chat', err);
  dispatch({type: MESSENGER_CHAT_HANDLE_FAIL});
 }
}

export const messengerLoadChat = (chat: string): MessengerThunkAction => async dispatch => {
 interface IMessagesResponse {
  messages: IChatMessage[]
 }

 try {
  dispatch({type: MESSENGER_CHAT_SELECT, selectedChat: chat});
  const [chatRes, msgRes] = await Promise.all([
   httpGet<IChatItem>('/mapi/chats/chat/' + chat),
   httpGet<IMessagesResponse>('/mapi/chats/chat/' + chat + '/messages'),
  ]);
  // const res = await httpGet<IMessagesResponse>('/mapi/chats/chat/' + chat + '/messages');
  const users = chatRes.users;
  const messages = msgRes.messages.map(f => responseFixChatMessage(f)).sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
  dispatch({type: MESSENGER_CHAT_SELECT_OK, users, messages});
  window.scrollTo(0, document.body.scrollHeight);
 }
 catch (err) {
  errorLog('messenger load chat', err);
  dispatch({type: MESSENGER_CHAT_SELECT_FAIL});
 }
}

export const messengerUnloadChat = (): MessengerActionTypes => ({type: MESSENGER_CHAT_DESELECT});

export const messengerModifyChat = (chatId: string, name: string, users: IUserRoleMap): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_CHAT_HANDLE});
  const chatItemResponse = await httpPut<IChatItem>('/mapi/chats/chat/' + chatId, {name, users});
  // handleChatItem(chatItem);
  const chatItem = responseFixChatItem(chatItemResponse);
  dispatch({type: MESSENGER_CHAT_HANDLE_OK, chatItem});
 }
 catch (err) {
  errorLog('messenger update chat', err);
  dispatch({type: MESSENGER_CHAT_HANDLE_FAIL});
 }
}

export const messengerModifyChatWell = (chatId: string, name: string, category: ChatCategoryEnum): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_CHAT_HANDLE});
  const chatItemResponse = await httpPut<IChatItem>('/mapi/chats/chat/' + chatId + '/well', {name, category});
  const chatItem = responseFixChatItem(chatItemResponse);
  dispatch({type: MESSENGER_CHAT_HANDLE_OK, chatItem});
 }
 catch (err) {
  errorLog("messenger update chat well", err);
  dispatch({type: MESSENGER_CHAT_HANDLE_FAIL});
 }
}

export const messengerChatSettings = (chat: string, notify: ChatSettingsNotifyEnum): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_CHAT_HANDLE});
  const chatItemResponse = await httpPost<IChatItem>('/mapi/chats/chat/settings', {chat, notify});
  const chatItem = responseFixChatItem(chatItemResponse);
  dispatch({type: MESSENGER_CHAT_HANDLE_OK, chatItem});
 }
 catch (err) {
  errorLog("messenger chat settings", err);
  dispatch({type: MESSENGER_CHAT_HANDLE_FAIL});
 }
}

export const messengerMsgPost = (chat: string, message: string): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_MSG_POST});
  const msgResponse = await httpPost<IChatMessage>('/mapi/chats/message', {chat, message});
  const msg = responseFixChatMessage(msgResponse);
  dispatch({type: MESSENGER_MSG_POST_OK, msg});
  window.scrollTo(0, document.body.scrollHeight);
 }
 catch (err) {
  errorLog('messenger msg post', err);
  dispatch({type: MESSENGER_MSG_POST_FAIL});
 }
}

export const messengerMsgPostFiles = (chat: string, files: FileList): MessengerThunkAction => async (dispatch) => {
 try {
  dispatch({type: MESSENGER_MSG_POST});
  const formData = new FormData();
  for (let i = 0; i < files.length; i++)
   formData.append("files", files[i]);
  formData.append("chat", chat);
  const msgResponse = await httpPostRawBody<IChatMessage>('/mapi/chats/files', formData);
  const msg = responseFixChatMessage(msgResponse);
  dispatch({type: MESSENGER_MSG_POST_OK, msg});
  window.scrollTo(0, document.body.scrollHeight);
 }
 catch (err) {
  errorLog('messenger msg post files', err);
  dispatch({type: MESSENGER_MSG_POST_FAIL});
 }
}

export const messengerMsgRead = (messageId: string): MessengerThunkAction => async (dispatch) => {
 interface IResponse {
  messageId: string
  chatId: string
 }

 try {
  const res = await httpPost<IResponse>('/mapi/chats/message/' + messageId + '/read', {});
  dispatch({type: MESSENGER_MSG_READ, msgId: res.messageId, chatId: res.chatId});
 }
 catch (err) {
  errorLog("messenger msg read", err);
 }
}
