import React from "react";
import {ChatCategoryEnum, IChat} from "../../models/messenger";
import {IVWell} from "../../models/struct";
import {CategorySelector} from "./category.selector";
import {t} from "../../lang";

interface IProps {
 chat: IChat
 well?: IVWell
 isHandling: boolean
 cancelModifyChat: Function
 messengerModifyChat: any
}

interface IState {
 name: string
 category: ChatCategoryEnum
}

export class MessengerModifyWellChat extends React.Component<IProps, IState> {
 state: IState = {
  name: "",
  category: ChatCategoryEnum.Default,
 }

 componentDidMount() {
  const name = this.props.chat.name;
  const category = this.props.chat.category? this.props.chat.category : ChatCategoryEnum.Default;
  this.setState({name, category});
 }

 changeName = (e: any) => this.setState({name: e.target.value});
 changeCategory = (category: ChatCategoryEnum) => {
  this.setState({category})
 }


 submitForm = async (e: any) => {
  e.preventDefault();
  if(this.canSubmit()) {
   try {
    await this.props.messengerModifyChat(this.props.chat.id, this.state.name, this.state.category);
    this.props.cancelModifyChat();
   }
   catch (error) {
   }
  }
 }

 canSubmit = (): Boolean => Boolean(this.state.name.trim());// && Object.keys(this.state.selected).length > 0));

 render() {
  return (
   <div className="MessengerBody">
    <div className={"header"}>
     <h2>{this.props.chat.name}</h2>
    </div>
    <div className={"body"}>
     <div className={"ChatForm"}>
      <form onSubmit={this.submitForm}>
       {this.props.well && (
        <div className={"name-row"}>
         <h3>{t("Объект")}</h3>
         <label>{this.props.well.path}</label>
        </div>
       )}
       <div className="name-row">
        <h3>{t("Название")}</h3>
        <input value={this.state.name} onChange={this.changeName}/>
       </div>
       <div className="name-row">
        <h3>{t("Категория чата")}</h3>
        <CategorySelector category={this.state.category} onChange={this.changeCategory}/>
       </div>
       <div className="actions-row">
        <button disabled={!this.canSubmit() || this.props.isHandling} type={"submit"}>{t("Сохранить")}</button>
        <button type={"button"} onClick={() => this.props.cancelModifyChat()}>{t("Отмена")}</button>
       </div>
      </form>
     </div>
    </div>
   </div>
  )
 }
}