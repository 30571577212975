import {LatLng} from "leaflet";
import {IContact} from "../../models/contact";

export interface IMapPoint {
 deviceId: string
 date: Date
 position: LatLng
 // position: LatLngExpression
}

export interface IUserMapPoint extends IMapPoint {
 person: IContact
 dangerZone: boolean
}


export interface ITime {
 hour: number,
 minute: number
}

type ICoords = number[];

export interface IMapHistoryResult extends ITime {
 // coordinates: string // todo andrew marks
 coordinates: ICoords[]
}

export interface IMapHistory {
 tick: number
 coordinates: LatLng[]
}

export const timeToTick = (t: ITime): number => 60 * t.hour + t.minute;

export const tickToTime = (t: number): ITime => {
 const minute = t % 60;
 const hour = (t - minute) / 60;
 return {hour, minute}
};

export const tickTimeString = (tick: number): string => {
 const t = tickToTime(tick);
 return t.hour + ":" + ((t.minute < 10) ? "0" : "") + t.minute;
};

