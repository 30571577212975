import React from "react";
import {ChatMessageStatusEnum, IChatMessage} from "../../models/messenger";
import {messengerMsgRead} from "../../store/messenger/actions";
import {connect} from "react-redux";
import {d} from "../../utils/date";
import {MessageBody} from "./message.body";
import {AppState} from "../../store";

interface IProps {
 msg: IChatMessage
 isMy: boolean
 isUserOnline?: boolean
 showDate: boolean
 messengerMsgRead: any
 myLogin: string
}

const mapState = (state: AppState) => ({
 myLogin: state.userState.user.login
})

const mapDispatch = {
 messengerMsgRead
}

const Message = ({msg, isMy, myLogin, isUserOnline, showDate, messengerMsgRead}: IProps) => {
 const isUnread = (msg.status===ChatMessageStatusEnum.Unread);
 const className = isMy? "ChatMsg from_me": (isUnread? "ChatMsg to_me unread": "ChatMsg to_me");
 return (
  <div className={className}>
   {showDate && <div className={"date"}><label>{d(msg.createdAt, "md")}</label></div>}
   <div className={"msg"} onClick={() => (isUnread? messengerMsgRead(msg.id): () => {})}>
    {!isMy && (
     <div className={msg.ownerName? "user": "no-user"}>
      {msg.ownerName? msg.ownerName: msg.ownerId}
      {isUserOnline && <span className={"OnlineDot"}></span>}
     </div>)}
     <MessageBody msg={msg} myLogin={myLogin} />
    {/*msg.body.split("\n").map((txt,i) => <span key={i}>{txt}<br/></span>)*/}
    <span className={"time"}>{d(msg.createdAt, "t")}</span>
   </div>
  </div>
 );
}

export default connect(mapState,mapDispatch)(Message);
