import {LatLng, LatLngBoundsExpression} from "leaflet";

export const image = process.env.PUBLIC_URL + "/assets/map.png";
// export const bounds: LatLngBoundsExpression = [[6781415, 7677116], [6782984, 7677275]];
// export const center: LatLng = new LatLng(6782199, 7677195);
export const bounds: LatLngBoundsExpression = [[7677116, 6781415], [7677275, 6782984]];
export const center: LatLng = new LatLng(7677195, 6782199);
export const circleRadius = 20;
export const minZoom = -4;
export const defaultZoom = 0;

// export const image = process.env.PUBLIC_URL + "/assets/kartaDvora.png";
// export const bounds: LatLngBoundsExpression = [[5684665, 3588770], [5684756, 3589037]];
// export const center: LatLng = new LatLng(5684710, 3588903);
// export const circleRadius = 20;

export const pointInDangerZone = (point: LatLng): boolean => {
 const circ = Math.pow(center.lat - point.lat, 2) + Math.pow(center.lng - point.lng, 2);
 return (circ <= circleRadius*circleRadius);
}
