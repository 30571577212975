import React from "react";
import {t} from "../../lang";
import {connect} from "react-redux";
import Routes from "../app/routes";
import {AppState} from "../../store";
import {userLoadProfile, userLogin} from "../../store/user/actions";

class IProps {
}

class IState {
}

class Ecm extends React.Component<IProps, IState> {



    render() {
        return (
            <div >

            </div>
        );

    }
}
const mapState = (state: AppState) => ({

})

const mapDispatch = {

}
export default connect(mapState, mapDispatch)(Ecm);
