import React from "react";
import {connect} from "react-redux";
import {IVcRoomMessage, VcMessageStatusEnum} from "../../models/vc";
import {d} from "../../utils/date";
import {vcReadMessage} from "../../store/vc/actions";

interface IProps {
 msg: IVcRoomMessage
 isMy: boolean
 showDate: boolean
 readMessage: any
}

const mapDispatch = {
 readMessage: vcReadMessage
}

const VcMessage: React.FC<IProps> = ({msg, isMy, showDate, readMessage}:IProps) => {
 const isUnread = (msg.status===VcMessageStatusEnum.Unread);
 const className = isMy? "VcMessage from_me": (isUnread? "VcMessage to_me unread": "VcMessage to_me");
 return (
  <div className={className}>
   {showDate && <div className={"date"}><label>{d(msg.createdAt, "md")}</label></div>}
   <div className={"msg"} onClick={() => (isUnread? readMessage(msg.id): () => {})}>
    {!isMy && (<div className={msg.ownerName? "user": "no-user"}>{msg.ownerName? msg.ownerName: msg.ownerId}</div>)}
    {msg.body.split("\n").map((txt,i) => <span key={i}>{txt}<br/></span>)}
    <span className={"time"}>{d(msg.createdAt, "t")}</span>
   </div>
  </div>
 )
}

export default connect(null, mapDispatch)(VcMessage);