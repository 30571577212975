export interface IUrlQueryMap {
 [key: string]: string
}

export const getQueryParams = (search: string): IUrlQueryMap => {
 if(!search)
  return {};

 const ioq = search.indexOf('?');
 const hashes = (ioq >= 0)? search.slice(ioq+1).split('&'): search.split('&');
 return hashes.reduce((acc, hash) => {
  const [key, val] = hash.split(`=`);
  const ret = {...acc};
  ret[key] = decodeURIComponent(val);
  return ret;
 }, {});
}