import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";

import Sidebar from "./sidebar";
import MessengerChat from "./chat";
import MessengerIntro from "./intro";
import MessengerCreatePage from "./create";
import {IContact} from "../../models/contact";
import {AppState} from "../../store";
import PageLoader from "../../components/pageloader";
import {IChat} from "../../models/messenger";
import MessengerPersonalWrapper from "./personal";
import {messengerLoad} from "../../store/messenger/actions";
import {t} from "../../lang";

interface IRoute {
 id: string
 mode: string
}

// interface PersonalChatUserMap {
//  [key: string]: string
// }

interface IProps extends RouteComponentProps<IRoute> {
 contacts: IContact[]
 isFetching: boolean
 chats: IChat[]
 messengerLoad: any
 // personalMap: PersonalChatUserMap
}

const mapState = (state: AppState) => ({
 contacts: state.ocState.contacts.filter(f => f.id !== state.userState.user.id).sort((a, b) => ((a.name > b.name) ? 1 : -1)),
 isFetching: state.messengerState.isFetching,
 chats: state.messengerState.chats,
});

const mapDispatch = {
 messengerLoad
}

interface IState {
 chatCreation: boolean
}

class Messenger extends React.Component<IProps, IState> {
 state: IState = {
  chatCreation: false // todo remove after debug
 }

 showChatCreateForm = () => {
  this.setState({chatCreation: true});
 }

 cancelChatCreate = () => {
  this.setState({chatCreation: false});
 }

 chatCreatedHandler = (chatId: string) => {
  this.props.history.push("/app/chat/c/" + chatId);
 }

 componentDidMount() {
  if(this.props.match.params.mode === "cw") {
   this.setState({chatCreation: true});
  }
 }

 componentDidUpdate(prevProps: IProps) {
  const uOld = prevProps.match.params.mode + "@" + prevProps.match.params.id;
  const uNew = this.props.match.params.mode + "@" + this.props.match.params.id;
  if (uOld !== uNew) {
   this.setState({chatCreation: false});
  }
 }

 render() {
  const {isFetching, contacts} = this.props;
  const {params} = this.props.match;

  if (isFetching)
   return <PageLoader label={t("Загрузка мессенджера")}/>;

  return (
   <div className="Messenger">
    <Sidebar iniMode={params.mode} chatCreation={this.state.chatCreation} showChatCreateForm={this.showChatCreateForm}/>
    {
     this.state.chatCreation ?
      (<MessengerCreatePage cancelCreateChat={this.cancelChatCreate} chatCreatedHandler={this.chatCreatedHandler} contacts={contacts} wellId={(params.mode==="cw")? params.id: undefined}/>) :
      (
       (params.id && (params.mode==="u" || params.mode==="c")) ?
        ((params.mode==="u")? <MessengerPersonalWrapper userId={params.id} contacts={contacts} /> : <MessengerChat chatId={params.id} contacts={contacts}/>) :
        <MessengerIntro reloadList={()=>this.props.messengerLoad()} showChatCreateForm={this.showChatCreateForm}/>
      )
    }
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(Messenger);
