import React from "react";
import BotHelpOptions from "./helpOptions";
import Weather from "./widgets/weather/weather";
import Currency from "./widgets/currency/crrency";
import {t} from "../../lang"
//import { createChatBotMessage } from "react-chatbot-kit";
const react_chatbot_kit = require('react-chatbot-kit');
const{createChatBotMessage}=react_chatbot_kit;


class BotConfig {

    name = t("Petroviser Bot");


    msg = t("Привет! Я ")+t("Petroviser Bot")+". "+t("Что вас интересует?");

 config= {
        botName: this.name,
        initialMessages: [
            createChatBotMessage(
                this.msg,

                {
                    withAvatar: false,
                    delay: 500,
                    widget: "helpOptions",
                }
            ),
        ],
        customComponents: {
            // Replaces the default header
            header: () => <div style={{
                backgroundColor: '#766a6a',
                padding: "5px",
                borderRadius: "3px",
                fontFamily: "Consolas",
                color: "white"
            }}>{this.name}</div>
        },
        customStyles: {
            botMessageBox: {
                backgroundColor: "#766a6a",
            },
            chatButton: {
                backgroundColor: "#766a6a",
            },
        },
        state: {
            gist: "",
        },
        widgets: [
            {
                widgetName: "helpOptions",
                mapStateToProps: ["gist"],
                widgetFunc: (props) => <BotHelpOptions {...props} />,

                props: {}
            },
            {
                widgetName: "weather",
                mapStateToProps: ["gist"],
                widgetFunc: (props) => <Weather {...props} />,

                props: {}
            },
            {
                widgetName: "currency",
                mapStateToProps: ["gist"],
                widgetFunc: (props) => <Currency {...props} />,

                props: {}
            },
        ],
    }

}
export default  BotConfig;