// enums

export enum VcUserRoleEnum {
 Admin = 1,
 User = 2,
 Blocked = 3,
}

export enum VcMessageStatusEnum {
 Read = 0,
 Unread = 1,
}

// interfaces

export interface IVcRoom {
 id: string;
 ownerId: string;
 createdAt: Date;
 closedAt?: Date;
 name: string;
}

export interface IVcRoomUserRole {
 userId: string;
 userName: string;
 role: VcUserRoleEnum
}

export interface IVcRoomItem {
 room: IVcRoom;
 users: IVcRoomUserRole[];
}

export interface IVcRoomMessage {
 id: string;
 roomId: string;
 ownerId: string;
 createdAt: Date;
 body: string;
 ownerName?: string
 status?: VcMessageStatusEnum;
}

export interface IVcCam {
 id: string
 name: string
}
