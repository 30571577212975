import React from "react";
import {AppState} from "../../store";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {IVWell} from "../../models/struct";
import {IContact} from "../../models/contact";
import {PageError} from "../../components/pageerror";
import PageLoader from "../../components/pageloader";
import OcUsersList from "./users.list";
import OcWellHeader from "./well.header";
import {t} from "../../lang";

interface IRoute {
 wellId: string
}

interface IProps extends RouteComponentProps<IRoute> {
 well?: IVWell
 contacts: IContact[]
 isFetching: boolean
}

const mapState = (state: AppState, own: RouteComponentProps<IRoute>) => {
 const well = state.ocState.wells.find(f => f.id === own.match.params.wellId);
 const contacts = well ? state.ocState.contacts.filter(f => (f.wells && f.wells.length > 0 && f.wells.indexOf(well.id) >= 0)) : [];
 return {
  well,
  contacts,
  isFetching: state.ocState.isFetching,
 }
};

const OcWellUsers: React.FC<IProps> = ({well, contacts, isFetching}) => {
 const [search, setSearch] = React.useState<string>("");
 const [users, setUsers] = React.useState<IContact[]>([]);
 React.useEffect(() => {
  const query = search.toLowerCase().trim();
  const _users = query
   ? contacts.filter(f => (f.name.toLowerCase().indexOf(query) >= 0 || (f.post && f.post.toLowerCase().indexOf(query) >= 0)))
   : contacts;
  setUsers(_users);
 }, [contacts, search]);

 return (!well || isFetching) ? (isFetching ? <PageLoader label={t("Загрузка")}/> : <PageError label={t("Объект не найден")}/>) :
  <div className={"OcPage"}>
   <div className={"header"}>
    <OcWellHeader well={well} />
    {contacts.length > 0 && <input type={"search"} placeholder={t("Поиск")} value={search} onChange={(e: any) => setSearch(e.target.value)}/>}
   </div>
   {(contacts.length > 0) ?
    <OcUsersList contacts={users}/>
    :
    <div className={"oc-empty"}>
     <h2>{t("Контакты не найдены")}</h2>
    </div>}
  </div>
}

export default connect(mapState)(OcWellUsers);

