import {ThunkAction} from "redux-thunk";
import {
 CONTACT_CONNECTED, CONTACT_DISCONNECTED, CONTACTS_FAIL, CONTACTS_FETCH, CONTACTS_OK, CONTACTS_ONLINE, CONTACTS_WELLS_LOADED, ContactsActionTypes,
 OcState
} from "./types";
import {httpGet} from "../../utils/http";
import {errorLog} from "../../utils/console";
import {IContact, IOnlineUsersMap} from "../../models/contact";
import {IVWell} from "../../models/struct";
import {UserActionTypes} from "../user/types";
import {AppState} from "../index";
import {IKeyMap} from "../../models/common";

type AppAction = ThunkAction<void, AppState, undefined, ContactsActionTypes | UserActionTypes>;
type ContactsAction = ThunkAction<void, OcState, undefined, ContactsActionTypes>

interface IUserListResponse {
 // userListItems: IContact[]
 users: IContact[]
}

interface IWellListResponse {
 wells: IVWell[]
 admin: string[]
}

export const ocContactsLoad = (): AppAction => async (dispatch, getState) => {
 try {
  dispatch({type: CONTACTS_FETCH});
  const state = getState();
  const myNodeId = state.userState.user ? state.userState.user.nodeId : "";
  const [usersRes, wellsRes] = await Promise.all([
   httpGet<IUserListResponse>('/mapi/oc/users'),
   httpGet<IWellListResponse>('/mapi/oc/wells'),
  ]);
  // const res = await httpGet<IUserListResponse>('/mapi/oc/users');
  // dispatch({type: CONTACTS_OK, contacts: res.users.sort((a, b) => (a.name > b.name) ? 1 : -1)});

  const usersList = usersRes.users.map(f => {
   const isMyNode = Boolean(myNodeId && myNodeId === f.nodeId);
   return {...f, isMyNode}
  });

  const contacts: IContact[] = usersList.sort((a, b) => (a.name > b.name) ? 1 : -1);
  // const contacts: IContact[] = usersRes.users.sort((a, b) => (a.name > b.name) ? 1 : -1);
  const wells: IVWell[] = wellsRes.wells.map(f => {
   const path = f.company + "/" + f.locality + "/" + (f.cluster ? (f.cluster + "/" + f.well) : f.well);
   return {...f, path};
  }).sort((a, b) => (a.path > b.path) ? 1 : -1);
  const admin: IKeyMap = wellsRes.admin.reduce((p,c) => {
   p[c] = true;
   return p;
  }, {} as IKeyMap);

  dispatch({type: CONTACTS_OK, contacts});
  dispatch({type: CONTACTS_WELLS_LOADED, wells, admin});

  // const res = await httpGet<IUserListResponse>('/mapi/oc/getUserListjson');
  // dispatch({type: CONTACTS_OK, contacts: res.userListItems.sort((a, b) => (a.name > b.name) ? 1 : -1)});
 }
 catch (error) {
  errorLog('Contacts load error', error);
  dispatch({type: CONTACTS_FAIL, error});
 }
};

export const ocContactsOnline = (users: string[]): ContactsAction => async dispatch => {
 try {
  const online: IOnlineUsersMap = users.reduce((p, c) => {
   if (!p[c])
    p[c] = true;
   return p;
  }, {} as IOnlineUsersMap);
  dispatch({type: CONTACTS_ONLINE, online});
 }
 catch (error) {
  errorLog('Contacts online error', error);
 }
};

export const ocContactConnectedOnline = (userId: string): ContactsAction => async dispatch => {
 try {
  dispatch({type: CONTACT_CONNECTED, userId});
 }
 catch (error) {
  errorLog('Contact connected online error', error);
 }
};

export const ocContactDisconnectedOnline = (userId: string): ContactsAction => async dispatch => {
 try {
  dispatch({type: CONTACT_DISCONNECTED, userId});
 }
 catch (error) {
  errorLog('Contact disconnected online error', error);
 }
}
