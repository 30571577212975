import React from "react";
import {t} from "../../lang";
import NavSettings from "./navsettings";
import {INotifyRuleDetail, INotifyRuleResponse} from "../../models/notification";
import {Link, NavLink} from "react-router-dom";
import {AppState} from "../../store";
import {IUserFull} from "../../models/user";
import {connect} from "react-redux";

interface IProps {
    me: IUserFull,
}
const ruleDetails: INotifyRuleDetail[] = [{
    id: "1",
    description: "first test rule",
    owner_id: "1",
    owner_name: "Admin",
    active: true,
    selected_monitors: ["b2308ff5-b00b-43dc-a437-d3dc73211bae", "be464b64-5920-4f56-a4b3-1cc033fdf2b9"],
    method: "e-mail",
    method_params: "",
    schedule: "Каждый час",
    time: ""
},
    {
        id: "2",
        description: "second test rule",
        owner_id: "6f2c2f6a-0d0d-4442-8f33-f97dadf9d978",
        owner_name: "Виктория Ро",
        active: false,
        selected_monitors: ["b2308ff5-b00b-43dc-a437-d3dc73211bae"],
        method: "e-mail",
        method_params: "",
        schedule: "Настраиваемое",
        time: "10:30 UTC+3:00"
    },
    {
        id: "3",
        description: "Test rule",
        owner_id: "6f2c2f6a-0d0d-4442-8f33-f97dadf9d978",
        owner_name: "Виктория Ро",
        active: true,
        selected_monitors: ["b2308ff5-b00b-43dc-a437-d3dc73211bae", "be464b64-5920-4f56-a4b3-1cc033fdf2b9", "cea5df8c-df38-4204-ae24-68a38b4906f3"],
        method: "e-mail",
        method_params: "",
        schedule: "Каждый час",
        time: ""
    }];

interface IState {
    id: string;
    description: string;
    owner_id: string;
    owner_name: string;
    active: boolean;
    selected_monitors: string[];
    method:string;
    method_params: string;
    schedule: string;
    hour: string;
    min: string;
    t_zone:string
}

const mapState = (state: AppState) => {
    return {
        me: state.userState.user
    };
}
class EditMonitorRule extends React.Component<IProps, IState> {
    state: IState = {

            id: "",
            description: "",
            owner_id: "",
            owner_name: "",
            active: true,
            selected_monitors: [],
            method: "",
            method_params: "",
            schedule: "",
            hour: "",
        min:"",
        t_zone:""
    };
    componentDidMount(): void {
        console.log(this.props.me);
        this.getRuleDetails();
    }
    getRuleDetails(): void {
        console.log("id");
        // @ts-ignore
        const id = this.props.match.params.id;
        const index = ruleDetails.findIndex(r => r.id === id);
        if(ruleDetails[index].time!='')
        {

        }
        this.setState({
            id: ruleDetails[index].id,
            description: ruleDetails[index].description,
            owner_id: ruleDetails[index].owner_id,
            owner_name: ruleDetails[index].owner_name,
            active: ruleDetails[index].active,
            selected_monitors:ruleDetails[index].selected_monitors,
            method: ruleDetails[index].method,
            method_params: ruleDetails[index].method_params,
            schedule: ruleDetails[index].schedule,
        });
        if(ruleDetails[index].time!='')
        {
            this.setState({
                hour: ruleDetails[index].time.split(':')[0]+t("ч"),
                min:ruleDetails[index].time.split(':')[1].split(' ')[0]+t("мин"),
                t_zone:ruleDetails[index].time.split(':')[1].split(' ')[1]
            });
        }
        console.log(id);
    }
    changeInputValue = (e: any) => {
        console.log(e);
        console.log(e.target.value);
        console.log(e.target.name);
        console.log(e.target.name);
        this.setState({[e.target.name]: e.target.value} as IState)

    }
    render() {
        return (
            <div className="MonitorSettingsContainer">
                <div className="Monitor">
                    <div className="RuleFormWrapper">
                        <form className="RuleForm">
                            <div>
                                <div className="title">
                                    <h1>{t("Посмотреть подписку")} : {this.state.id}</h1>
                                </div>
                                <h2> {t("Описание")}</h2>
                                <input  value={t(this.state.description)} name="description" onChange={this.changeInputValue} required/>
                                <div className="Container">
                                    <div>
                                        <h2> {t("Метод доставки")}</h2>
                                        <select value={t(this.state.method)} onChange={this.changeInputValue} name="method" required>
                                            <option value="e-mail">e-mail</option>
                                            <option value="чат">{t("чат")}</option>
                                        </select>

                                    </div>
                                    <div>
                                        <h2> {t("Параметры")}</h2>
                                        { this.state.method=='e-mail'?
                                            <input value={ this.props.me.email!=undefined ? t(this.props.me.email): t("адрес не найден")} name="method_params" readOnly
                                                   required/>:
                                            <input  value={t(this.state.method_params)} name="method_params"  onChange={this.changeInputValue} required/>
                                        }
                                    </div>
                                </div>
                                <div className="Container">
                                    <div>
                                        <h2> {t("Расписание")}</h2>
                                        <select  value={t(this.state.schedule)} name="schedule" onChange={this.changeInputValue} required>
                                            <option value="Настраиваемое">{t("Настраиваемое")}</option>
                                            <option value="По событию">{t("По событию")}</option>
                                            <option value="По событию">{t("По событию")}</option>

                                        </select>
                                        {this.state.schedule === "Настраиваемое" && (
                                            <div className="Time">
                                                <div>
                                                    <select  value={t(this.state.hour)} name="" onChange={this.changeInputValue} required>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="0">0</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <select  value={t(this.state.schedule)} name="time_1" onChange={this.changeInputValue} required>
                                                        <option value="set">{t("Настраиваемое")}</option>
                                                        <option value="event">{t("По событию")}</option>
                                                        <option value="hour">{t("Каждый час")}</option>
                                                    </select>
                                                    <input value={t(this.state.hour)} name="time_1" required/>
                                                </div>
                                                <div>
                                                    <select  value={t(this.state.schedule)} name="time_1" onChange={this.changeInputValue} required>
                                                        <option value="set">{t("Настраиваемое")}</option>
                                                        <option value="event">{t("По событию")}</option>
                                                        <option value="hour">{t("Каждый час")}</option>
                                                    </select>
                                                    <input value={t(this.state.min)} name="time_2" required/>
                                                </div>
                                                <div>
                                                    <input value={t(this.state.t_zone)} name="time_3" required/>
                                                </div>
                                            </div>)}
                                    </div>
                                    <div>
                                        <h2> {t("Объекты")}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="Actions">
                                <NavLink to={"/app/monitor/settings"}>
                                    <button type="button">{t("Отмена")}</button>
                                </NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapState)(EditMonitorRule);