import React from "react";
import {IContact} from "../../models/contact";
import {AppState} from "../../store";
import {messengerModifyChat, messengerModifyChatWell} from "../../store/messenger/actions";
import {connect} from "react-redux";
import {ChatTypeEnum, IChatExt, IChatUserRole} from "../../models/messenger";
import {MessengerModifyRoomChat} from "./modify.chat";
import {MessengerModifyWellChat} from "./modify.wellchat";
import {IVWell} from "../../models/struct";

interface IOwnProps {
 contacts: IContact[]
 chat: IChatExt
 users: IChatUserRole[]
 cancelModifyChat: Function
}

interface IProps extends IOwnProps{
 well?: IVWell
 isHandling: boolean
 messengerModifyChat: any
 messengerModifyChatWell: any
}

const mapState = (state: AppState, {chat}: IOwnProps) => {
 const well = (chat && chat.type===ChatTypeEnum.Well && chat.targetId)? state.ocState.wells.find(f => f.id===chat.targetId): undefined;
 return {
  well,
  isHandling: state.messengerState.isChatHandling
 }
};

const mapDispatch = {
 messengerModifyChat,
 messengerModifyChatWell
}

class MessengerModifyChat extends React.Component<IProps> {
 render() {
  const {chat, contacts, users, well, isHandling, cancelModifyChat, messengerModifyChat, messengerModifyChatWell} = this.props;

  if (chat.type === ChatTypeEnum.Room)
   return <MessengerModifyRoomChat contacts={contacts} chat={chat} users={users} isHandling={isHandling}
                                   cancelModifyChat={cancelModifyChat} messengerModifyChat={messengerModifyChat}/>
  if (chat.type === ChatTypeEnum.Well)
   return <MessengerModifyWellChat chat={chat} well={well} isHandling={isHandling}
                                   cancelModifyChat={cancelModifyChat} messengerModifyChat={messengerModifyChatWell}/>

  return null;
 }
}

export default connect(mapState, mapDispatch)(MessengerModifyChat);
