
import React, {useState} from "react";
import {t, getLang} from '../../../../lang'



const Weather= (props) => {

    const api = {
        key: "b225aa25e7d2af217ad3bdff93fba00f",
        base: "https://api.openweathermap.org/data/2.5/"
    }


    const [query, setQuery] = useState('');
    const currLang=getLang();
    console.log(currLang);
    const[isIni,setIni]=useState(true);
    const[isError,setError]=useState('');
    const [weather, setWeather] = useState({"weather":[{"id":'',"main":"","description":"","icon":""}],"main":{"temp":0},"sys":{"country":""}, "name":""});
    function handleErrors(response) {
        if (!response.ok) throw new Error(response.status);
        return response;
    }

    const search = evt => {
        if (evt.key === "Enter") {
            fetch(`${api.base}weather?q=${query}&units=metric&APPID=${api.key}&lang=${currLang}`).then(handleErrors)
                .then(res => res.json())
                .then(result => {
                    setWeather(result);
                    setQuery('');
                    console.log(result);
                    console.log(weather);
                    setIni(false);
                    setError("");
                }).catch(err=> {
                    console.log(err);
                    setIni(false);
                    setError(t("Возникла ошибка. Проверьте, пожалуйста, что название города введено верно."));


                });
        }
    }

    const dateBuilder = (d) => {
        let months = [t("Январь"), t("Февраль"), t("Март"), t("Апрель"), t("Май"), t("Июнь"),
            t("Июль"), t("Август"),t("Сентябрь"), t("Октябрь"), t("Ноябрь"), t("Декабрь")];
        let days = [t("Вс"), t("Пн"), t("Вт"), t("Ср"), t("Чт"), t("Пт"), t("Сб")];

        let day = days[d.getDay()];
        let date = d.getDate();
        let month = months[d.getMonth()];
        let year = d.getFullYear();

        return `${day} ${date} ${month} ${year}`
    }

    return (
        <div className = 'weather-container' >

            <main>
                <div className="weather-search-box">
                    <input
                        type="text"
                        className="search-bar"
                        placeholder={t("Поиск...")}
                        onChange={e => setQuery(e.target.value)}
                        value={query}
                        onKeyPress={search}
                    />
                </div>
                <div>
                {(!isIni&&isError.length===0) ? (
                    <div  className='weather-data'>
                        <div className="location-box">
                            <div className="location">{weather.name}, {weather.sys.country}</div>
                            <div className="date">{dateBuilder(new Date())}</div>
                        </div>
                        <div className="weather-box">
                            <div className="temp">
                                {Math.round(weather.main.temp)}°C
                            </div>
                            <div className="weather">{weather.weather[0].description}</div>
                        </div>
                    </div>
                ) : ('')}
                    {(isError.length>0)? (
                    <div  className='weather-data-error'>
                        {isError}
                        </div>) : ('')
                    }
                </div>
            </main>
        </div>
    );
}


export default Weather;
