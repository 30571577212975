import {IMapPoint} from "./models";
import {httpGet} from "../../utils/http";
import {LatLng} from "leaflet";

interface IMapPointHttpResult {
 DeviceId: number
 Date: number
 Time: number
 Lat: number
 Lng: number
 Speed: number
}

export const getMapRealtime = async (): Promise<IMapPoint[]> => {
 const res = await httpGet<IMapPointHttpResult[]>('/mapi/gps/GetActualTracks');
 return res.map(r => {
  const deviceId = r.DeviceId.toString();
  const date = r.Date.toString();
  const dateLen = date.length;
  const day = Number(date.substr(0, dateLen - 4));
  const month = Number(date.substr(dateLen - 4, 2));
  const year = Number(date.substr(dateLen - 2));
  const time = r.Time.toString();
  const timeLen = time.length;
  const hour = Number(time.substr(0, timeLen - 4));
  const min = Number(time.substr(timeLen - 4, 2));
  const sec = Number(time.substr(timeLen - 2));
  // return {deviceId, date: new Date(year, month - 1, day, hour, min, sec), position: new LatLng(r.Lat, r.Lng)} as IMapPoint
  return {deviceId, date: new Date(year, month - 1, day, hour, min, sec), position: new LatLng(r.Lng, r.Lat)} as IMapPoint
 });
}