import {IContact, IOnlineUsersMap} from "../../models/contact";
import {IVWell} from "../../models/struct";
import {IKeyMap} from "../../models/common";

export interface OcState {
 contacts: IContact[]
 wells: IVWell[]
 admin: IKeyMap
 online: IOnlineUsersMap
 isFetching: boolean
 error: any
}

export const CONTACTS_FETCH = 'contacts/fetch';
interface ContactsFetchAction {
 type: typeof CONTACTS_FETCH
}

export const CONTACTS_OK = 'contacts/ok';
interface ContactsOkAction {
 type: typeof CONTACTS_OK
 contacts: IContact[]
}

export const CONTACTS_WELLS_LOADED = 'contacts/wells';
interface ContactsWellsAction {
 type: typeof CONTACTS_WELLS_LOADED
 wells: IVWell[]
 admin: IKeyMap
}

export const CONTACTS_FAIL = 'contacts/fail';
interface ContactsFailAction {
 type: typeof CONTACTS_FAIL
 error: any
}

export const CONTACTS_RESET = 'contacts/reset';
interface ContactsResetAction {
 type: typeof CONTACTS_RESET
}

export const CONTACTS_ONLINE = 'contacts/online';
interface ContactsOnlineAction {
 type: typeof CONTACTS_ONLINE
 online: IOnlineUsersMap
}

export const CONTACT_CONNECTED = 'contacts/contact_connected';
interface ContactConnectedAction {
 type: typeof CONTACT_CONNECTED
 userId: string
}

export const CONTACT_DISCONNECTED = 'contacts/contact/disconnected';
interface ContactDisconnectedAction {
 type: typeof CONTACT_DISCONNECTED
 userId: string
}

export type ContactsActionTypes = ContactsFetchAction | ContactsOkAction | ContactsWellsAction | ContactsFailAction | ContactsResetAction
| ContactsOnlineAction | ContactConnectedAction | ContactDisconnectedAction
