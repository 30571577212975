import React from "react";
import {connect} from "react-redux";
import {IVcCam} from "../../models/vc";
import {vcRoomAddCam, vcRoomGetCams, vcRoomGetFreeCamDevices, vcRoomRemoveCam} from "../../store/vc/actions";
import {t} from "../../lang";

interface IProps {
 roomId: string
 cams: IVcCam[]
 vcRoomAddCam: any
 vcRoomRemoveCam: any
}

const mapDispatch = {
 vcRoomAddCam,
 vcRoomRemoveCam
};

interface IState {
 roomCams: IVcCam[]
 loadingRoomCams: boolean
 freeDevices: string[]
 handling: string[]
}

class VcCamsForm extends React.Component<IProps, IState> {
 state: IState = {
  roomCams: [],
  loadingRoomCams: true,
  freeDevices: [],
  handling: []
 };

 componentDidMount() {
  this.loadRoomCams();
 }

 componentDidUpdate(prev: IProps) {
  const prev_cams = prev.cams.map(f => f.id).join(";");
  const now_cams = this.props.cams.map(f => f.id).join(";");
  if (prev_cams !== now_cams) {
   if (window.console) {
    console.log('cams updated');
    console.log('p', prev_cams);
    console.log('n', now_cams);
   }
   this.loadFreeDevices();
  }
 }

 loadRoomCams = async () => {
  await this.loadFreeDevices();
  this.setState({roomCams: [], loadingRoomCams: true});
  try {
   const roomCams: IVcCam[] = await vcRoomGetCams(this.props.roomId);
   if (this.props.cams.length > 0) {
    for (let i = 0; i < this.props.cams.length; i++) {
     const c = this.props.cams[i];
     if (!Boolean(roomCams.find(f => f.id === c.id)))
      roomCams.push(c);
    }
   }
   this.setState({roomCams, loadingRoomCams: false});
  }
  catch (err) {
   this.setState({loadingRoomCams: false})
  }
 }

 loadFreeDevices = async () => {
  try {
   const freeDevices: string[] = await vcRoomGetFreeCamDevices(this.props.roomId);
   this.setState({freeDevices});
  }
  catch (err) {
   this.setState({freeDevices: []})
  }
 }

 clickCamera = async (e: any) => {
  const v = e.target.value;
  this.setState({handling: [...this.state.handling, v]});
  if (e.target.checked) {
   await this.props.vcRoomAddCam(this.props.roomId, v);
  }
  else {
   await this.props.vcRoomRemoveCam(this.props.roomId, v);
  }
  this.setState({handling: this.state.handling.filter(f => f !== v)});
 }

 render() {
  if (this.state.loadingRoomCams)
   return <div className={"cams-row"}><div className={"info-msg"}><i className={"far fa-hourglass"}></i><span>{t("Загрузка списка камер")}</span></div></div>

  const disableAdd = !Boolean(this.state.freeDevices.length > 0);

  return (
   <div className={"cams-row"}>
    {(this.state.roomCams.length > 0) ? (
     <div>
      {disableAdd
       ? <div className={"info-msg err"}><i className={"fas fa-exclamation-circle"}></i><span>{t("Добавление камер сейчас невозможно")}</span></div>
       : <div className={"info-msg"}><i className={"fas fa-info"}></i><span>{t("Обратите внимание, что добавление/удаление внешних камер просиходит с небольшой задержкой")}</span>
       </div>}
      <ul>
       {this.state.roomCams.map(c => {
        const checked = Boolean(this.props.cams.find(f => f.id === c.id));
        const isHandling = Boolean(this.state.handling.indexOf(c.id) >= 0);
        return (
         <li key={c.id}>
          <label>
           <input type={"checkbox"} disabled={(!checked && disableAdd) || isHandling} value={c.id} checked={checked} onChange={this.clickCamera}/>
           <span>{c.name} {isHandling && <i>...</i>}</span>
          </label>
         </li>
        )
       })}
      </ul>
     </div>
    ) : (<div className={"info-msg err"}><i className={"fas fa-exclamation-circle"}></i><span>{t("Нет доступных камер")}</span></div>)}

   </div>
  )
 }
}

export default connect(null, mapDispatch)(VcCamsForm);