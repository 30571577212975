import {LIVE_CONNECT, LIVE_CONNECTED, LIVE_DISCONNECTED, LiveActionTypes, LiveState} from "./types";
import {headerRegister, headerUnregister} from "../../utils/http";

const iniState: LiveState = {
 socket: undefined,
 liveId: ""
}

export function liveReducer(state: LiveState = iniState, action: LiveActionTypes): LiveState {
 switch (action.type) {
  case LIVE_CONNECT:
   return {socket: action.socket, liveId: ""};
  case LIVE_CONNECTED:
   headerRegister("X-Live-Id", action.liveId);
   return {...state, liveId: action.liveId};
  case LIVE_DISCONNECTED:
   headerUnregister("X-Live-Id");
   if(state.socket)
    state.socket.close();
   return {...iniState};
  default:
   return state;
 }
}