import React from "react";
import {t} from "../../lang"
import Quote from "./widgets/quote/quote";
import {consoleLog} from "../../utils/console";
const BotHelpOptions = (props) => {
    const options = [
        { text: t("Погода"),
            handler: handleWeather,
            id: 1 },
        { text: t("Курс валют"),
            handler: handleCurrency,
            id: 2 },
        { text: t("Умная мысль"),
            handler: handleQuotes,
            id: 3 },

    ];

    function handleWeather() {

        console.log("Weather");
        const messages=props.actionProvider.createChatBotMessage(t("Укажите, пожалуйста, город и нажмите Enter, чтобы узнать погоду."),
            {widget: "weather", withAvatar: true}
            )
        props.actionProvider.updateChatbotState(messages)
    }
    function handleCurrency() {

        console.log("Currency");
        const messages=props.actionProvider.createChatBotMessage(t("Курсы валют к RUB: "),
            {widget: "currency", withAvatar: true}
        )
        props.actionProvider.updateChatbotState(messages)
    }

 function  handleQuotes() {


        Quote(props);


    }


    const optionsMarkup = options.map((option) => (


        <button

            key={option.id}
            onClick={option.handler}
        >
            <span>
            {option.text}
            </span>
        </button>

    ));

    return <div className="bot-options">{optionsMarkup}</div>;
};

export default BotHelpOptions;
