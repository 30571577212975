import {
 MESSENGER_CHAT_DESELECT,
 MESSENGER_CHAT_HANDLE,
 MESSENGER_CHAT_HANDLE_FAIL,
 MESSENGER_CHAT_HANDLE_OK,
 MESSENGER_CHAT_REMOVED,
 MESSENGER_CHAT_SELECT,
 MESSENGER_CHAT_SELECT_FAIL,
 MESSENGER_CHAT_SELECT_OK,
 MESSENGER_CHAT_UPDATED,
 MESSENGER_FETCHED,
 MESSENGER_INI,
 MESSENGER_MSG_POST,
 MESSENGER_MSG_POST_FAIL,
 MESSENGER_MSG_POST_OK,
 MESSENGER_MSG_READ,
 MESSENGER_MSG_RECEIVED,
 MESSENGER_UNREADS_RECEIVED,
 MessengerActionTypes,
 MessengerState,
 MessengerUnreadMap
} from "./types";
import {
 ChatMessageStatusEnum, IChatExt, IChatItem, IChatLastUpdateMap, IChatMessage,
 IChatUserRole
} from "../../models/messenger";

const iniState: MessengerState = {
 // chatItems: [],
 chats: [],
 selectedChatId: "",
 users: [],
 messages: [],
 isFetching: false,
 isPosting: false,
 isSelecting: false,
 isChatHandling: false,
 unread: {},
 updates: {}
};

const messengerChatHandle = (state: MessengerState, chatItem: IChatItem, updateChatHandling = true): MessengerState => {
 // const chatItems: IChatItem[] = (state.chatItems.find(f => f.chat.id === chatItem.chat.id))
 //  ? state.chatItems.map(f => (f.chat.id === chatItem.chat.id) ? chatItem : f)
 //  : [...state.chatItems, chatItem];
 // if (updateChatHandling)
 //  return {...state, isChatHandling: false, chatItems};
 // return {...state, chatItems};

 const chats: IChatExt[] = (state.chats.find(f => f.id === chatItem.chat.id))
  ? state.chats.map(f => (f.id === chatItem.chat.id) ? chatItem.chat : f)
  : [...state.chats, chatItem.chat];
 const users: IChatUserRole[] = (state.selectedChatId === chatItem.chat.id) ? chatItem.users : state.users;
 if (updateChatHandling)
  return {...state, users, isChatHandling: false, chats};
 return {...state, users, chats};
};

const messengerMsgReceived = (state: MessengerState, msg: IChatMessage): MessengerState => {
 let unread: MessengerUnreadMap | undefined = undefined;
 if (msg.status && msg.status === ChatMessageStatusEnum.Unread) {
  unread = {...state.unread};
  if (!unread[msg.chatId])
   unread[msg.chatId] = [];
  unread[msg.chatId].push(msg.id);
 }
 let messages: IChatMessage[] | undefined = undefined;
 if (state.selectedChatId === msg.chatId) {
  messages = [...state.messages, msg];
 }
 let updates: IChatLastUpdateMap | undefined = undefined;
 if (!state.updates[msg.chatId] || state.updates[msg.chatId] < msg.createdAt) {
  updates = {...state.updates};
  updates[msg.chatId] = msg.createdAt;
 }
 if (unread !== undefined || messages !== undefined || updates !== undefined) {
  let updatedState = {...state};
  if (unread !== undefined)
   updatedState.unread = unread;
  if (messages !== undefined)
   updatedState.messages = messages;
  if (updates !== undefined)
   updatedState.updates = updates;
  return updatedState;
 }
 return state;
}

const messengerMsgRead = (state: MessengerState, msgId: string, chatId: string): MessengerState => {
 const idx = (state.unread[chatId]) ? state.unread[chatId].indexOf(msgId) : -1;
 if (idx >= 0) {
  const unread = {...state.unread};
  unread[chatId].splice(idx, 1);
  if (unread[chatId].length <= 0)
   delete unread[chatId];
  if (state.selectedChatId === chatId) {
   const messages = state.messages.map(m => ((m.id === msgId) ? {...m, status: ChatMessageStatusEnum.Read} : m));
   return {...state, unread, messages};
  }
  return {...state, unread};
 }
 return state;
}

const messengerUnreadsReceived = (state: MessengerState, messages: IChatMessage[]): MessengerState => {
 const unread: MessengerUnreadMap = messages.reduce((p, c) => {
  if (!p[c.chatId])
   p[c.chatId] = [];
  p[c.chatId].push(c.id);
  return p;
 }, {} as MessengerUnreadMap);
 return {...state, unread};
}

export function messengerReducer(state: MessengerState = iniState, action: MessengerActionTypes): MessengerState {
 switch (action.type) {
  case MESSENGER_INI:
   return {...state, isFetching: true};
  case MESSENGER_FETCHED:
   return {...state, isFetching: false, chats: action.chats, updates: action.updates};

  case MESSENGER_CHAT_HANDLE:
   return {...state, isChatHandling: true};
  case MESSENGER_CHAT_HANDLE_OK:
   return messengerChatHandle(state, action.chatItem);
  case MESSENGER_CHAT_HANDLE_FAIL:
   return {...state, isChatHandling: false};
  case MESSENGER_CHAT_UPDATED:
   return messengerChatHandle(state, action.chatItem, false);
  case MESSENGER_CHAT_REMOVED:
   return {...state, chats: state.chats.filter(f => f.id !== action.chatId)};

  case MESSENGER_CHAT_SELECT:
   return {...state, isSelecting: true, selectedChatId: action.selectedChat, users: [], messages: []};
  case MESSENGER_CHAT_SELECT_OK:
   return {...state, isSelecting: false, users: action.users, messages: action.messages};
  case MESSENGER_CHAT_SELECT_FAIL:
   return {...state, isSelecting: false, selectedChatId: "", users: [], messages: []};
  case MESSENGER_CHAT_DESELECT:
   return {...state, selectedChatId: "", users: [], messages: []};

  case MESSENGER_MSG_POST:
   return {...state, isPosting: true};
  case MESSENGER_MSG_POST_OK:
   return {...state, isPosting: false, messages: [...state.messages, action.msg]};
  case MESSENGER_MSG_POST_FAIL:
   return {...state, isPosting: false};
  case MESSENGER_MSG_RECEIVED:
   return messengerMsgReceived(state, action.msg);
  case MESSENGER_MSG_READ:
   return messengerMsgRead(state, action.msgId, action.chatId);

  case MESSENGER_UNREADS_RECEIVED:
   return messengerUnreadsReceived(state, action.messages);

  default:
   return state;
 }
}
