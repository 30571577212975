import React from "react";
import {IContact, IOnlineUsersMap} from "../../models/contact";
import {AppState} from "../../store";
import {connect} from "react-redux";
import {vcCreateRoom} from "../../store/vc/actions";
import {t} from "../../lang";

interface ICheckedMap {
 [key: string]: boolean
}

interface IProps {
 contacts: IContact[]
 online: IOnlineUsersMap
 selectedId: string
 isHandling: boolean
 vcCreateRoom: any
 createdOkHandler: (string) => void
 createCancelHandler: () => void
}

interface IState {
 name: string
 selected: string[]
 search: string
}

const mapState = (state: AppState) => ({
 contacts: state.ocState.contacts.filter(f => (f.id !== state.userState.user.id && f.nodeId && f.nodeId === state.userState.user.nodeId)),
 online: state.ocState.online,
 selectedId: state.vcState.selectedRoom,
 isHandling: state.vcState.isRoomHandling,
})

const mapDispatch = {
 vcCreateRoom
}

class VcCreateForm extends React.Component<IProps, IState> {
 box: any;
 state = {
  name: "",
  selected: [],
  search: ""
 }

 componentDidMount() {
  document.addEventListener("click", this.handleClick);
 }

 componentWillUnmount() {
  document.removeEventListener("click", this.handleClick);
 }

 handleClick = (e: any) => {
  if (this.box && !this.box.contains(e.target))
   this.props.createCancelHandler();
 }

 changeName = (e: any) => this.setState({name: e.target.value});
 changeSearch = (e: any) => this.setState({search: e.target.value});
 changeUser = (e: any) => {
  const id = e.target.value;
  if (Boolean(this.state.selected.find(f => f === id)))
   this.setState({selected: this.state.selected.filter(f => f !== id)});
  else
   this.setState({selected: [...this.state.selected, id]});
 }
 submitForm = async (e: any) => {
  e.preventDefault();
  if (this.canSubmit()) {
   try {
    const roomId = await this.props.vcCreateRoom(this.state.name, this.state.selected);
    this.props.createdOkHandler(roomId);
   }
   catch (error) {
   }
  }
 }

 canSubmit = (): Boolean => Boolean((Boolean(this.state.name.trim()) && this.state.selected.length > 0));

 render() {
  const online = this.props.online;
  const checked = this.state.selected.reduce((p, c) => {
   p[c] = true;
   return p;
  }, {} as ICheckedMap);

  const search = this.state.search.toLowerCase().trim();
  const contacts = search ? this.props.contacts.filter(f => f.name.toLowerCase().indexOf(search) >= 0) : this.props.contacts;

  return (
   <div className="VcCreateRoomForm" ref={el => this.box = el}>
    <h1>{t("Создание новой видеоконференции")}</h1>
    <form onSubmit={this.submitForm}>
     <div className="name-row">
      <h3>{t("Название")}</h3>
      <input disabled={this.props.isHandling} value={this.state.name} onChange={this.changeName}/>
     </div>
     <div className="users-row">
      <div className="users-row-title">
       <h3>{t("Участники")}</h3>
       <input type="search" placeholder={t("Поиск")} value={this.state.search} onChange={this.changeSearch}/>
      </div>
      {contacts.length > 0 ? (<ul>
       {contacts.map(c => (
        <li key={c.id}>
         <label><input disabled={this.props.isHandling} type={"checkbox"} value={c.id} checked={Boolean(checked[c.id])}
                       onChange={this.changeUser}/> {c.name}</label>
         {c.isMyNode && <label className={"OnlineStatus" + (online[c.id] ? " online" : " offline")}>{online[c.id] ? "online" : "offline"}</label>}
        </li>
       ))}
      </ul>) : (<div className="no-users">{t("Пользователи не найдены")}</div>)}
     </div>
     <div className="actions-row">
      <button disabled={!this.canSubmit() || this.props.isHandling} type={"submit"}>{t("Создать")}</button>
      <button onClick={this.props.createCancelHandler}>{t("Отмена")}</button>
     </div>
    </form>
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(VcCreateForm);
