import React from "react";
import {getLang, t} from '../../../../lang'
import $ from 'jquery';

interface IQuote {
    quoteText: string,
    quoteAuthor: string


}

 function Quote  (props)
{
    let lang = getLang();
    let quote="";
    console.log(process.env.REACT_APP_QUOTES_API);
     $.ajax({
        jsonp: "jsonp",
        dataType: "jsonp",
        url: process.env.REACT_APP_QUOTES_API,
        contentType: "application/jsonp",
        data: {
            lang: lang,
            method: "getQuote",
            format: 'jsonp'
        },
        success: function(data) { quote= (data as IQuote).quoteText  +"   ----" +(data as IQuote).quoteAuthor;
        quoteMsg(quote, props);

           },
        error: function () {   quote= t("Не удалось получить цитату.");
            quoteMsg(quote, props);
        }});

    }

    function quoteMsg(msg:string, props ) {
        const messages=props.actionProvider.createChatBotMessage(msg);
        props.actionProvider.updateChatbotState(messages)

    }

export default Quote;