import React from "react";
import {IVWell} from "../../models/struct";
import {AppState} from "../../store";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useOutsideClick} from "../../utils/useOutsideClick";

interface IOwnProps {
 well: IVWell
}

interface IProps extends IOwnProps {
 wells: IVWell[]
}

const mapState = (state: AppState, own: IOwnProps) => ({
 wells: state.ocState.wells.filter(f => f.id !== own.well.id)
});

const OcWellHeader: React.FC<IProps> = ({well, wells}) => (
 (wells.length > 0)? <OcWellHeaderWithMenu well={well} wells={wells} />: <h1>{well.path}</h1>
)

const OcWellHeaderWithMenu: React.FC<IProps> = ({well, wells}) => {
 const submenuRef = React.useRef<HTMLDivElement>(null);
 const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
 const clickOutside = () => {
  if(activeMenu)
   setActiveMenu(false);
 };
 useOutsideClick(submenuRef, clickOutside);

 return (
  <div className={"with-submenu" + (activeMenu? " active":"")}>
   <h1>{well.path}</h1>
   <button className={"submenu-trigger"} onClick={()=>setActiveMenu(!activeMenu)}><i className={"fas fa-caret-down"}></i></button>
   <div className={"submenu"} ref={submenuRef}>
    <ul>
     {wells.map(w => <li key={w.id}><Link to={`/app/wells/${w.id}/contacts`}>{w.path}</Link></li>)}
    </ul>
   </div>
  </div>
 )
}

export default connect(mapState)(OcWellHeader)