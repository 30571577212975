import React from "react";
import {ChatMessageTypeEnum, IChatBodyFile, IChatMessage} from "../../models/messenger";
import {formatBytes} from "../../utils/str";
import {userMentionText} from "../../utils/mention";

interface IBodyProps {
 text: string
}

interface IBodyWithLoginProps extends IBodyProps {
 myLogin: string
}

const MessageBodyBlock: React.FC<IBodyProps> = ({text}) => {
 return <>{text}<br/></>
}

const MessageBodyText: React.FC<IBodyWithLoginProps> = ({text, myLogin}) => {
 const um = userMentionText(text);

 return (
  <div>
   {(um.mentions && um.mentions.length > 0) && <ul className={"mentions"}>{um.mentions.map(m => <li className={(myLogin===m)? "itsme":""} key={m}>@{m}</li>)}</ul>}
   {um.text.split("\n").map((txt, i) => <MessageBodyBlock key={i} text={txt}/>)}
  </div>
 )
}

interface IFilesProps extends IBodyProps {
 msgId: string
 chatId: string
}

const MessageBodyFiles: React.FC<IFilesProps> = ({text, chatId, msgId}) => {
 // const files: IChatBodyFile[] = JSON.parse(text) as IChatBodyFile[];
 const json = JSON.parse(text);
 const files: IChatBodyFile[] = json.files ? json.files as IChatBodyFile[] : [];

 return (
  <ul className="files">{files.map((f, i) => <li key={i}>
   <a href={`/mapi/chats/files/${chatId}/${msgId}/${f.file}`}><i className={"fa fa-paperclip"}/>{f.file}</a>
   <span className="size">{formatBytes(f.size)}</span>
  </li>)}</ul>
 )
}


interface IProps {
 msg: IChatMessage
 myLogin: string
}

export const MessageBody: React.FC<IProps> = ({msg, myLogin}) => {
 switch (msg.type) {
  case ChatMessageTypeEnum.Text:
   return <MessageBodyText text={msg.body} myLogin={myLogin}/>
  case ChatMessageTypeEnum.Files:
   return <MessageBodyFiles text={msg.body} chatId={msg.chatId} msgId={msg.id}/>
 }
 return null;
}


