import React from "react";
import {IChatUserRole} from "../../models/messenger";
import {IOnlineUsersMap} from "../../models/contact";
import {useOutsideClick} from "../../utils/useOutsideClick";
import {IKeyStringMap} from "../../models/common";
import {t} from "../../lang";

interface IProps {
 myId: string
 online: IOnlineUsersMap,
 users: IChatUserRole[]
 mentioned: IKeyStringMap
 userMention: (userId: string) => void
}

export const ChatUsers: React.FC<IProps> = ({myId, online, users, mentioned, userMention}) => {
 const menuRef = React.useRef<HTMLDivElement>(null);
 const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
 const clickOutsideMenu = () => {
  if (activeMenu)
   setActiveMenu(false);
 }
 useOutsideClick(menuRef, clickOutsideMenu);

 return (
  <>
   <button onClick={() => setActiveMenu(!activeMenu)} className={"fas fa-user-friends"}><i></i><label>{t("Участники")}</label></button>
   <div className={"ChatUsers" + (activeMenu ? " active" : "")} ref={menuRef}>
    <h3>{t("Участники")}</h3>
    <button className={"close-button"} onClick={() => setActiveMenu(false)}><i className={"fas fa-times-circle"}></i></button>
    <ul>
     {users.map(f => (
      <li key={f.userId}>
       {f.userId === myId
        ? <><label>{f.userName}</label><span className={"itsme"}>{t("Я")}</span></>
        : <label className={Boolean(mentioned[f.userId]) ? "mentioned" : "unmentioned"} onClick={() => userMention(f.userId)}>{f.userName}</label>
       }
      </li>
     ))}
    </ul>
   </div>
  </>
 )
}