import React from "react";
import {AppState} from "../../store";
import {liveConnect, liveDisconnect, liveMessageReceived} from "../../store/live/actions";
import {connect} from "react-redux";
import {errorLog} from "../../utils/console";

const wsUrlCreate = (): string => {
 // if(process.env.NODE_ENV==="development")
 //  return "ws://192.168.20.200:5002/live";
 const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
 const host = window.location.host;
 return `${protocol}//${host}/live`
}

interface IProps {
 socket?: WebSocket
 liveConnect: typeof liveConnect
 liveDisconnect: typeof liveDisconnect
 liveMessageReceived: any
}

const mapState = (state: AppState) => ({
 socket: state.liveState.socket
});

const mapDispatch = {
 liveConnect,
 liveDisconnect,
 liveMessageReceived
}

class LiveContainer extends React.Component<IProps> {
 private socket: any = null;
 private intervalId: any = null;

 connect = () => {
  try {
   this.socket = new WebSocket(wsUrlCreate());
   this.socket.onopen = () => this.props.liveConnect(this.socket);
   this.socket.onclose = () => this.props.liveDisconnect();
   this.socket.onmessage = (e: any) => this.props.liveMessageReceived(e);
   this.socket.onerror = (e: any) => errorLog('live socket error', e);
  }
  catch (err) {
   errorLog("Live connection error", err);
  }
 }

 checkConnection = () => {
  if (!this.socket || this.socket.readyState === WebSocket.CLOSED)
   this.connect();
 }

 componentDidMount() {
  this.connect();
  this.intervalId = setInterval(this.checkConnection, 5000);
 }

 componentWillUnmount() {
  clearInterval(this.intervalId);
  this.props.liveDisconnect();
 }

 render() {
  const socket = this.props.socket;

  if (!socket)
   return <div className="Live error"><label>disconnected</label></div>

  return (
   <div className="Live online"><label>online</label></div>
  )
 }
}

export default connect(mapState, mapDispatch)(LiveContainer);
