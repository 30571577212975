import React from 'react';
import BotConfig from "./chatbotConfig";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import {t} from '../../lang'
const react_chatbot_kit = require('react-chatbot-kit');
const{Chatbot}=react_chatbot_kit;




export class ChatBot extends React.Component {
    componentDidMount(): void {
        document.getElementsByClassName('react-chatbot-kit-chat-input')[0].setAttribute("placeholder",t("Сообщение..."));
        document.getElementsByClassName('react-chatbot-kit-chat-container')[0].setAttribute("style","width: 340px; border: 1px solid black; border-radius: 4px");
    }

    render() {
        return (
                    <div   style={{zIndex:1000, position: 'fixed', left: '96px', bottom: '110px'}}>

                <Chatbot
                    config={new BotConfig().config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
            </div>
        )
    }


}
export default ChatBot;