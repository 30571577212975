import React from "react";
import {connect} from "react-redux";
import JsSIP from "jssip";
import {errorLog} from "../../utils/console";
import Dialer from "./dialer";
import Incoming from "./incoming";
import Session from "./session";
import {mediaConstraints, pcConfig, rtcOfferConstraints} from "./config";
import {PHONE_STATUS_IDLE, PhoneStatus} from "../../store/phone/types";
import {AppState} from "../../store";
import {
 phoneAddHistory,
 phoneConnected, phoneConnecting, phoneDisconnected, phoneEndSessions,
 phoneIncoming, phoneIncomingAccepted, phoneIni, phoneMarkAllMissedHistory,
 phoneOutgoing, phoneOutgoingAccepted, phoneOutgoingCall
} from "../../store/phone/actions";
import {phoneLog, phonePlaySound, phoneStopSound} from "../../utils/phone";
import {IUserFull} from "../../models/user";
import {phoneActivate, phoneStorageActive} from "./helper";
import {CALL_TYPE_INCOMING, CALL_TYPE_MISSED_NEW, CALL_TYPE_OUTGOING, CallType} from "../../models/call";
import {ISipConfig} from "../../models/config";

interface IProps {
 config: ISipConfig,
 me: IUserFull,
 status: PhoneStatus
 userAgent: any
 activeSession: any
 incomingSession: any
 phoneIni: typeof phoneIni
 phoneConnecting: typeof phoneConnecting
 phoneConnected: typeof phoneConnected
 phoneDisconnected: typeof phoneDisconnected
 phoneIncoming: typeof phoneIncoming
 phoneIncomingAccepted: typeof phoneIncomingAccepted
 phoneOutgoing: typeof phoneOutgoing
 phoneOutgoingAccepted: typeof phoneOutgoingAccepted
 phoneEndSessions: typeof phoneEndSessions
 phoneAddHistory: typeof phoneAddHistory
 phoneMarkAllMissedHistory: typeof phoneMarkAllMissedHistory
 phoneOutgoingCall: any
}

const mapState = (state: AppState) => {
 return {
  config: state.configState.config.sip,
  me: state.userState.user,
  status: state.phoneState.status,
  userAgent: state.phoneState.userAgent,
  activeSession: state.phoneState.activeSession,
  incomingSession: state.phoneState.incomingSession,
  // contacts: state.phoneState.contacts
 };
}

const mapDispatch = {
 phoneIni,
 phoneConnecting,
 phoneConnected,
 phoneDisconnected,
 phoneIncoming,
 phoneIncomingAccepted,
 phoneOutgoing,
 phoneOutgoingAccepted,
 phoneEndSessions,
 phoneAddHistory,
 phoneMarkAllMissedHistory,
 phoneOutgoingCall,
}

interface IState {
}

class Phone extends React.Component<IProps, IState> {
 componentDidMount() {
  if(!this.props.config.socket || !this.props.me.sipNumber || !this.props.me.sipPasswd)
   return null;

  if (phoneStorageActive())
   phoneActivate();

  try {
   const socket = new JsSIP.WebSocketInterface(this.props.config.socket);
   const userAgent = new JsSIP.UA({
    // uri: "sip:" + this.props.me.appSipNumber + "@app.svhs.ru",
    uri: this.props.config.uri.replace("__USER__", this.props.me.sipNumber),
    password: this.props.me.sipPasswd,
    realm: this.props.config.realm,
    sockets: [socket]
   });

   // соединяемся
   userAgent.on('connecting', (e) => {
    phoneLog("Connecting...", e);
    this.props.phoneConnecting();
   });

   // соединились
   userAgent.on('connected', () => {
    phoneLog("Connected");
   });

   // отсоединились
   userAgent.on('disconnected', () => {
    phoneLog("Disconnected");
    this.props.phoneDisconnected();
   });

   // астер нас зарегал, теперь можно звонить и принимать звонки
   userAgent.on('registered', (e) => {
    phoneLog("Registered");
    this.props.phoneConnected()
   });

   // астер про нас больше не знает
   userAgent.on('unregistered', (data) => {
    phoneLog("Unregistered", data);
    this.props.phoneDisconnected();
   });

   // астер не зарегал нас, что то не то, скорее всего неверный логин или пароль
   userAgent.on('registrationFailed', (data) => {
    phoneLog("Registration failed", data);
    this.props.phoneConnected();
   });

   // соединение
   userAgent.on('newRTCSession', (data) => {
    phoneLog("New session", data);
    if (data.originator === 'local')
     return;

    const session = data.session;
    if (this.props.activeSession || this.props.incomingSession) {
     session.terminate({'status_code': 486, 'reason_phrase': 'Busy Here'});
     this.props.phoneAddHistory({when: new Date(), type: CALL_TYPE_MISSED_NEW, contact: session.remote_identity.uri.user})
     return;
    }

    phoneLog("Incoming session", session);
    phonePlaySound("ringing.ogg", true);
    this.props.phoneIncoming(session);
    phoneActivate();

    session.on('failed', (e: any) => {
     phoneLog("Incoming session failed", e.cause);
     phoneStopSound();
     this.props.phoneEndSessions();
     const type: CallType = (e.cause === "Canceled")? CALL_TYPE_MISSED_NEW: CALL_TYPE_INCOMING;
     this.props.phoneAddHistory({when: new Date(), type, contact: session.remote_identity.uri.user})
    });

    session.on('ended', () => {
     phoneLog("Incoming session ended");
     this.props.phoneEndSessions();
     this.props.phoneAddHistory({when: new Date(), type: CALL_TYPE_INCOMING, contact: session.remote_identity.uri.user})
    });

    session.on('accepted', () => {
     phoneStopSound();
     this.props.phoneIncomingAccepted(session);
    });

   });

   userAgent.start();
   this.props.phoneIni(userAgent);
  }
  catch (err) {
   errorLog("SIP user agent connection error", err);
   return;
  }
 }

 outgoingCall = (contact: string) => {
  this.props.phoneOutgoingCall(contact);
 }

 outgoingCallInside = (contact: string) => {
  phoneLog("Outgoing call", contact);

  const session = this.props.userAgent.call(contact, {pcConfig, mediaConstraints, rtcOfferConstraints});
  phoneLog("Outgoing session", session);

  // Астер нас соединил с абонентом
  session.on('connecting', () => {
   phoneLog("Outgoing connecting");
   phonePlaySound("ringback.ogg", true);
   this.props.phoneOutgoing(session);
  });

  // В процессе дозвона
  session.on('progress', () => {
   phonePlaySound("ringback.ogg", true);
  });

  // Дозвон завершился неудачно, например, абонент сбросил звонок
  session.on('failed', (data) => {
   phoneLog("Outgoing session failed", data);
   phonePlaySound('rejected.mp3', false);
   this.props.phoneEndSessions();
   this.props.phoneAddHistory({when: new Date(), type: CALL_TYPE_OUTGOING, contact: session.remote_identity.uri.user})
  });

  session.on('ended', () => {
   phoneLog("Outgoing session ended");
   phonePlaySound("rejected.mp3", false);
   this.props.phoneEndSessions();
   this.props.phoneAddHistory({when: new Date(), type: CALL_TYPE_OUTGOING, contact: session.remote_identity.uri.user})
  });

  // Звонок принят, можно начинать говорить
  session.on('accepted', (e: any) => {
   phoneLog("Outgoing session accepted", e);
   phonePlaySound("answered.mp3", false);
   this.props.phoneOutgoingAccepted();
  });

 }

 incomingAnswer = () => {
  phoneLog("Answer");
  if (this.props.incomingSession) {
   this.props.incomingSession.answer({pcConfig, mediaConstraints})
  }
 }

 incomingReject = () => {
  phoneLog("Reject");
  if (this.props.incomingSession) {
   this.props.incomingSession.terminate();
  }
 }

 render() {
  const {status, incomingSession, activeSession} = this.props;
  return (
   <div className="Phone">
    {(status === PHONE_STATUS_IDLE && !Boolean(activeSession || incomingSession) && <Dialer onCall={this.outgoingCall}/>)}
    {activeSession && <Session session={activeSession}/>}
    {incomingSession && <Incoming session={incomingSession} onAnswer={this.incomingAnswer} onReject={this.incomingReject}/>}
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(Phone);
