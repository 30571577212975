import React from "react";
import {IVcRoomItem} from "../../models/vc";
import {Link} from "react-router-dom";
import {d} from "../../utils/date";
import {t, tn} from "../../lang";

interface IProps {
 roomItem: IVcRoomItem
 showDate: boolean
 unread: number
}

const durationStr = (a: Date, b?: Date): string => {
 if (b) {
  const d = b.getTime() - a.getTime();
  const totalMins = Math.ceil(d / (1000 * 60));
  const hours = Math.floor(totalMins / 60);
  const mins = totalMins - hours*60;
  return (hours? (hours + t(" ч.")): "") + ((hours & mins)? " ": "") + (mins? (mins + t(" мин.")): "") // hours + ":" + ("0" + mins).slice(-2);
 }
 return "";
}

export const VcListItem: React.FC<IProps> = ({roomItem, showDate, unread}: IProps) => {
 const owner = roomItem.users.find(f => f.userId === roomItem.room.ownerId);
 const size = roomItem.users.length;
 const isActive = !Boolean(roomItem.room.closedAt);
 return (
  <div className={"VcListItem"}>
   {showDate && <div className={"date-row"}>{d(roomItem.room.createdAt, "md")}</div>}
   <Link to={"/app/vc/" + roomItem.room.id}>

    <div className={"item"}>
     <div className={"time-begin"}>
      <span>{isActive? <label>{t("Начало")}</label>: <label>{t("Начало. Длительность")} {durationStr(roomItem.room.createdAt, roomItem.room.closedAt)}</label>}{d(roomItem.room.createdAt, "t")}</span>
     </div>
     {/*
     <div className={"duration"}>
      {isActive
       ? <div className={"vc-active"}><i className={"fas fa-video"}></i><label>a</label></div>
       : <span><label>Длительность</label>{durationStr(roomItem.room.createdAt, roomItem.room.closedAt)}</span>}
     </div>
     */}
     <div className={"title"}>
      {isActive && <div className={"vc-active"}><i className={"fas fa-video"}></i><label>a</label></div>}
      <span className={"room-name"}>{roomItem.room.name}</span>
      {owner && <span className={"owner"}><label>{t("Автор")}</label><i className={"fas fa-user"}></i> {owner.userName}</span>}
      {(size > 0) && <span className={"users"}><i className={"fas fa-users"}></i> {tn(size, ["участник", "участника", "участников"])}</span>}
     </div>
     <div className={"unread"}>
      {(unread > 0) && <span>{unread}<label>{tn(unread, ["новое сообщение", "новых сообщения", "новых сообщений"])}</label></span>}
     </div>
    </div>

    <div className={"item-row" + (isActive ? " item-active" : "")}>
     <div className={"name"}>
      <i className={"video-icon fas " + (isActive ? "fa-video" : "fa-video-slash")} title={isActive ? t("Активный ВКС") : t("ВКС завершён")}></i>
      {roomItem.room.name}
     </div>
     <div className="owner" title={t("Автор")}>
      {owner
       ? <span><i className={"fas fa-user"}></i>{owner.userName}, {tn(roomItem.users.length, ["участник", "участника", "участников"])}</span>
       : <span>{tn(roomItem.users.length, ["участник", "участника", "участников"])}</span>
      }
     </div>
     <div className={"dates"}>
      {(roomItem.room.closedAt) ?
       (<span>{d(roomItem.room.createdAt, "t")} - {d(roomItem.room.closedAt, "t")}</span>) :
       (<span>{t("Создан в")} {d(roomItem.room.createdAt, "t")}</span>)}
     </div>
     <div className={"unread"}>
      {(unread > 0) && <span><label className="nums">{unread}</label><label className="numlabel">{t("непрочитанных соощений")}</label></span>}
     </div>
    </div>
   </Link>
  </div>
 )
}