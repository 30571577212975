export const errorLog = (msg: string, err: any) => {
 if (window.console)
  console.error(msg, err);
};

export const infoLog = (msg: any) => {
 if (window.console)
  console.info(msg);
}

export const consoleLog = (msg: any) => {
 if (window.console)
  console.log(msg);
}
