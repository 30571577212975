import React from "react";
import {IContact, IOnlineUsersMap} from "../../models/contact";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {IVcRoomItem, VcUserRoleEnum} from "../../models/vc";
import {vcAddUser, vcRemoveUser, vcUpdateUser} from "../../store/vc/actions";
import {t} from "../../lang";

interface IProps {
 contacts: IContact[]
 online: IOnlineUsersMap
 isHandling: boolean
 roomItem: IVcRoomItem
 vcAddUser: any,
 vcUpdateUser: any,
 vcRemoveUser: any,
}

const mapState = (state: AppState) => ({
 contacts: state.ocState.contacts.filter(f => f.id !== state.userState.user.id),
 online: state.ocState.online,
 isHandling: state.vcState.isRoomHandling,
})

const mapDispatch = {
 vcAddUser,
 vcUpdateUser,
 vcRemoveUser,
}

interface IRoomUserRoleMap {
 [key: string]: VcUserRoleEnum
}

interface IState {
 selected: IRoomUserRoleMap
 search: string
}

const UserNotChecked = "none";

interface IUserRoleSelectorProps {
 role: VcUserRoleEnum | typeof UserNotChecked
 onChange: (role: VcUserRoleEnum | typeof UserNotChecked) => void
}

const UserRoleSelector = ({role, onChange}: IUserRoleSelectorProps) => (
 <div className={"role-selector"}><select value={role} onChange={(e: any) => onChange(e.target.value)}>
  <option value={UserNotChecked}>--</option>
  <option value={VcUserRoleEnum.Admin}>{t("Модератор")}</option>
  <option value={VcUserRoleEnum.User}>{t("Пользователь")}</option>
  <option value={VcUserRoleEnum.Blocked}>{t("Заблокирован")}</option>
 </select></div>
)


class VcUsersForm extends React.Component<IProps, IState> {
 state: IState = {
  selected: {},
  search: ""
 }

 componentDidMount() {
  const selected: IRoomUserRoleMap = this.props.roomItem.users.reduce((p, c) => {
   p[c.userId] = c.role;
   return p;
  }, {} as IRoomUserRoleMap);
  this.setState({selected});
 }

 changeSearch = (e: any) => this.setState({search: e.target.value});

 changeUser = async (user: string, role: VcUserRoleEnum | typeof UserNotChecked) => {
  const room = this.props.roomItem.room.id;
  if (role === UserNotChecked) {
   await this.props.vcRemoveUser(room, user);
   const selected = {...this.state.selected};
   delete selected[user];
   this.setState({selected});
  }
  else {
   if (this.state.selected[user]) {
    await this.props.vcUpdateUser(room, user, role);
    const selected = {...this.state.selected};
    selected[user] = role;
    this.setState({selected});
   }
   else {
    await this.props.vcAddUser(room, user, role);
    const selected = {...this.state.selected};
    selected[user] = role;
    this.setState({selected});
   }
  }
 }

 render() {
  const online = this.props.online;
  const search = this.state.search.toLowerCase().trim();
  const contacts = search ? this.props.contacts.filter(f => f.name.toLowerCase().indexOf(search) >= 0) : this.props.contacts;

  return (
   <div className={"users-row"}>
    <div className="search-box">
     <input type="search" placeholder={t("Поиск")} value={this.state.search} onChange={this.changeSearch}/>
    </div>
    {contacts.length > 0 ? (<ul>
     {contacts.map(c => (
      <li key={c.id}>
       <label>
        {c.name}
        {c.isMyNode && <span className={"OnlineStatus" + (online[c.id] ? " online" : " offline")}>{online[c.id] ? "online" : "offline"}</span>}
       </label>

       <UserRoleSelector role={this.state.selected[c.id] || UserNotChecked} onChange={role => this.changeUser(c.id, role)}/>
      </li>
     ))}
    </ul>) : (<div className="no-users">{t("Пользователи не найдены")}</div>)}
   </div>
  )
 }
}

export default connect(mapState, mapDispatch)(VcUsersForm);