import {ICall} from "../../models/call";

export const PHONE_STATUS_OFFLINE = 0;
export const PHONE_STATUS_CONNECTING = 10;
// export const PHONE_STATUS_REGISTERING = 11;
export const PHONE_STATUS_IDLE = 100;
export const PHONE_STATUS_INCOMING = 200;
export const PHONE_STATUS_OUTGOUING = 300;
export const PHONE_STATUS_ACTIVE = 500;

export type PhoneStatus = typeof PHONE_STATUS_OFFLINE
 | typeof PHONE_STATUS_CONNECTING
 // | typeof PHONE_STATUS_REGISTERING
 | typeof PHONE_STATUS_IDLE
 | typeof PHONE_STATUS_INCOMING
 | typeof PHONE_STATUS_OUTGOUING
 | typeof PHONE_STATUS_ACTIVE


export interface PhoneState {
 status: PhoneStatus
 userAgent: any
 activeSession: any
 incomingSession: any
 // contacts: IContact[]
 history: ICall[]
}


export const PHONE_INI = 'phone/ini';

interface PhoneIniAction {
 type: typeof PHONE_INI
 userAgent: any
}

export const PHONE_CONNECTING = 'phone/connecting';

interface PhoneConnectingAction {
 type: typeof PHONE_CONNECTING
}

export const PHONE_CONNECTED = 'phone/connected';

interface PhoneConnectedAction {
 type: typeof PHONE_CONNECTED
}

export const PHONE_DISCONNECTED = 'phone/disconnected';

interface PhoneDisconnectedAction {
 type: typeof PHONE_DISCONNECTED
}

export const PHONE_INCOMING = 'phone/incoming';

interface PhoneIncomingAction {
 type: typeof PHONE_INCOMING
 incomingSession: any
}

export const PHONE_INCOMING_ACCEPTED = 'phone/incoming_accepted';

interface PhoneIncomingAcceptedAction {
 type: typeof PHONE_INCOMING_ACCEPTED
 activeSession: any
}

export const PHONE_OUTGOING = 'phone/outgoing';

interface PhoneOutgoingAction {
 type: typeof PHONE_OUTGOING
 activeSession: any
}

export const PHONE_OUTGOING_ACCEPTED = 'phone/outgoing_accepted';

interface PhoneOutgoingAcceptedAction {
 type: typeof PHONE_OUTGOING_ACCEPTED
}

export const PHONE_END_SESSIONS = 'phone/end_sessions';

interface PhoneEndSessionsAction {
 type: typeof PHONE_END_SESSIONS
}


// history section

export const PHONE_ADD_HISTORY = 'phone/add_history';

interface PhoneAddHistoryAction {
 type: typeof PHONE_ADD_HISTORY;
 call: ICall
}

export const PHONE_DEL_HISTORY = 'phone/del_history';

interface PhoneDelHistoryAction {
 type: typeof PHONE_DEL_HISTORY;
 date: Date;
}

export const PHONE_MARK_MISSED_HISTORY = 'phone/mark_missed_history';

interface PhoneMarkMissedHistoryAction {
 type: typeof PHONE_MARK_MISSED_HISTORY;
 date: Date;
}

export const PHONE_MARK_ALL_MISSED_HISTORY = 'phone/mark_all_missed_history';

interface PhoneMarkAllMissedHistoryAction {
 type: typeof PHONE_MARK_ALL_MISSED_HISTORY
}

export type PhoneActionTypes = PhoneIniAction
 | PhoneConnectingAction
 | PhoneConnectedAction
 | PhoneDisconnectedAction
 | PhoneIncomingAction
 | PhoneIncomingAcceptedAction
 | PhoneOutgoingAction
 | PhoneOutgoingAcceptedAction
 | PhoneEndSessionsAction
 | PhoneAddHistoryAction
 | PhoneDelHistoryAction
 | PhoneMarkMissedHistoryAction
 | PhoneMarkAllMissedHistoryAction
