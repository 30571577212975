import React from "react";
import {connect} from "react-redux";
import {getDisplayNameOrUserFromSession} from "./helper";
import {IContact} from "../../models/contact";
import {AppState} from "../../store";
import {HideButton} from "./hide.button";
import {t} from "../../lang";

interface IProps {
 contacts: IContact[]
 session: any
 onAnswer: () => void
 onReject: () => void
}

const mapState = (state: AppState) => ({
 contacts: state.ocState.contacts
});

const Incoming = ({session, onAnswer, onReject, contacts}: IProps) => {
 const contact = contacts.find(f => f.sipNumber === session.remote_identity.uri.user);
 const name = contact? contact.name: getDisplayNameOrUserFromSession(session.remote_identity);
 const uri = session.remote_identity.uri.toString();
 return (
  <div className="CallBox IncomingCall">
   <div className="title">{t("Входящий звонок")}</div>
   <div className="name">{name}</div>
   <div className="contact">{uri}</div>
   <div className="actions">
    <button className="answer" onClick={() => onAnswer()}><i className="fas fa-phone"></i><label>{t("Ответить")}</label></button>
    <button className="reject" onClick={() => onReject()}><i className="fas fa-phone"></i><label>{t("Отклонить")}</label></button>
   </div>
   <HideButton />
  </div>
 )
}

export default connect(mapState)(Incoming);