import React from "react";
import {AppState} from "../../store";
import {MessengerUnreadMap} from "../../store/messenger/types";
import {ChatCategoryEnum, ChatSettingsNotifyEnum, ChatTypeEnum, IChatExt} from "../../models/messenger";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {t} from "../../lang";

interface IChatExtUpd extends IChatExt {
 lastUpdate: Date | undefined
}

interface IProps {
 selectedChat: string
 chats: IChatExtUpd[]
 unread: MessengerUnreadMap
 myId: string
}

const mapState = (state: AppState) => ({
 selectedChat: state.messengerState.selectedChatId,
 chats: state.messengerState.chats.map(f => {
  const lastUpdate: Date | undefined = state.messengerState.updates[f.id] ? state.messengerState.updates[f.id] : undefined;
  return {...f, lastUpdate} as IChatExtUpd
 }).sort((a, b) => {
  if (a.lastUpdate && b.lastUpdate)
   return (a.lastUpdate > b.lastUpdate) ? -1 : 1;
  if (a.lastUpdate && !b.lastUpdate)
   return -1;
  if (!a.lastUpdate && b.lastUpdate)
   return 1;
  return 0;
 }),
 unread: state.messengerState.unread,
 myId: state.userState.user.id,
})

const getIconClassNameByType = (t: ChatTypeEnum): string => {
 switch (t) {
  case ChatTypeEnum.Personal:
   return "ch-type-ico fas fa-user";
  case ChatTypeEnum.Room:
   return "ch-type-ico fas fa-users";
  case ChatTypeEnum.Group:
   return "ch-type-ico fas fa-project-diagram";
  case ChatTypeEnum.Well:
   return "ch-type-ico fas fa-sitemap";
 }
 return "ch-type-ico fas fa-commnets"
}

const getCategoryClassName = (category?: ChatCategoryEnum): string => {
 if (category) {
  const c = category * 1;
  switch (c) {
   case ChatCategoryEnum.Accident:
    return " cat-accident";
   case ChatCategoryEnum.Deffect:
    return " cat-deffect";
   case ChatCategoryEnum.Lag:
    return " cat-lag";
  }
 }
 return "";
}

const SidebarHistory: React.FC<IProps> = ({selectedChat, chats, unread}: IProps) => {
 return (
  <div className="list">
   {chats.length > 0 ? (
    <ul>
     {chats.map(c => {
      const u = unread[c.id] ? unread[c.id].length : 0;
      return <li key={c.id}>
       <Link className={((selectedChat === c.id) ? "active" : "") + getCategoryClassName(c.category)} to={"/app/chat/c/" + (c.id)}>
        <div className="chat-box">
         <i className={getIconClassNameByType(c.type)}></i>
         <label className={"chat-name"}>
          {(c.notify===ChatSettingsNotifyEnum.Silent) && <i className={"silent-ico far fa-bell-slash"}></i>}
          {c.name || "..."}
         </label>
        </div>
       </Link>
       {u > 0 && <span className={"unread"}>{u}</span>}
      </li>
     })}
    </ul>
   ) : (<div className={"no-chats"}>{t("Пока нет ни одной беседы")}</div>)}
  </div>
 )
}

export default connect(mapState)(SidebarHistory);
