import React from "react";
import {IUserMapPoint} from "./models";
import {Link} from "react-router-dom";
import {t} from "../../lang";

interface IProps {
 contacts: IUserMapPoint[]
 // onContactClick: (id: string) => void
}

export const MapSidebarContacts = (props: IProps) => {
 return (
  <div className="Sidebar">
   <h3>{t("Пользователи")}</h3>
   {(props.contacts.length > 0) && (
    <div className="realtime-users">
     <ul className="persons">
      {props.contacts.map((u, i) => <li key={i}>
       <Link to={"/app/maps/realtime/"+u.person.login}>
        <div className={"person " + (u.dangerZone? "danger": "")}>
         <span className="name">{u.person.name}</span><br/>
         <span className="status">{u.dangerZone? t("находится в опасной зоне") : t("находится вне зон")}</span>
        </div>
       </Link>
      </li>)}
     </ul>
    </div>
   )}
  </div>
 )
}
