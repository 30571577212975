import React from "react";
import {tickTimeString} from "./models";
import {t} from "../../lang";

interface IProps {
 times: number[]
 selected?: number
 selectTime: any
 interval: number
 selectInterval: any
}

interface IntervalOptions {
 [key: string]: number
}

const intervals: IntervalOptions = {
 '1 мин': 1,
 '2 мин': 2,
 '5 мин': 5,
 '10 мин': 10,
 '20 мин': 20,
 '30 мин': 30,
 '1 час': 60
}

class HistoryTimeline extends React.Component<IProps> {
 render() {
  const {times, interval, selected} = this.props;
  return (
   <div className="HistoryTimeline">
    {times.length > 0 && (
     <div className="timeline-box">
      <select value={interval} onChange={e => this.props.selectInterval(Number(e.target.value))}>
       {Object.keys(intervals).map((k: string) => <option key={k} value={intervals[k]}>{t(k)}</option>)}
      </select>
      <ul>
       <li>
       </li>
       {times.map((t, i) => <li key={i} className={(selected && (selected <= t) && (t < selected + interval)) ? "selected" : ""}>
        <button onClick={() => this.props.selectTime(t)}>{tickTimeString(t)}</button>
       </li>)}
      </ul>
     </div>
    )}
   </div>
  )
 }
}

export default HistoryTimeline;