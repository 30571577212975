import React, {useState} from "react";
import {t} from '../../../../lang'
import {connect} from "react-redux";

interface IProps {
    onNumChange: (num: string) => void
    onDTMF: (num: string) => void
    isToneModeEnabled: boolean;
}
interface Irate {
    rates: {
        "RUB": number
    },
    base: string,
    date: string

}

interface  IState {
    USDRate: number,
    EURRate:number,
    GBPRate :number,
    USD: {'CUR': number, 'RUB': number},
    EUR:{'CUR':number, 'RUB': number, },
    GBP :{'CUR':number, 'RUB': number},
    Error: string
}

export class Currency extends React.Component<IProps, IState> {

    state: IState = {
        USDRate: 0,
        EURRate:0,
        GBPRate: 0,
        USD: {'CUR':0, 'RUB': 0},
        EUR: {'CUR':0, 'RUB': 0},
        GBP: {'CUR':0, 'RUB': 0},
        Error: ''

    };

    componentDidMount(): void {

        this.Rates();
    }
 base="https://api.exchangeratesapi.io/latest";
handleErrors=  (response) => {
        if (!response.ok) throw new Error(response.status);
        return response;
    }
    recalcAmount=(e)=>{
    if(e.target.id==='USDUSD') {


        this.setState({
            USD: {
                'CUR': e.target.value,
                'RUB': Math.round(e.target.value * this.state.USDRate * 100) / 100
            }
        });

    }
        if(e.target.id==='USDRUB') {

            this.setState({
                USD: {
                    'CUR': Math.round(e.target.value / this.state.USDRate * 100) / 100,
                    'RUB':  e.target.value
                }
            });
        }

        if(e.target.id==='EUREUR') {


            this.setState({
                EUR: {
                    'CUR': e.target.value,
                    'RUB': Math.round(e.target.value * this.state.EURRate * 100) / 100
                }
            });

        }
        if(e.target.id==='EURRUB') {

            this.setState({
                EUR: {
                    'CUR': Math.round(e.target.value / this.state.EURRate * 100) / 100,
                    'RUB':  e.target.value
                }
            });
        }
        if(e.target.id==='GBPGBP') {


            this.setState({
                GBP: {
                    'CUR': e.target.value,
                    'RUB': Math.round(e.target.value * this.state.GBPRate * 100) / 100
                }
            });

        }
        if(e.target.id==='GBPRUB') {

            this.setState({
                GBP: {
                    'CUR': Math.round(e.target.value / this.state.GBPRate * 100) / 100,
                    'RUB':  e.target.value
                }
            });
        }

    };

Rates=() => {


        this.setState({Error:''});
        ["USD","EUR", "GBP"].forEach( arrayItem =>
    {
        fetch(`${this.base}?base=${arrayItem}&symbols=RUB`).then(this.handleErrors)
            .then(res => res.json())
            .then(result => {
                console.log(result);
                if ((result as Irate).rates && (result as Irate).rates.RUB) {

                    console.log(result);
                    if (arrayItem==="USD") {
                        this.setState({USDRate: (result as Irate).rates.RUB});
                        let conv=(result as Irate).rates.RUB;
                        this.setState({USD: {'CUR': 1, 'RUB': Math.round((result as Irate).rates.RUB*100)/100}});
                    }
                    if (arrayItem==="EUR") {
                        this.setState({EURRate: (result as Irate).rates.RUB});
                        this.setState({EUR: {'CUR': 1, 'RUB': Math.round((result as Irate).rates.RUB*100)/100}});
                    }
                    if (arrayItem==="GBP") {
                        this.setState({GBPRate: (result as Irate).rates.RUB});
                        this.setState({GBP: {'CUR': 1, 'RUB': Math.round((result as Irate).rates.RUB*100)/100}});
                    }
                } else
                        this.setState( {Error: this.state.Error+ t("Не удалось получить курс ")+arrayItem+"\n"});
            }).catch(err => {
            console.log(err);
            this.setState( {Error: this.state.Error+ t("Не удалось получить курс ")+arrayItem+"\n"});


        });
    });
    };

    render() {
        return (
            <div className = 'currency-container' >

                                     <div className = 'rate-data'>
                                         <div >
                                     USD:
                                    <input
                                        id='USDUSD'
                                        type="number"
                                        lang="en"
                                        min={0}
                                         className="search-bar"
                                         onChange={e => this.recalcAmount(e)}
                                         value={(this.state.USD.CUR)}
                                    />
                                         </div>
                                         <div>
                                    RUB:
                                     <input
                                         id='USDRUB'
                                         type="number"
                                         lang="en"
                                         min={0}
                                        className="search-bar"
                                         onChange={e => this.recalcAmount(e)}
                                         value={(this.state.USD.RUB)}
                                     />
                                 </div>
                                     </div >
                                     <div className = 'rate-data'>
                                         <div>
                                         EUR:
                                         <input
                                             id='EUREUR'
                                             type="number"
                                             lang="en"
                                             className="search-bar"
                                             onChange={e => this.recalcAmount(e)}
                                             value={(this.state.EUR.CUR)}
                                         />
                                         </div>
                                         <div>
                                         RUB:
                                         <input
                                             id='EURRUB'
                                             type="number"
                                             lang="en"
                                             min={0}
                                             className="search-bar"
                                             onChange={e => this.recalcAmount(e)}
                                             value={(this.state.EUR.RUB)}
                                         />
                                         </div>
                                     </div>
                                     {/*<div>*/}
                                     {/*    <div>*/}
                                     {/*    GBP:*/}
                                     {/*    <input*/}
                                     {/*        id='GBPGBP'*/}
                                     {/*        type="number"*/}
                                     {/*        lang="en"*/}
                                     {/*        min={0}*/}
                                     {/*        className="search-bar"*/}
                                     {/*        onChange={e => this.recalcAmount(e)}*/}
                                     {/*        value={(this.state.GBP.CUR)}*/}
                                     {/*    />*/}
                                     {/*    </div>*/}
                                     {/*    <div>*/}
                                     {/*    RUB:*/}
                                     {/*    <input*/}
                                     {/*        id='GBPRUB'*/}
                                     {/*        type="number"*/}
                                     {/*        lang="en"*/}
                                     {/*        min={0}*/}
                                     {/*        className="search-bar"*/}
                                     {/*        onChange={e => this.recalcAmount(e)}*/}
                                     {/*        value={(this.state.GBP.RUB)}*/}
                                     {/*    />*/}
                                     {/*    </div>*/}
                                     {/*</div>*/}
                         </div>
        )
    }
}
export default connect()(Currency);