import React from "react";
// import {generateVcJWT} from "../../utils/jwt";
import {httpGet} from "../../utils/http";
import {VcUserRoleEnum} from "../../models/vc";

interface IProps {
 userId: string
 userName: string
 server: string
 script: string
 room: string
 userRole: VcUserRoleEnum
}

interface IState {
 token: string
 api: JitsiApi
}

interface JitsiWindow extends Window {
 JitsiMeetExternalAPI: any
}
interface JitsiApi extends Object{
 dispose: any
}
interface ITokenResponse {
 token: string;
}

export class VcVideo extends React.Component<IProps, IState> {
 mounted = false;
 state = {
  token: "",
  api: {dispose() {
   }},

 };
 options = {
  roomName: "",
  width: '100%',
  height: '100%',
  parentNode: document.querySelector('#vcVideoBox'),
  // onload: (e, a) => {
  //  console.log("mapi: on load", e, a);
  // },
  jwt: "",
  interfaceConfigOverwrite: {
   DEFAULT_BACKGROUND: '#303030',
   DISABLE_VIDEO_BACKGROUND: true, // ??
   INITIAL_TOOLBAR_TIMEOUT: 5000,
   TOOLBAR_TIMEOUT: 500,
   TOOLBAR_ALWAYS_VISIBLE: true,
   DEFAULT_REMOTE_DISPLAY_NAME: 'user',
   SHOW_JITSI_WATERMARK: false,
   JITSI_WATERMARK_LINK: 'http://petroviser.ru/',
   SHOW_WATERMARK_FOR_GUESTS: false,
   APP_NAME: 'VKS Meet',
   NATIVE_APP_NAME: 'Vks Meet',
   PROVIDER_NAME: 'Vks Provider',
   LANG_DETECTION: true,
   INVITATION_POWERED_BY: false,
   MOBILE_APP_PROMO: false,
   TOOLBAR_BUTTONS: [
    'microphone',
    'camera',
    'desktop',
    'fullscreen',
    'fodeviceselection',
    'settings',
    'raisehand',
    'videoquality',
    'stats',
    'shortcuts',
    'tileview',
   ],
   SETTINGS_SECTIONS: ['devices', 'language'],
   CONNECTION_INDICATOR_DISABLED: true,
   RECENT_LIST_ENABLED: false,
  }
 };

 async componentDidUpdate(prevProps: IProps) {
  if(this.props.userRole!==prevProps.userRole && (this.props.userRole===1|| prevProps.userRole===1)) {
   this.state.api.dispose();
   if (this.props.userRole===1) {
    this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("recording","mute-everyone","invite");
   }
   if (prevProps.userRole===1){
    {
     this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.splice(this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.indexOf("recording"),1);
     this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.splice(this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.indexOf("mute-everyone"),1);
     this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.splice(this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.indexOf("invite"),1);
    }
   }
   const {token} = await httpGet<ITokenResponse>('/mapi/vc/room/'+this.props.room+'/'+this.props.server+'/'+this.props.userRole+'/token');
   this.options.jwt=token;
   setTimeout(() => {
    if(this.mounted) {
     const api = new (window as JitsiWindow).JitsiMeetExternalAPI(this.props.server, this.options);
     this.setState({token, api});
    }
   }, 1000);
  }
 }

 async componentDidMount() {
  this.mounted = true;
  //this.state.api.dispose();
  if (this.props.userRole===1)
   this.options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("recording","mute-everyone","invite");
  if(window.localStorage && window.localStorage["vc"] && window.localStorage["vc"]==="off")
   return;
  try {
   const {token} = await httpGet<ITokenResponse>('/mapi/vc/room/'+this.props.room+'/'+this.props.server+'/'+this.props.userRole+'/token');
   const room = this.props.room.replace(/-/g, "");
   this.options.parentNode=document.querySelector('#vcVideoBox');
   this.options.jwt=token;
   this.options.roomName=room;
   // const token = await generateVcJWT(this.props.userId, this.props.userName, this.props.server, room);

   setTimeout(() => {
    if(this.mounted) {
     const api = new (window as JitsiWindow).JitsiMeetExternalAPI(this.props.server, this.options);
     this.setState({token, api});
    }
   }, 1000);
  }
  catch (err) {
   if(window.console)
    console.error("There are video ini error", err);
  }
 }

 componentWillUnmount() {
  this.mounted = false;
 }

 render() {
  return <div className={"VcVideo"}>
   <div id="vcVideoBox"></div>
  </div>
 }
}