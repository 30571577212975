import {IUserFull} from "../../models/user";

export interface UserState {
 user: IUserFull
 lang: string
 isSigned: boolean
 isFetching: boolean
 error: any
}

export const USER_FETCH = 'user/fetch';
export const USER_OK = 'user/ok';
export const USER_FAIL = 'user/fail';
export const USER_LOGOUT = 'user/logout';
export const USER_LANG = 'user/lang';

interface UserFetchAction {
 type: typeof USER_FETCH;
}

interface UserOkAction {
 type: typeof USER_OK;
 user: IUserFull;
}

interface UserFailAction {
 type: typeof USER_FAIL;
 error: any;
}

interface UserLogoutAction {
 type: typeof USER_LOGOUT;
}

interface UserChangeLangAction {
 type: typeof USER_LANG;
 lang: string
}

export type UserActionTypes = UserFetchAction | UserFailAction | UserOkAction | UserLogoutAction | UserChangeLangAction;
